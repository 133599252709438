import React, { Component } from 'react'; //different

import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            username: null,
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            loginstate: false,
            fullname: null,
            lname: null,
            alert: null,
            depositstate: 0,
            depositamount: 0,
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            affpercent: 0,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            tblnextrank: [],
            tblrankall: [],
            tblprofile: [],
            prodetail: [],
            prolist: [],
            prolistx: null,
            linkaff: "",
            picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
            proderedeem: [],
            buttoncliam: false,
            limit: null,
            rankpic: null,
            rankname: null,
            rankob: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    componentDidMount() {
        const search = window.location.href;
        var test = search.split("blog/")[1]
        if (test != undefined) {
            this.promotionlist(test.replace('blog', ''));
        }

        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     var decoded = jwt_decode(token);
        //     var Username = decoded.message.playerid;
        //     this.setState({
        //         username: Username,
        //     });


        // }
    }

    converttag(value) {

        let text = "";
        if (value.tag != null) {
            let tag = JSON.parse(value.tag)
            for (var i = 0; i < tag.length; i++) {
                // console.log(8555, tag[i]);
                text += "<a title='' style={ margin-left: '5px' } href=''>" + tag[i] + "</a>"
            }
        }
        return text;
    };

    convertkeyworrd(value) {
        let text = "";
        if (value.keyword != null) {
            let keyword = JSON.parse(value.keyword)
            for (var i = 0; i < keyword.length; i++) {
                // console.log(8555, keyword[i]);
                text += keyword[i] + ","
            }
        }
        return text;
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        // var token = localStorage.getItem('auth_token');
        // var decoded = jwt_decode(token);
        // var Username = decoded.message.playerid;
        // //console.log(Username, proid);
        // if (token != null) {
        //     this.setState({
        //         username: Username,
        //     });
        //     await instance.post("/api/v1/redeempromotion", {
        //         userid: Username,
        //         System: Systems,
        //         Proid: proid,
        //     }, {
        //         headers: { Authorization: `Bearer ${token}` }
        //     }).then(async (res) => {
        //         //console.log(res.data.status)
        //         if (res.data.status === 200) {
        //             document.getElementById("opendepositsuper").click()
        //         }
        //         else if (res.data.status === 201) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }
        //         else if (res.data.status === 204) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }
        //         else if (res.data.status === 202) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }

        //     }).catch(async (err) => {
        //         // console.log(err);
        //         if (err.response.status === 401 || err.response.status === 403) {
        //             localStorage.clear();
        //             await Swal.fire({
        //                 title: 'Warning!',
        //                 text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
        //                 icon: 'warning',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             window.location.href = '/';
        //         }
        //     });
        // }
    }

    checkpro = async (e, proid) => {
        // var Username = "";
        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     var decoded = jwt_decode(token);
        //     Username = decoded.message.playerid;
        // }
        // await instance.post("/api/v1/checkpromotion", {
        //     userid: Username,
        //     System: Systems,
        //     Proid: proid,
        // }, {
        //     headers: { Authorization: `Bearer ${token}` }
        // }).then(async (res) => {
        //     if (res.data.status === 200) {
        //         //console.log(res.data);
        //         if (res.data.type === 0) {
        //             this.setState({
        //                 prodetail: res.data.message.tblpromotions[0],
        //             });
        //         }
        //         else if (res.data.type === 1) {
        //             this.setState({
        //                 proderedeem: res.data.message.tblpromotionredeems,
        //             });
        //         }

        //     }
        //     else if (res.data.status === 201) {
        //         this.setState({
        //             prodetail: [],
        //             picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
        //         });

        //     }
        //     else if (res.data.status === 202) {
        //         await Swal.fire({
        //             title: 'error!',
        //             text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
        //             icon: 'error',
        //             confirmButtonText: 'ตกลง'
        //         })
        //         return false;
        //     }

        // }).catch(async (err) => {
        //     //   console.log(err);
        //     if (err.response.status === 401 || err.response.status === 403) {
        //         localStorage.clear();
        //         await Swal.fire({
        //             title: 'Warning!',
        //             text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
        //             icon: 'warning',
        //             confirmButtonText: 'ตกลง'
        //         })
        //         window.location.href = '/';
        //     }
        // });
    }
    promotionlist = async (id) => {
        console.log(id, 11111);
        await instance.post("/api/v1/getblog", {
            System: Systems,
            id: id,
            Type: 2

        }).then(async (res) => {
            if (res.data.status === 200) {
                console.log(res.data, 230);
                this.setState({
                    prolistx: res.data.message[0],
                });
            }
        }).catch(async (err) => {
            console.log(288, err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }

    render() {

        const { prodetail, picnonpro, username, prolistx } = this.state
        console.log(306, prolistx)
        return (
            <div className="x-blog-show">
                <div className="x-index-cover-container" style={{height:"180px"}}>
                </div>
                <div className="container">
                    {prolistx == null ? "" : <div>
                        <span className='tags' style={{ color: 'rgb(35,8,8)', fontSize: '20px', fontFamily: 'Kanit', marginLeft: "30px" }}>Hashtag
                            <div className="tags" style={{ fontFamily: 'Kanit', marginLeft: "30px" }}>
                                <section
                                    dangerouslySetInnerHTML={{ __html: this.converttag(prolistx) }}
                                />
                            </div>
                        </span>

                        <div className="container">
                            <div className="row px-2">
                                <React.Fragment key={prolistx.id}>
                                    <section
                                        dangerouslySetInnerHTML={{ __html: prolistx.Detail }}
                                    />
                                </React.Fragment>
                            </div>
                        </div>
                        <span className='tags' style={{ color: 'rgb(35,8,8)', fontSize: '20px', fontFamily: 'Kanit', marginLeft: "30px" }}>keyword {this.convertkeyworrd(prolistx)}    </span>
                    </div>

                    }
                </div>
            </div>
        );
    }
}
export default App;
