import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            list: [],
            casinolist: [],
            sportlist: [],
            arcadelist: [],
            fishlist: [],
            activePage: 1,
            activeid: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            pagestate: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            weekNumber: 0,
            counter: 0,
            alert: null,
            typepage: ""
        }

    }





    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        //  console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                System: Systems,
                playerUsername: this.state.userid,
                isMobile: isMobile,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined)
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {

                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
        this.getcheckin();
    }
    reload(e, date) {
        var userAgent = navigator.userAgent
        //  console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        else {
            var token = localStorage.getItem('auth_token');
            var userid = null
            if (token != null) {
                var decoded = jwt_decode(token);
                userid = decoded.message.playerid;
                var curr = new Date;
                var startDate = new Date(curr.getFullYear(), 0, 1);
                let days = Math.floor((curr - startDate) /
                    (24 * 60 * 60 * 1000));
                let weekNumber = Math.ceil(days / 7);

                var row = {
                    CheckinID: date,
                    userid: userid,
                    status: 1,
                    dateupdate: curr,
                    weekofyear: weekNumber,
                    monthofyear: moment(curr).format('MM'),
                    dayofyear: days + 1,

                }

                instance.post("/api/v1/updatecheckinlist", {
                    System: Systems,
                    userid: userid,
                    rows: row
                })
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire({
                                title: 'Success!',
                                text: 'ท่าน checkin เรียบร้อย',
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else if (res.data.status === 202) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่านไม่สามารถเชคอินล่วงหน้าได้',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else if (res.data.status === 204) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่านต้องเริ่มเชคอินใหม่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่าน checkin ไปแล้ว',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    });



            }


        }
    }
    getcheckin() {

        var token = localStorage.getItem('auth_token');
        var userid = null
        if (token != null) {
            var decoded = jwt_decode(token);
            userid = decoded.message.playerid;
        }
        instance.post("/api/v1/getcheckin", {
            System: Systems,
            userid: userid
        })
            .then((res) => {
                console.log(111455555, res.data);
                if (res.data.status === 200) {
                    this.setState({
                        list: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });



    }
    checkonline() {
        //////console.log(1);
        // const search = window.location.href;
        // const params = new URLSearchParams(search);
        // const space = search.split("/")
        // if (space[5].replace("#", "") !== "") {
        //     this.setState({
        //         typepage: space[4].replace("#", ""),
        //     });
        //     instance.post("/api/v1/tabgamelist", {
        //         state: "999",
        //         System: Systems,
        //         partner: space[5].replace("#", ""),
        //         game: space[4].replace("#", ""),
        //     })
        //         .then((res) => {
        //             if (res.data.status === 200) {
        //                 console.log(res.data);
        //                 this.setState({
        //                     slotlist: res.data.message
        //                 });
        //             }
        //             else {
        //                 //  this.CheckThisGoalError();
        //             }
        //         });

        //     this.setState({
        //         typepage: space[4].replace("#", ""),
        //     });

        // }
        // else {
        //     this.setState({
        //         typepage: space[4].replace("#", ""),
        //     });
        //     instance.post("/api/v1/tabgamelistpop", {
        //         state: "7",
        //         System: Systems,
        //         Type: 2,
        //         game: space[4].replace("#", ""),
        //     })
        //         .then((res) => {
        //             if (res.data.status === 200) {
        //                 console.log(res.data);
        //                 this.setState({
        //                     slotlist: res.data.message
        //                 });
        //             }
        //             else {
        //                 //  this.CheckThisGoalError();
        //             }
        //         });

        //     this.setState({
        //         typepage: space[4].replace("#", ""),
        //     });
        // }

        instance.post("/api/v1/tabgamelist", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        casinolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "5",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        sportlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

        instance.post("/api/v1/tabgamelist", {
            state: "8",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        fishlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "4",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        arcadelist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });



    }



    render() {
        return (
            <div>
                <NavMenu />
                <section className="x-category-index">
                    {/* <div className="-nav-menu-container col-xl-2 col-lg-3 js-category-menus">
                        <nav className="nav-menu" id="navbarCategory">
                            <ul
                                className="-menu-parent navbar-nav js-menu-container"
                                id="accordion-games"
                            >
                                <li className="-list-parent nav-item -category-type">
                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapse show  js-type"
                                        data-toggle="collapse"
                                        data-target="#collapse-type"
                                        aria-expanded="false"
                                        aria-controls="collapse-type"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">หมวดหมู่</span>
                                            <span className="-menu-text-sup d-none d-lg-block">2 ประเภท</span>
                                        </div>
                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-type"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        <a
                                            type="button"
                                            className="-menu-btn btn-block -child"
                                            href={"/listgameall/slot/"}
                                        >
                                            <span className="-menu-text-child">เกมส์ยอดนิยม</span>
                                        </a>
                                    </div>
                                </li>
                                <li className="-list-parent nav-item -category-brand">
                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapsed js-brand"
                                        data-toggle="collapse"
                                        data-target="#collapse-brand"
                                        aria-expanded="false"
                                        aria-controls="collapse-brand"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">ค่ายเกมส์</span>
                                            <span className="-menu-text-sup d-none d-lg-block">{this.state.categorylist.length} ค่าย</span>
                                        </div>
                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-brand"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        {this.state.categorylist.map(slotlist => (
                                            <button
                                                type="button"
                                                className="-menu-btn btn-block -child"
                                                onClick={(e) => this.reload(e, slotlist.partner, "slot")}
                                            >
                                                <span className="-menu-text-child"> {slotlist.description}</span>

                                            </button>
                                        ))}
                                        <a
                                            href="#"
                                            target="_blank"
                                            className="-menu-btn btn-block -child"
                                        >
                                            <img
                                                className="img-fluid -img-btn"
                                                src="/build/web/ez-bet/img/simpleplay-logo.png"
                                                alt="sp"
                                            />
                                        </a>
                                    </div>
                                </li>
                                <li className="-list-parent nav-item -category-casino">

                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapsed js-brand"
                                        data-toggle="collapse"
                                        data-target="#collapse-casino"
                                        aria-expanded="false"
                                        aria-controls="collapse-casino"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">คาสิโนสด</span>
                                            <span className="-menu-text-sup d-none d-lg-block">{this.state.casinolist.length} ค่าย</span>
                                        </div>
                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-casino"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        {this.state.casinolist.map(casinolist => (
                                            <button
                                                type="button"
                                                className="-menu-btn btn-block -child "
                                                onClick={(e) => this.reload(e, casinolist.partner, "casino")}
                                            >
                                                <span className="-menu-text-child"> {casinolist.description}</span>
                                            </button>
                                        ))}
                                    </div>
                                </li>
                                <li className="-list-parent nav-item -category-sport">
                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapsed js-brand"
                                        data-toggle="collapse"
                                        data-target="#collapse-sport"
                                        aria-expanded="false"
                                        aria-controls="collapse-sport"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">สปอร์ต</span>
                                            <span className="-menu-text-sup d-none d-lg-block">{this.state.sportlist.length} ค่าย</span>
                                        </div>
                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-sport"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        {this.state.sportlist.map(sportlist => (
                                            <button
                                                type="button"
                                                className="-menu-btn btn-block -child "
                                                onClick={(e) => this.reload(e, sportlist.partner, "sport")}
                                            >
                                                <span className="-menu-text-child"> {sportlist.description}</span>
                                            </button>
                                        ))}
                                    </div>
                                </li>
                                <li className="-list-parent nav-item -category-skill-game">
                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapsed js-brand"
                                        data-toggle="collapse"
                                        data-target="#collapse-fish"
                                        aria-expanded="false"
                                        aria-controls="collapse-fish"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">ยิงปลา</span>
                                            <span className="-menu-text-sup d-none d-lg-block">{this.state.fishlist.length} เกมส์</span>
                                        </div>

                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-fish"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        {this.state.fishlist.map(fishlist => (
                                            <button
                                                type="button"
                                                className="-menu-btn btn-block -child "
                                                onClick={(e) => this.reload(e, fishlist.partner, "fishing")}
                                            >
                                                <span className="-menu-text-child"> {fishlist.description}</span>
                                            </button>
                                        ))}
                                    </div>
                                </li>
                                <li className="-list-parent nav-item -category-fishing-game">
                                    <button
                                        type="button"
                                        className="-menu-btn -parent nav-link collapsed js-brand"
                                        data-toggle="collapse"
                                        data-target="#collapse-arcade"
                                        aria-expanded="false"
                                        aria-controls="collapse-arcade"
                                    >
                                        <div className="-img-category" />
                                        <div className="-menu-text">
                                            <span className="-menu-text-main">อาร์เคด</span>
                                            <span className="-menu-text-sup d-none d-lg-block">{this.state.arcadelist.length} เกมส์</span>
                                        </div>
                                        <i className="fas fa-chevron-down d-none d-lg-flex" />
                                    </button>
                                    <div
                                        id="collapse-arcade"
                                        className="-menu-child collapse"
                                        data-parent="#accordion-games"
                                    >
                                        {this.state.arcadelist.map(arcadelist => (
                                            <button
                                                type="button"
                                                className="-menu-btn btn-block -child "
                                                onClick={(e) => this.reload(e, arcadelist.partner, "arcade")}
                                            >
                                                <span className="-menu-text-child"> {arcadelist.description}</span>
                                            </button>
                                        ))}
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                    <div className="-games-list-container js-game-scroll-container js-game-container col"  >
                        <div className="x-promotion-index">

                            <div className="row px-2">
                                {this.state.list.map(listitem => (
                                    <div
                                        className="col-lg-4 col-6 -promotion-card-link animated fadeInUp"
                                        data-animatable="fadeInUp"
                                        data-delay={100}
                                    >
                                        <a className="d-block h-100 cnodrop"
                                            onClick={(e) => this.reload(e, listitem.date)}
                                        >
                                            <div className="x-card card -multi-card ">
                                                <div className="-img-container">
                                                    <img
                                                        src={listitem.pic}
                                                        alt="รับรายได้จากการเช็คอินรายวัน"
                                                        className="-img"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <div className="-title-container m-3">
                                                        <h3 className="-title">
                                                            {"วันที " + listitem.date} <i className="fas fa-sack-dollar" />
                                                        </h3>
                                                        <p className="-sub-title">
                                                            <i className="fas fa-coins" /> {listitem.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <button
                                            id="loginmode"
                                            href="#loginModal"
                                            className="-btn -btn-play js-account-approve-aware"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>
                </section >
            </div >
        );

    }
}
export default App;
