import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            visible: false,
            slotlist: [],
            recentlist: [],
            lottolist: [],
            rewardlist: [],
            newgamelist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        console.log(44, props);
    }

    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length)
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        this.checklogin();
        instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
            Type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    categorylist: res.data.message
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    slotlist: res.data.message
                });
                //////console.log(res.data.message);
            }
            else {
                // this.CheckThisGoalError();
            }
        });

        instance.post("/api/v1/tabgamelistpop", {
            state: "9",
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    lottolist: res.data.message
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });

        instance.post("/api/v1/tabgamelistpop", {
            state: "7",
            System: Systems,
            Type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({
                    newgamelist: res.data.message
                });
              console.log(111255,res.data.message);
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        instance.post("/api/v1/tabgamelistpop", {
            state: "5",
            System: Systems,
            playerid: Username

        }).then((res) => {
            if (res.data.status === 200) {

                // this.state.recentmenu
                this.setState({
                    recentlist: res.data.message,
                    recentmenu: true
                });
                //////console.log(555, res.data.message);
            }
            else {
                // this.CheckThisGoalError();
            }
        });
    }

    render() {
        const { loginstate } = this.state
        return (
            <>
                <div className="js-replace-cover-seo-container" >
                    <div className="x-homepage-banner-container x-banner-slide-wrapper " style={{backgroundColor:"black"}}>
                        <Slider {...settings}>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-01.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-02.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-03.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-04.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-05.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-06.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-07.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-08.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-09.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/ulo24-10.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>

                        </Slider>

                    </div>
                </div>

                <div className="x-index-content-main-container -anon" style={{ backgroundImage: "url(/build/web/igame-index-lobby-wm/index-bg.jpg)" }} >
                    <div className="x-title-with-tag-header animated fadeInUp">
                        <div className="container-fluid h-100">

                        </div>
                    </div>
                    <div className="x-index-content-main-container -anon">
                        <div
                            className="x-title-with-tag-header animated fadeInUp"
                            data-animatable="fadeInUp"
                            data-delay={150}
                        >
                            <div className="container">
                                <h1 className="-title" style={{ color: "white" }}>
                                Hexa789 สล็อตออนไลน์ ยิงปลา เกมคาสิโน สล็อต gameslot
                                    โปรแรงสุดในไทย
                                </h1>
                            </div>
                        </div>
                        <div className="x-category-total-game -v2">
                            <div className="container-fluid">
                                <nav className="nav-menu" id="navbarCategory">
                                    <ul className="-menu-parent navbar-nav flex-row">
                                        <li
                                            className="-list-parent nav-item px-lg-2 -category-casino animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={100}
                                        >
                                            <a
                                                href="/listgameall/casino/"
                                                className="x-category-button -category-casino -index-page  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-lotto.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-lotto.png?v=9"
                                                    />
                                                    <img
                                                        alt="category casino image png"
                                                        className="-img -default"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-lotto.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-lotto-hover.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-lotto-hover.png?v=9"
                                                    />
                                                    <img
                                                        alt="category casino image png"
                                                        className="-img -hover"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-lotto-hover.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">ประเภทหวย</span>
                                                        <span className="-text-mobile">หวย</span>
                                                    </div>
                                                </span>
                                            </a>
                                        </li>

                                        <li
                                            className="-list-parent nav-item px-lg-2 -category-casino animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={100}
                                        >
                                            <a
                                                href="/listgameall/casino/"
                                                className="x-category-button -category-casino -index-page  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-casino.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-casino.png?v=9"
                                                    />
                                                    <img
                                                        alt="category casino image png"
                                                        className="-img -default"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-casino.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-casino-hover.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-casino-hover.png?v=9"
                                                    />
                                                    <img
                                                        alt="category casino image png"
                                                        className="-img -hover"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-casino-hover.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">คาสิโนสด</span>
                                                        <span className="-text-mobile">คาสิโน</span>
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            className="-list-parent nav-item px-lg-2 -category-slot animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={150}
                                        >
                                            <a
                                                href="/listgameall/slot/"
                                                className="x-category-button -category-slot -index-page  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-slot.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-slot.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot image png"
                                                        className="-img -default"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-slot.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-slot-hover.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-slot-hover.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot image png"
                                                        className="-img -hover"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-slot-hover.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">สล็อต</span>
                                                        <span className="-text-mobile">สล็อต</span>
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            className="-list-parent nav-item px-lg-2 -category-sport animated fadeInUp"
                                            data-animatable="fadeInUp"
                                            data-delay={200}
                                        >
                                            <a
                                                href="/listgameall/sport/"
                                                className="x-category-button -category-sport -index-page  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-sport.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-sport.png?v=9"
                                                    />
                                                    <img
                                                        alt="category sport image png"
                                                        className="-img -default"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-sport.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-sport-hover.png?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/igame-index-lobby-wm/img/menu-category-sport-hover.png?v=9"
                                                    />
                                                    <img
                                                        alt="category sport image png"
                                                        className="-img -hover"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/igame-index-lobby-wm/img/menu-category-sport-hover.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">กีฬา</span>
                                                        <span className="-text-mobile">กีฬา</span>
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="x-lotto-category">
                            <div className="container-fluid">
                                <div
                                    className="-lotto-category-wrapper animated fadeInUp"
                                    data-animatable="fadeInUp"
                                    data-delay={150}
                                >
                                    <div className="x-provider-heading -spacing-top ">
                                        <h2 className="-heading-title">เว็บหวยชั้นนำ</h2>
                                        <p className="-heading-sub-title">ขั้นต่ำ 5 บาท</p>
                                    </div>
                                    <ul className="navbar-nav">
                                        {this.state.lottolist.map((listitem, i) => (
                                            <li className="nav-item -lotto-card-item">
                                                <a
                                                    href={() => false}
                                                    onClick={(e) => this.redeemlotto(e, listitem.gameId, listitem.partner)}
                                                    className="nav-link js-account-approve-aware"
                                                    rel="nofollow noopener"
                                                >
                                                    <div
                                                        className="x-game-list-item-macro-in-share js-game-list-toggle -big-with-countdown-light -cannot-entry -untestable  "
                                                        data-status="-cannot-entry -untestable"
                                                    >
                                                        <div className="-inner-wrapper">
                                                            <div
                                                                className="x-game-badge-component - -big-with-countdown-light animated fadeInUp"
                                                                data-animatable="fadeInUp"
                                                                data-delay={400}
                                                            >
                                                                <span />
                                                            </div>
                                                            <img src={this.addDefaultSrc(null, listitem.image)}
                                                                className="-cover-img lazyload img-fluid"
                                                                alt="pg-soft cover image png"
                                                                width={249}
                                                                onError={(e) => {
                                                                    e.target.src = 'https://play-lh.googleusercontent.com/8guUOMrhHPgiIvmE2x7tvTqwbxLlcVXvpauGxyuXeVq-Mf2L-OSHDPn0i0MY532i9l4' // some replacement image
                                                                }}
                                                                height={361}
                                                            />
                                                            <div className="-overlay">
                                                                <div className="-overlay-inner">
                                                                    <div className="-wrapper-container">
                                                                        <button href={() => false}
                                                                            className="js-account-approve-aware -btn -btn-play"
                                                                        >
                                                                            <i className="fas fa-play" />
                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                        </button>
                                                                        <button
                                                                            id="loginmode"
                                                                            href="#loginModal"
                                                                            className="-btn -btn-play js-account-approve-aware"
                                                                            data-toggle="modal"
                                                                            data-target="#loginModal"
                                                                            style={{ display: "none" }}
                                                                        >
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-title">{listitem.description}</div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="x-lotto-category">
                            <div className="container-fluid">
                                <div
                                    className="-lotto-category-wrapper animated fadeInUp"
                                    data-animatable="fadeInUp"
                                    data-delay={150}
                                >
                                    <div className="x-provider-heading -spacing-top " style={{ color: "black" }}>
                                        <h2 className="-heading-title">Casino ชั้นนำ</h2>
                                    </div>
                                    <ul className="navbar-nav">

                                        {this.state.categorylist.map((listitem, i) => (
                                            <li className="nav-item -lotto-card-item">
                                                <a href={"/listgameall/casino/" + listitem.partner}
                                                    className="nav-link js-account-approve-aware"
                                                    rel="nofollow noopener">
                                                    <div
                                                        className="x-game-list-item-macro-in-share js-game-list-toggle -big-with-countdown-light -cannot-entry -untestable  "
                                                        data-status="-cannot-entry -untestable"
                                                    >
                                                        <div className="-inner-wrapper">
                                                            <div
                                                                className="x-game-badge-component - -big-with-countdown-light animated fadeInUp"
                                                                data-animatable="fadeInUp"
                                                                data-delay={400}
                                                            >
                                                                <span />
                                                            </div>
                                                            <picture>
                                                                <img
                                                                    alt="steal cover image png"
                                                                    className="img-fluid -cover-img lazyloaded"
                                                                    width={456}
                                                                    height={714}
                                                                    src={listitem.imagesec}
                                                                />
                                                            </picture>
                                                            <div className="-overlay">
                                                                <div className="-overlay-inner">
                                                                    <div className="-wrapper-container">
                                                                        <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                            <i className="fas fa-play" />
                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}


                                    </ul>
                                    <div className="x-provider-heading -spacing-top ">
                                        <h2 className="-heading-title">Slot</h2>
                                    </div>
                                    <ul className="navbar-nav">

                                        {this.state.slotlist.map((listitem, i) => (
                                            <li className="nav-item -lotto-card-item">
                                                <a href={"/listgameall/casino/" + listitem.partner}
                                                    className="nav-link js-account-approve-aware"
                                                    rel="nofollow noopener">
                                                    <div
                                                        className="x-game-list-item-macro-in-share js-game-list-toggle -big-with-countdown-light -cannot-entry -untestable  "
                                                        data-status="-cannot-entry -untestable"
                                                    >
                                                        <div className="-inner-wrapper">
                                                            <div
                                                                className="x-game-badge-component - -big-with-countdown-light animated fadeInUp"
                                                                data-animatable="fadeInUp"
                                                                data-delay={400}
                                                            >
                                                                <span />
                                                            </div>
                                                            <picture>
                                                                <img
                                                                    alt="steal cover image png"
                                                                    className="img-fluid -cover-img lazyloaded"
                                                                    width={456}
                                                                    height={714}
                                                                    src={this.addDefaultSrc(null, listitem.image)}
                                                                />
                                                            </picture>
                                                            <div className="-overlay">
                                                                <div className="-overlay-inner">
                                                                    <div className="-wrapper-container">
                                                                        <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                            <i className="fas fa-play" />
                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}


                                    </ul>
                                    <div className="x-provider-heading -spacing-top ">
                                        <h2 className="-heading-title">New Games</h2>
                                    </div>
                                    <ul className="navbar-nav">

                                        {this.state.newgamelist.map((listitem, i) => (
                                            <li className="nav-item -lotto-card-item">
                                                <a href={"/listgameall/casino/" + listitem.partner}
                                                    className="nav-link js-account-approve-aware"
                                                    rel="nofollow noopener">
                                                    <div
                                                        className="x-game-list-item-macro-in-share js-game-list-toggle -big-with-countdown-light -cannot-entry -untestable  "
                                                        data-status="-cannot-entry -untestable"
                                                    >
                                                        <div className="-inner-wrapper">
                                                            <div
                                                                className="x-game-badge-component - -big-with-countdown-light animated fadeInUp"
                                                                data-animatable="fadeInUp"
                                                                data-delay={400}
                                                            >
                                                                <span />
                                                            </div>
                                                            <picture>
                                                                <img
                                                                    alt="steal cover image png"
                                                                    className="img-fluid -cover-img lazyloaded"
                                                                    width={456}
                                                                    height={714}
                                                                    src={this.addDefaultSrc(null, listitem.image)}
                                                                />
                                                            </picture>
                                                            <div className="-overlay">
                                                                <div className="-overlay-inner">
                                                                    <div className="-wrapper-container">
                                                                        <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                            <i className="fas fa-play" />
                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}


                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="-promotion-section-wrapper -anon" >
                            <div className="-big-win-container">
                                <div className="x-big-win-component">
                                    <img className="-bg-big-win d-none d-lg-block lazyload" data-src="/build/web/ez-bet/img/ez-slot-bg-bigwin-desktop.png" alt="รูปเนื้อหา Bigwin" />
                                    <img className="-thumbles d-none d-lg-block lazyload" data-src="/build/web/ez-bet/img/ez-slot-thumbles.gif" alt="รูปตัวอย่างเกมส์" />
                                    <div className="-poringwing-animate d-none d-lg-block lazyload" data-animation-lottie="/build/web/ez-bet/animations/poringwing.json"></div>

                                    <div className="-bonus-win" >
                                        <div className="-win-anima" data-animation-lottie="/build/web/ez-bet/animations/bigwin.json"></div>
                                        <a href="#0" data-toggle="modal" data-target="#registerModal" className="btn -register-btn">
                                            สมัครสมาชิก
                                        </a>
                                        <img src="/build/web/ez-bet/img/ez-slot-coin-01.png" className="-coin -coin-1" alt="JPVIPBET รูปเหรียญทอง png" />
                                        <img src="/build/web/ez-bet/img/ez-slot-coin-02.png" className="-coin -coin-2" alt="JPVIPBET รูปเหรียญทอง png" />
                                        <img src="/build/web/ez-bet/img/ez-slot-coin-03.png" className="-coin -coin-3" alt="JPVIPBET รูปเหรียญทอง png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-status-game-section-wrapper -anon">
                            <div className="container">
                                <ul className="-status-game-container-wrapper">
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={100}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-wallet.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">Smart Wallet</h3>
                                            <span className="-status-detail d-none d-md-block">เล่นได้ทุกเกมส์ไม่ต้องโยกเงิน</span>
                                        </div>
                                    </li>
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={200}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-rank.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">อันดับ 1 ในไทย</h3>
                                            <span className="-status-detail d-none d-md-block">เทคโนโลยี และเกมส์จากทั่วทุกมุมโลก</span>
                                        </div>
                                    </li>
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={300}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-change.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">ฝาก - ถอน เร็วมาก</h3>
                                            <span className="-status-detail d-none d-md-block">ฝาก-ถอนเร็วมากด้วยระบบ Bot AI</span>
                                        </div>
                                    </li>
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={400}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-eveywhere.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">เล่นได้ทุกที่</h3>
                                            <span className="-status-detail d-none d-md-block">ระบบใช้ง่ายรองรับทุกอุปกรณ์</span>
                                        </div>
                                    </li>
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={500}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-support.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">บริการด้วยใจ</h3>
                                            <span className="-status-detail d-none d-md-block">ฝ่ายบริการลูกค้า24 ชม ทุกวัน</span>
                                        </div>
                                    </li>
                                    <li className="-status-list" data-animatable="fadeInUp" data-delay={600}>
                                        <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-promotion.png" alt="Pgline" />
                                        <div className="-status-content">
                                            <h3 className="-status-title h5">โปรดีๆสำหรับคุณ</h3>
                                            <span className="-status-detail d-none d-md-block">กิจกรรมและโปรโมชั่นหลากหลายแรงที่สุดในไทย</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="x-lotto-features">
                            <div className="container-fluid">
                                <h2 className="-section-title">ติดต่อเรา Hexa789</h2>
                                <div className="-lotto-features-wrapper">
                                    <div
                                        className="-feature-item"
                                        data-animatable="fadeInUp"
                                        data-delay={150}
                                    >
                                        <picture>
                                            <img
                                                alt="Lotto features icon"
                                                className="-img img-fluid lazyload"
                                                width={180}
                                                height={160}
                                                src="https://qr-official.line.me/gs/M_569daajb_GW.png?oat_content=url&ts=06082316"
                                            />
                                        </picture>
                                        <h3 className="-text-title">ฝาก - ถอน ออโต้</h3>
                                        <p className="-text-description">
                                            ระบบฝากถอนอัตโนมัติ ตรวจสอบไวไม่เกิน 3นาที สามารถเดิมพันได้ทันที
                                            ไม่ต้องรอนาน
                                        </p>
                                    </div>
                                    <div
                                        className="-feature-item"
                                        data-animatable="fadeInUp"
                                        data-delay={150}
                                    >
                                        <picture>
                                            <source
                                                type="image/webp"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-2.png?v=9"
                                            />
                                            <source
                                                type="image/png"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-2.png"
                                            />
                                            <img
                                                alt="Lotto features icon"
                                                className="-img img-fluid lazyload"
                                                width={180}
                                                height={160}
                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-2.png"
                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            />
                                        </picture>
                                        <h3 className="-text-title">บริการลูกค้า 24 ชม.</h3>
                                        <p className="-text-description">
                                            มีทีมงานคอยให้คำปรึกษาตลอดการเดิมพัน
                                            สามารถช่วยตรวจสอบและแก้ไขปัญหาได้ตลอด 24ชม.
                                        </p>
                                    </div>
                                    <div
                                        className="-feature-item"
                                        data-animatable="fadeInUp"
                                        data-delay={150}
                                    >
                                        <picture>
                                            <source
                                                type="image/webp"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-3.png?v=9"
                                            />
                                            <source
                                                type="image/png"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-3.png"
                                            />
                                            <img
                                                alt="Lotto features icon"
                                                className="-img img-fluid lazyload"
                                                width={180}
                                                height={160}
                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-3.png"
                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            />
                                        </picture>
                                        <h3 className="-text-title">ความมั่นคง</h3>
                                        <p className="-text-description">
                                            อัตราการจ่ายสูง และมีคนเข้าเล่นเดิมพันเยอะที่สุดในไทย
                                            หลักล้านก็ถอนได้ ไม่มีเงื่อนไข
                                        </p>
                                    </div>
                                    <div
                                        className="-feature-item"
                                        data-animatable="fadeInUp"
                                        data-delay={150}
                                    >
                                        <picture>
                                            <source
                                                type="image/webp"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-4.png?v=9"
                                            />
                                            <source
                                                type="image/png"
                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-4.png"
                                            />
                                            <img
                                                alt="Lotto features icon"
                                                className="-img img-fluid lazyload"
                                                width={180}
                                                height={160}
                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/index-lotto-features-4.png"
                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            />
                                        </picture>
                                        <h3 className="-text-title">ครบจบที่เดียว</h3>
                                        <p className="-text-description">
                                            สามารถเดิมพันได้ทุกรูปแบบไม่ว่าจะเป็น หวย สล็อต คาสิโน บอล
                                            เล่นได้ในเว็บเดียวไม่ต้องโยกเงิน
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
