import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            username: null,
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            loginstate: false,
            fullname: null,
            lname: null,
            alert: null,
            depositstate: 0,
            depositamount: 0,
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            affpercent: 0,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            tblnextrank: [],
            tblrankall: [],
            tblprofile: [],
            prodetail: [],
            prolist: [],
            prolistx: [],
            linkaff: "",
            picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
            proderedeem: [],
            buttoncliam: false,
            limit: null,
            rankpic: null,
            rankname: null,
            rankob: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }


    async componentDidMount() {
        try {
            console.log("componentDidMount");
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value,
                    });
                }
            });
        }
        catch (ex) {
            console.log(ex);
        }

    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    componentDidMount() {
        this.promotionlist();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.setState({
                username: Username,
            });


        }
    }

    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    document.getElementById("opendepositsuper").click()
                }
                else if (res.data.status === 201) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 204) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }
                else if (res.data.status === 202) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    e.preventDefault();
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
    }

    checkpro = async (e, proid) => {
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }

            }
            else if (res.data.status === 201) {
                this.setState({
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }

    render() {

        const { prodetail, picnonpro, username, prolistx } = this.state
        console.log(prolistx)
        return (
            <>

                <div className="-full-flex-wrap " style={{
                    backgroundImage: "url(build/web/igame-index-lobby-wm/index-bg.jpg)"
                }}>
                    <div className="js-replace-cover-seo-container">
                        <div
                            className="x-cover -small x-cover-casino x-bg-position-center lazyloaded"
                            data-bgset="/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9"
                            style={{
                                backgroundImage:
                                    'url("/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9")'
                            }}
                        >
                            <div className="x-cover-template-full ">
                                <div className="container -container-wrapper">
                                    <div className="-row-wrapper ">
                                        <div
                                            className="-col-wrapper -first animated fadeInModal"
                                            data-animatable="fadeInModal"
                                        >
                                            <div className="x-cover-typography -v2">
                                                <h1 className="-title">
                                                    เว็บพนัน คาสิโนออนไลน์ การันตีความ ปลอดภัย 100%
                                                </h1>
                                                <p className="-sub-title">
                                                    เดิมพันคาสิโนออนไลน์ เว็บใหญ่ที่สุด เล่นง่ายถอนได้ทุกบิล
                                                </p>
                                            </div>
                                            <button
                                                className="btn x-cover-btn"
                                                data-toggle="modal"
                                                data-target="#registerModal"
                                            >
                                                สมัครสมาชิก
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="x-promotion-index">
                        <div className="container">
                            <div className="row px-2">
                                {prolistx.map(prolists => (
                                    <React.Fragment key={prolists.no}>
                                        <div
                                            className="col-lg-4 col-6 -promotion-card-link"
                                        >
                                            <a
                                                href="#0"
                                                className="d-block h-100 "
                                                data-force="true"
                                                data-toggle="modal"
                                                onClick={(e) => this.checkpro(e, prolists.no)}
                                                data-target="#ProDetailModalsuper"
                                            >
                                                <div
                                                    className="x-card card -multi-card x-bg-position-center lazyloaded"
                                                    // data-bgset="https://www.ez-casino.org/build/web/ezl-ez-casino/img/card-promotion-bg.jpg?v=9"
                                                    // style={{
                                                    //     backgroundImage:
                                                    //         'url("https://www.ez-casino.org/build/web/ezl-ez-casino/img/card-promotion-bg.jpg?v=9")',
                                                    //         fontFamily:"FC Iconic Text,Helvetica Neue,Helvetica,Arial,sans-serif"
                                                    // }}

                                                    data-bgset="/build/web/ez-bet/img/bg-tmp.jpg?v=9"
                                                    style={{
                                                        backgroundImage:
                                                            'url("/build/web/ez-bet/img/bg-tmp.jpg?v=9")'
                                                    }}

                                                >
                                                    <div className="-img-container">
                                                        <img
                                                            style={{ display: (prolists.linkpicture !== null) ? "" : "none" }}
                                                            src={prolists.linkpicture}
                                                            alt="300 ขึ้นไป เพิ่มให้ 100"
                                                            className="-img"
                                                        />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="-title-container m-3">
                                                            <h2 className="-title" style={{color:"#ffffff"}}>{prolists.proname}</h2>
                                                            <h3 className="-sub-title">{prolists.subtitle}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <button
                                                            className="btn -btn js-promotion-apply -mobile"
                                                            data-toggle="modal"
                                                            data-target="#depositModal"
                                                            data-promotion-target="promotion-detail-modal-28"
                                                            data-type="deposit"
                                                            data-dismiss="modal"
                                                            data-url="/promotion/28/apply"
                                                        >
                                                            <span>รับโปรโมชั่น</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                    </React.Fragment>

                                ))}

                            </div>
                        </div>
                    </div>
                    <div
                        id="ProDetailModalsuper"
                        tabIndex={-1}
                        role="dialog"
                        data-ajax-modal-always-reload="true"
                        style={{ paddingRight: 6 }}
                        className="x-modal modal -hello-popup show"
                        aria-modal="true"
                    >
                        <div
                            className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -no-fixed-button"
                            role="document"
                        >
                            <div className="modal-content -modal-content">
                                <button
                                    type="button"
                                    className="close f-1 "
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="fas fa-times" />
                                </button>
                                <div className="modal-header border-bottom-0">
                                    <img
                                        style={{ display: (prodetail.linkpicture !== null) ? "" : "none" }}
                                        src={prodetail.linkpicture}
                                        alt="300 ขึ้นไป เพิ่มให้ 100"
                                        className="-logo js-modal-header-image fadeInModal animated"
                                        data-offset={0}
                                        data-animatable="fadeInModal"
                                        width={150}
                                        height={150}
                                    />
                                </div>
                                <div className="modal-body -modal-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 mt-4">
                                                <div className="x-page-title-component -midsize">
                                                    <div className="-inner-wrapper">
                                                        <div className="-title">โปรโมชั่น</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column" >

                                        {(prodetail.length === 0) ? <div className="-promotion-detail ">
                                            <div className="card-body">
                                                <div className="-title">โปรโมชั่นยังไม่ได้เปิดใช้งาน</div>
                                                <div className="-img-container">
                                                    <img
                                                        src={picnonpro}
                                                        alt="โปรโมชั่นยังไม่ได้เปิดใช้งาน"
                                                        className="-img-promotion img-fluid"
                                                        width={398}
                                                        height={143}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="-real-content" style={{ fontSize: "1em !important", color: "#c4c4c4 !important" }}>
                                                <div className="-promotion-detail ">
                                                    <section
                                                        dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                                    />
                                                    <div className="card-footer" style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                                        <button
                                                            className="btn "
                                                            style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                                            onClick={(e) => this.redeempro(e, prodetail.no)}
                                                        >
                                                            <span>รับโปรโมชั่น</span>
                                                        </button>
                                                        <button id="opendepositsuper" onClick={(e) => this.confirmdeposit(e, 4)} className="btn -btn -get-promotion-btn  " style={{ display: "none" }} data-toggle="modal" data-target="#depositModal"  >
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>}

                                        <a
                                            href="#deposit-choose-promotion"
                                            className="js-account-approve-aware btn -back-btn"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <i className="fas fa-arrow-left" />
                                            <div className="f-6 -text">ย้อนกลับ</div>
                                        </a>
                                        <div className="mx-3 mb-3">
                                            <div className="x-admin-contact -no-fixed">
                                                <span className="x-text-with-link-component">
                                                    <label className="-text-message ">ติดปัญหา</label>
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-message "
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default App;
