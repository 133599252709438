import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            lotto: false,
            casinolist: [],
            sportlist: [],
            arcadelist: [],
            lottolist: [],
            fishlist: [],
            partnerx: null,
            partner: null,
            callapse: false,
            activePage: 1,
            activeid: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            pagestate: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            counter: 0,
            alert: null,
            typepage: ""
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }

    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        console.log("img", origin.length)
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }

    callapsexo = async (num) => {
        //console.log(546565565656, this.state.callapse);
        let check = true
        if (this.state.callapse) check = false
        this.setState({
            callapse: check,
        });
    }
    buttoncheck = async (num) => {
        console.log(num, window.innerWidth);
        if (window.innerWidth < 1200) {
            await instance.post("/api/v1/tabgamelist", {
                state: num.toString(),
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            partner: num,
                            slotlist: res.data.message
                        });
                    }
                });
        }
        else {
            if (num == 3) return document.getElementById("btnbrand").click();
            if (num == 2) return document.getElementById("btncasino").click();
            if (num == 5) return document.getElementById("btnsport").click();
            if (num == 4) return document.getElementById("btnarcade").click();
            if (num == 8) return document.getElementById("btnfish").click();
            if (num == 11) return document.getElementById("btnlotto").click();
        }
    }



    redeem = async (e, gameIdx, partner) => {
        console.log(900, partner, gameIdx);
        var userAgent = navigator.userAgent
        console.log(userAgent);
        if (gameIdx != undefined) {
            this.checklogin();
            if (!this.state.loginstate) {
                console.log(5465656);
                return document.getElementById("loginmode").click();
            }
            if (this.state.username !== null) {
                if (this.state.partner === null) {
                    Swal.fire({
                        position: "center",
                        text: 'กำลังเปิดเกม รอซักครู่',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800
                    });
                    await instance.post("/api/v1/tablauncher", {
                        gameId: gameIdx,
                        partner: partner,
                        System: Systems,
                        playerUsername: this.state.userid,
                        isMobile: isMobile,
                        demo: false
                    })
                        .then((res) => {
                            if (res.data.status === 200) {
                                //////console.log(res.data.message);
                                var test = res.data.message.launcher
                                if (test !== undefined)
                                    if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                        window.location.href = test.mobile;
                                    }
                                    else {

                                        if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                        else window.open(test.mobile);
                                    }
                                else if (test === undefined) {
                                    Swal.fire({
                                        title: 'error!',
                                        text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                        icon: 'error',
                                        confirmButtonText: 'ตกลง'
                                    })
                                }
                            }
                            else {
                                Swal.fire({
                                    title: 'error!',
                                    text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                    icon: 'error',
                                    confirmButtonText: 'ตกลง'
                                })
                                //  this.CheckThisGoalError();
                            }
                        });
                }
                else {
                    if (this.state.partner == 3) window.location.href = '/listgameall/slot/' + partner;
                    else if (this.state.partner == 2) window.location.href = '/listgameall/casino/' + partner;
                    else if (this.state.partner == 5) window.location.href = '/listgameall/sport/' + partner;
                    else if (this.state.partner == 4) window.location.href = '/listgameall/arcade/' + partner;
                    else if (this.state.partner == 8) window.location.href = '/listgameall/fishing/' + partner;
                    else if (this.state.partner == 11) window.location.href = '/listgameall/lotto/' + partner;
                }
            }
        }
        else {
            const search = window.location.href;
            const params = new URLSearchParams(search);
            const space = search.split("/")
            window.location.href = "/listgameall/" + space[4] + "/" + partner;
        }



    }
    onError = (img) => {
        console.log(img)
        return img
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
    }
    reload(e, partner, game) {

        window.location.href = "/listgameall/" + game + "/" + partner;

    }
    checkonline() {

        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        //  console.log(1,space[5].replace("#", ""));
        if (space[5].replace("#", "") !== "") {
            console.log(2);
            this.setState({
                typepage: space[4].replace("#", ""),
                partnerx: space[5].replace("#", ""),
            });
            instance.post("/api/v1/tabgamelist", {
                state: "999",
                System: Systems,
                partner: space[5].replace("#", ""),
                game: space[4].replace("#", ""),
            }).then((res) => {
                // console.log(213,res);
                if (res.data.status === 200) {
                    //  console.log(213,res.data);
                    this.setState({
                        slotlist: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

            this.setState({
                typepage: space[4].replace("#", ""),
            });

        }
        else {
            let typepage = space[4].replace("#", "")
            this.setState({
                typepage: typepage,
            });
            if (typepage == "lotto") {
                instance.post("/api/v1/tabgamelistpop", {
                    state: "9",
                    System: Systems,
                    Type: 3,
                    game: space[4].replace("#", ""),
                }).then((res) => {
                    if (res.data.status === 200) {
                        console.log(234, res.data);
                        this.setState({
                            lotto: true,
                            slotlist: res.data.message
                        });
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });

            }
            if (typepage == "gamehit") {
                instance.post("/api/v1/tabgamelistpop", {
                    state: "998",
                    System: Systems,
                    Type: 4,
                    game: space[4].replace("#", ""),
                }).then((res) => {
                    if (res.data.status === 200) {
                        console.log(234, res.data);
                        this.setState({
                            lotto: false,
                            slotlist: res.data.message
                        });
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });

            } else {
                instance.post("/api/v1/tabgamelistpop", {
                    state: "7",
                    System: Systems,
                    Type: 3,
                    game: space[4].replace("#", ""),
                })
                    .then((res) => {
                        if (res.data.status === 200) {
                            console.log(234, res.data);
                            this.setState({
                                slotlist: res.data.message
                            });
                        }
                        else {
                            //  this.CheckThisGoalError();
                        }
                    });
            }
            this.setState({
                typepage: space[4].replace("#", ""),
            });
        }

        instance.post("/api/v1/tabgamelist", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        casinolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "5",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        sportlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

        instance.post("/api/v1/tabgamelist", {
            state: "8",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        fishlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "4",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        arcadelist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "11",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        lottolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
    }


    render() {
        console.log(355, this.state.slotlist);
        if (this.state.categorylist.length > 0) {
            return (
                <div style={{ backgroundColor: "black" }}>
                    <NavMenu />

                    <section className="x-category-index -v2" style={{ backgroundImage: "url(/build/web/igame-index-lobby-wm/index-bg.jpg)" }} >
                        <div className="-nav-menu-container js-category-menus -v2" style={{ display: isMobile ? "" : "none" }} >
                            <div className="container-fluid">
                                <div className="js-replace-seo-section-top-container">
                                    <div
                                        className="x-title-with-tag-header animated"
                                        data-delay={150}
                                    >
                                        <div className="container">
                                            <h1 className="-title">เว็บพนัน คาสิโนออนไลน์ การันตีความปลอดภัย 100%</h1>
                                            <p className="-sub-title">
                                                เดิมพันคาสิโนออนไลน์ เว็บใหญ่ที่สุด เล่นง่ายถอนได้ทุกบิล
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <nav className="nav-menu" id="navbarCategory">
                                    <ul
                                        className="-menu-parent navbar-nav js-menu-container -left"
                                        id="accordion-games"
                                    >
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/lotto/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    data-loading="_onLoading_"
                                                    className="x-category-button  js-lotto -category-lotto -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">ประเภทหวย</span>
                                                        <span className="-text-mobile">หวย</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/lotto/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    data-loading="_onLoading_"
                                                    className={this.state.typepage == "lotto" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category lotto mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-lotto-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category lotto mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-lotto-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">ประเภทหวย</span>
                                                            <span className="-text-mobile">หวย</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/gamehit/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    className="x-category-button  js-popular -category-popular -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">แนะนำ</span>
                                                        <span className="-text-mobile">แนะนำ</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/gamehit/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    //  className="x-category-button  js-popular -category-popular -index-page -mobile  -category-button-v2 -hoverable "
                                                    className={this.state.typepage == "gamehit" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category skill-game mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-popular-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category skill-game mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-popular-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">แนะนำ</span>
                                                            <span className="-text-mobile">แนะนำ</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/casino/"}
                                                    data-target=".js-game-container"
                                                    // data-href-push-state="/casino"
                                                    data-menu-container=".js-menu-container"
                                                    className="x-category-button  js-casino -category-casino -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">คาสิโนสด</span>
                                                        <span className="-text-mobile">คาสิโน</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/casino/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    className={this.state.typepage == "casino" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -default"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -hover lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category casino mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-casino-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category casino mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-casino-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">คาสิโนสด</span>
                                                            <span className="-text-mobile">คาสิโน</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <a
                                                href={"/listgameall/slot/"}
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-collapse-menu="js-brand"
                                                // className="x-category-button d-lg-none js-brand -category-brand -index-page -mobile  -category-button-v2 -hoverable "
                                                className={this.state.typepage == "slot" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                    />
                                                    <img
                                                        alt="bg image"
                                                        className="img-fluid -img-bg -default lazyloaded"
                                                        data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                    />
                                                    <img
                                                        alt="bg image"
                                                        className="img-fluid lazyload -img-bg -hover"
                                                        data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-mobile.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-mobile.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot mobile image png"
                                                        className="-img-mobile img-fluid -default"
                                                        width={30}
                                                        height={30}
                                                        src="/build/web/ez-bet/img/menu-category-slot-mobile.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-hover-mobile.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-hover-mobile.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot mobile image png"
                                                        className="-img-mobile -hover"
                                                        width={30}
                                                        height={30}
                                                        src="/build/web/ez-bet/img/menu-category-slot-hover-mobile.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">สล็อต</span>
                                                        <span className="-text-mobile">สล็อต</span>
                                                    </div>
                                                    <i className="fas fa-caret-down d-none d-lg-flex" />
                                                </span>
                                            </a>
                                            <a
                                                href="#0"
                                                aria-expanded="false"
                                                className="x-category-button -is-collapse -category-brand collapsed js-brand d-lg-flex d-none  -category-button-icon -hoverable "
                                            >
                                                <div className="-menu-text-wrapper">
                                                    <span className="-text-desktop">สล็อต</span>
                                                    <span className="-text-mobile">25 ค่าย</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/sport/"}
                                                    data-menu-container=".js-menu-container"
                                                    className="x-category-button  js-sport -category-sport -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">กีฬา</span>
                                                        <span className="-text-mobile">กีฬา</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/sport/"}
                                                    data-target=".js-game-container"
                                                    //  data-href-push-state="/sport"
                                                    data-menu-container=".js-menu-container"
                                                    className={this.state.typepage == "sport" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category sport mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-sport-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category sport mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-sport-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">กีฬา</span>
                                                            <span className="-text-mobile">กีฬา</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/live"}
                                                    // data-toggle="modal"
                                                    // data-target="#loginModal"
                                                    className="x-category-button  js-live_lotto -category-live_lotto -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">ดูหวยสด</span>
                                                        <span className="-text-mobile">หวยสด</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/live"}
                                                    // data-toggle="modal"
                                                    // data-target="#loginModal"
                                                    // className="x-category-button  js-live_lotto -category-live_lotto -index-page -mobile  -category-button-v2 -hoverable "
                                                    className={this.state.typepage == "live" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category live lotto bg image png"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category live lotto bg image png"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-live-lotto-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-live-lotto-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category live lotto mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-live-lotto-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-live-lotto-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-live-lotto-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category live lotto mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-live-lotto-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">ดูหวยสด</span>
                                                            <span className="-text-mobile">หวยสด</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/poker/"}
                                                    className="x-category-button  js-dummy -category-dummy -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">โป๊กเกอร์</span>
                                                        <span className="-text-mobile">โป๊กเกอร์</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/poker/"}
                                                    //  className="x-category-button  js-dummy -category-dummy -index-page -mobile  -category-button-v2 -hoverable "
                                                    className={this.state.typepage == "poker" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category dummy mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-dummy-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category dummy mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-dummy-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">โป๊กเกอร์</span>
                                                            <span className="-text-mobile">โป๊กเกอร์</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/fishing/"}
                                                    className="x-category-button  js-steal -category-steal -index-page -mobile  -category-button-icon -hoverable "
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">อาเคด</span>
                                                        <span className="-text-mobile">อาเคด</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href={"/listgameall/fishing/"}
                                                    className={this.state.typepage == "fishing" ? "x-category-button  js-lotto -category-lotto -index-page -mobile  active  -category-button-v2 -hoverable" : "x-category-button  js-lotto -category-lotto -index-page -mobile   -category-button-v2 -hoverable "}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid -img-bg -default lazyloaded"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                            src="/build/web/ez-bet/img/menu-category-bg-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="bg image"
                                                            className="img-fluid lazyload -img-bg -hover"
                                                            data-src="/build/web/ez-bet/img/menu-category-bg-hover-mobile.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category steal mobile image png"
                                                            className="-img-mobile img-fluid -default"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-steal-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-hover-mobile.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-hover-mobile.png?v=9"
                                                        />
                                                        <img
                                                            alt="category steal mobile image png"
                                                            className="-img-mobile -hover"
                                                            width={30}
                                                            height={30}
                                                            src="/build/web/ez-bet/img/menu-category-steal-hover-mobile.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">อาเคด</span>
                                                            <span className="-text-mobile">อาเคด</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="x-menu-child-horizontal js-tab-menu-provider " style={{ display: isMobile && this.state.typepage == "slot" ? "" : "none" }}>
                                <hr className="-hr" />
                                <div className="-inner-wrapper">
                                    <ul className="nav nav-pills -nav js-scroll-ltr js-scroll-ltr-provider-track">
                                        {this.state.categorylist.map(listitem => (
                                            <li className="nav-item -nav-item ">
                                                <a
                                                    href={'/listgameall/slot/' + listitem.partner}
                                                    className={listitem.partner == this.state.partnerx ? "nav-link -nav-link js-side-pragmaticplay-btn active" : "nav-link -nav-link js-side-pragmaticplay-btn"}
                                                    aria-label="pragmaticplay image provider"
                                                    data-target=".js-game-container"
                                                    data-collapse-menu="js-brand"
                                                    data-menu-container=".js-menu-container"
                                                    data-target-collapse="#collapse-brand"
                                                    data-target-collapse-mobile="#collapse-mobile-brand"
                                                    data-button-menu={listitem.partner}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset={listitem.selectimg}
                                                        />
                                                        <source
                                                            type="image/png"
                                                            data-srcset={listitem.selectimg}
                                                        />
                                                        <img
                                                            alt={listitem.partner}
                                                            className="img-fluid -img-btn  lazyload"
                                                            width={40}
                                                            height={40}
                                                            data-src={listitem.selectimg}
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset={listitem.selectimg}
                                                        />
                                                        <source
                                                            type="image/png"
                                                            data-srcset={listitem.selectimg}
                                                        />
                                                        <img
                                                            alt={listitem.partner}
                                                            className="img-fluid -img-btn -hover lazyload"
                                                            width={40}
                                                            height={40}
                                                            data-src={listitem.selectimg}
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-child">{listitem.description}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="-nav-menu-container js-category-menus -v2" style={{ display: isMobile ? "none" : "" }}>
                            <div className="container-fluid">
                                <nav className="nav-menu" id="navbarCategory">
                                    <ul
                                        className="-menu-parent navbar-nav js-menu-container -left"
                                        id="accordion-games"
                                    >
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/lotto/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"

                                                    className={this.state.typepage == "lotto" ? "x-category-button js-lotto   active -category-lotto -category-button-v2 -hoverable " : "x-category-button js-lotto -category-lotto -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto.png?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto.png?v=9"
                                                        />
                                                        <img
                                                            alt="category lotto image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-lotto.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-hover.png?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-lotto-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category lotto image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-lotto-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">ประเภทหวย</span>
                                                            <span className="-text-mobile">หวย</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    className="x-category-button js-lotto -category-lotto -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">ประเภทหวย</span>
                                                        <span className="-text-mobile">หวย</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/gamehit/"}
                                                    data-target=".js-game-container"
                                                    data-menu-container=".js-menu-container"
                                                    className={this.state.typepage == "gamehit" ? "x-category-button js-lotto   active -category-lotto -category-button-v2 -hoverable " : "x-category-button js-lotto -category-lotto -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular.png?v=9"
                                                        />
                                                        <img
                                                            alt="category skill image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-popular.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-hover.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-popular-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category skill image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-popular-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">แนะนำ</span>
                                                            <span className="-text-mobile">แนะนำ</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-ajax-game-load="/_ajax_/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95"
                                                    data-target=".js-game-container"
                                                    data-href-push-state="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95"
                                                    data-menu-container=".js-menu-container"
                                                    data-loading="_onLoading_"
                                                    className="x-category-button js-popular -category-popular -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">แนะนำ</span>
                                                        <span className="-text-mobile">แนะนำ</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/casino/"}
                                                    className={this.state.typepage == "casino" ? "x-category-button js-casino   active -category-lotto -category-button-v2 -hoverable " : "x-category-button js-lotto -category-lotto -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino.png?v=9"
                                                        />
                                                        <img
                                                            alt="category casino image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-casino.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-hover.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-casino-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category casino image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-casino-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">คาสิโนสด</span>
                                                            <span className="-text-mobile">คาสิโน</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-ajax-game-load="/_ajax_/casino"
                                                    data-target=".js-game-container"
                                                    data-href-push-state="/casino"
                                                    data-menu-container=".js-menu-container"
                                                    data-loading="_onLoading_"
                                                    className="x-category-button js-casino -category-casino -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">คาสิโนสด</span>
                                                        <span className="-text-mobile">คาสิโน</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <a
                                                // onClick={(e) => {
                                                //     this.callapsexo()
                                                // }}
                                                data-menu-container=".js-menu-container"
                                                data-collapse-menu="js-brand"
                                                className="x-category-button d-lg-none js-brand -category-brand -category-button-icon -hoverable"
                                            >
                                                <div className="-menu-text-wrapper">
                                                    <span className="-text-desktop">สล็อต</span>
                                                    <span className="-text-mobile">สล็อต</span>
                                                </div>
                                            </a>
                                            <a
                                                href="#0"
                                                onClick={(e) => {
                                                    this.callapsexo()
                                                }}
                                                aria-expanded={this.state.typepage == "slot" ? 'true' : 'false'}
                                                className={this.state.typepage == "slot" ? "x-category-button -is-collapse -category-brand js-brand d-lg-flex d-none -category-button-v2 -hoverable active collapsed" : "x-category-button -is-collapse -category-brand js-brand d-lg-flex d-none -category-button-v2 -hoverable collapsed "}
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot image png"
                                                        className="-img -default"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/ez-bet/img/menu-category-slot.png?v=9"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-hover.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        srcSet="/build/web/ez-bet/img/menu-category-slot-hover.png?v=9"
                                                    />
                                                    <img
                                                        alt="category slot image png"
                                                        className="-img -hover"
                                                        width={300}
                                                        height={82}
                                                        src="/build/web/ez-bet/img/menu-category-slot-hover.png?v=9"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">สล็อต</span>
                                                        <span className="-text-mobile">25 ค่าย</span>
                                                    </div>
                                                    <i className="fas fa-caret-down d-none d-lg-flex" />
                                                </span>
                                            </a>
                                            <div className="d-lg-block d-none">
                                                <div
                                                    id="collapse-brand"
                                                    className={this.state.callapse ? "x-menu-collapse-container -v2 -category-brand collapse show" : "x-menu-collapse-container -v2 -category-brand collapse"}
                                                    data-parent="#accordion-games"
                                                    style={{}}
                                                >
                                                    {this.state.categorylist.map(listitem => (
                                                        <a
                                                            href={'/listgameall/slot/' + listitem.partner}
                                                            type="button"
                                                            className="btn-block -child-collapse"
                                                            data-href-push-state={listitem.partner}
                                                            data-button-menu={listitem.partner}
                                                        >
                                                            <div className="-child-collapse-wrapper">
                                                                <div className="-img-wrapper">
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset={listitem.selectimg}
                                                                            srcSet={listitem.selectimg}
                                                                        />
                                                                        <source
                                                                            type="image/png"
                                                                            data-srcset={listitem.selectimg}
                                                                            srcSet={listitem.selectimg}
                                                                        />
                                                                        <img
                                                                            alt="pragmaticplay"
                                                                            className="img-fluid -img-btn ls-is-cached lazyloaded"
                                                                            width={40}
                                                                            height={40}
                                                                            data-src={listitem.selectimg}
                                                                            src={listitem.selectimg}
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <span className="-menu-text-child">{listitem.description}</span>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/sport/"}
                                                    className={this.state.typepage == "sport" ? "x-category-button js-sport -category-sport active -category-button-v2 -hoverable " : "x-category-button js-sport -category-sport -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport.png?v=9"
                                                        />
                                                        <img
                                                            alt="category sport image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-sport.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-hover.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-sport-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category sport image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-sport-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">กีฬา</span>
                                                            <span className="-text-mobile">กีฬา</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-ajax-game-load="/_ajax_/sport"
                                                    data-target=".js-game-container"
                                                    data-href-push-state="/sport"
                                                    data-menu-container=".js-menu-container"
                                                    data-loading="_onLoading_"
                                                    className="x-category-button js-sport -category-sport -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">กีฬา</span>
                                                        <span className="-text-mobile">กีฬา</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/poker/"}
                                                    // className="x-category-button js-dummy -category-dummy -category-button-v2 -hoverable"
                                                    className={this.state.typepage == "poker" ? "x-category-button js-dummy -category-dummy active -category-button-v2 -hoverable " : "x-category-button js-dummy -category-dummy -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy.png?v=9"
                                                        />
                                                        <img
                                                            alt="category dummy image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-dummy.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-hover.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-dummy-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category dummy image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-dummy-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">โป๊กเกอร์</span>
                                                            <span className="-text-mobile">โป๊กเกอร์</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-toggle="modal"
                                                    data-target="#loginModal"
                                                    className="x-category-button js-dummy -category-dummy -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">โป๊กเกอร์</span>
                                                        <span className="-text-mobile">โป๊กเกอร์</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="-list-parent nav-item">
                                            <div className="d-lg-block d-none">
                                                <a
                                                    href={"/listgameall/fishing/"}
                                                    className={this.state.typepage == "fishing" ? "x-category-button js-steal -category-steal active -category-button-v2 -hoverable " : "x-category-button js-steal -category-steal -category-button-v2 -hoverable"}
                                                >
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal.png?v=9"
                                                        />
                                                        <img
                                                            alt="category steal image png"
                                                            className="-img -default"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-steal.png?v=9"
                                                        />
                                                    </picture>
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-hover.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            srcSet="/build/web/ez-bet/img/menu-category-steal-hover.png?v=9"
                                                        />
                                                        <img
                                                            alt="category steal image png"
                                                            className="-img -hover"
                                                            width={300}
                                                            height={82}
                                                            src="/build/web/ez-bet/img/menu-category-steal-hover.png?v=9"
                                                        />
                                                    </picture>
                                                    <span className="-menu-text-main -text-btn-image">
                                                        <div className="-menu-text-wrapper">
                                                            <span className="-text-desktop">อาเคด</span>
                                                            <span className="-text-mobile">อาเคด</span>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="d-lg-none d-block w-100">
                                                <a
                                                    href="#0"
                                                    data-toggle="modal"
                                                    data-target="#loginModal"
                                                    className="x-category-button js-steal -category-steal -category-button-icon -hoverable"
                                                >
                                                    <div className="-menu-text-wrapper">
                                                        <span className="-text-desktop">อาเคด</span>
                                                        <span className="-text-mobile">อาเคด</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="-games-list-outer-container ">

                            <div className="container-fluid -container-fluid">
                                <div className="-games-list-container js-game-scroll-container js-game-container -menu-horizontal">
                                    <div className="-games-list-wrapper">
                                        <div className="-game-title-wrapper">
                                            <div className="-game-title-inner">
                                                <h2 className="-game-title h3 -shimmer">
                                                    {this.state.typepage} Games
                                                    <span className="-sub-title">({this.state.slotlist.length} games)</span>
                                                </h2>
                                            </div>
                                        </div>
                                        <ul className="navbar-nav -normal-page ">
                                            <li className="nav-item">
                                                <div className="-game-list-heading-container">
                                                    <div className="-inner-wrapper">
                                                        <div className="-overlay">
                                                            <a href="#"
                                                                className="-title"
                                                                target="_blank"
                                                                rel="nofollow noopener"
                                                            >
                                                                Random
                                                                <br />
                                                                Game
                                                            </a>
                                                            <img
                                                                className="-animate-img -pilot-tiny"
                                                                src="/build/web/ez-bet/img/ez-slot-animate-pilot-tiny-good.png"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>


                                            {this.state.lotto ? this.state.slotlist.map(listitem => (
                                                <li className="nav-item -is-lotto-category ">
                                                    <a
                                                        href={() => false}
                                                        onClick={(e) => this.redeemlotto(e, listitem.gameId, listitem.partner)}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener"
                                                    >
                                                        <div
                                                            className="x-game-list-item-macro-in-share js-game-list-toggle -big-with-countdown-light -cannot-entry -untestable  "
                                                            data-status="-cannot-entry -untestable"
                                                        >
                                                            <div className="-inner-wrapper">
                                                                <div
                                                                    className="x-game-badge-component - -big-with-countdown-light animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={400}
                                                                >
                                                                    <span />
                                                                </div>
                                                                <img src={this.addDefaultSrc(null, listitem.image)}
                                                                    className="-cover-img lazyload img-fluid"
                                                                    alt="pg-soft cover image png"
                                                                    width={249}
                                                                    onError={(e) => {
                                                                        e.target.src = 'https://play-lh.googleusercontent.com/8guUOMrhHPgiIvmE2x7tvTqwbxLlcVXvpauGxyuXeVq-Mf2L-OSHDPn0i0MY532i9l4' // some replacement image
                                                                    }}
                                                                    height={361}
                                                                />
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={() => false}
                                                                                className="js-account-approve-aware -btn -btn-play"
                                                                            >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                            <button
                                                                                id="loginmode"
                                                                                href="#loginModal"
                                                                                className="-btn -btn-play js-account-approve-aware"
                                                                                data-toggle="modal"
                                                                                data-target="#loginModal"
                                                                                style={{ display: "none" }}
                                                                            >
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )) : this.state.slotlist.map(listitem => (
                                                <li className="nav-item ">
                                                    <a
                                                        href={() => false}
                                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener"
                                                    >
                                                        <div
                                                            className="x-game-list-item-macro-in-share js-game-list-toggle -big -cannot-entry -untestable  "
                                                            data-status="-cannot-entry -untestable"
                                                        >
                                                            <div className="-inner-wrapper">
                                                                <div
                                                                    className="x-game-badge-component - -big animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={400}
                                                                >
                                                                    <span />
                                                                </div>
                                                                <img src={this.addDefaultSrc(null, listitem.image)}
                                                                    className="-cover-img lazyload img-fluid"
                                                                    alt="pg-soft cover image png"
                                                                    width={249}
                                                                    onError={(e) => {
                                                                        e.target.src = 'https://play-lh.googleusercontent.com/8guUOMrhHPgiIvmE2x7tvTqwbxLlcVXvpauGxyuXeVq-Mf2L-OSHDPn0i0MY532i9l4' // some replacement image
                                                                    }}
                                                                    height={361}
                                                                />
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={() => false}
                                                                                className="js-account-approve-aware -btn -btn-play"
                                                                            >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                            <button
                                                                                id="loginmode"
                                                                                href="#loginModal"
                                                                                className="-btn -btn-play js-account-approve-aware"
                                                                                data-toggle="modal"
                                                                                data-target="#loginModal"
                                                                                style={{ display: "none" }}
                                                                            >
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>

                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section >

                </div >
            );
        } else {

        }
    }
}
export default App;
