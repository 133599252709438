import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2'
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            ranking: [],
            rankingwin: [],
            ranking2: [],
            ranking3: [],
            activePage: 1,
            tblsetting: [],
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            categorylist: [],
            visible: false,
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            newgamelist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }
    async gerranking() {
        await instance.post("/api/v1/getranking", {
            System: Systems,
        })
            .then((res) => {

                var ranking = []
                var ranking1 = []
                var ranking2 = []
                var rankingwin = []
                var x = 0;
                for (let i = 0; i < res.data.message.length; i++) {
                    if (x == 0) {
                        rankingwin.push(res.data.message[i])
                    }
                    else if (x > 0 && x < 10) {
                        ranking.push(res.data.message[i])
                    }
                    else if (x > 10 && x < 21) {
                        ranking1.push(res.data.message[i])
                    }
                    else if (x > 21 && x < 32) {
                        ranking2.push(res.data.message[i])
                    }
                    x = x + 1
                }
                console.log(ranking2);
                this.setState({
                    tblsetting: res.data.tblsetting,
                    ranking: ranking,
                    ranking2: ranking1,
                    ranking3: ranking2,
                    rankingwin: rankingwin,

                });
            });
    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    async componentDidMount() {
        this.checklogin();
        this.gerranking();
    }
    calldate(date) {
        var today = new Date(date);
        var months = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
        var month = months[today.getMonth()];
        return month;
    }
    render() {
        return (
            <div className="x-index-top-container">
                <div>
                    <div className="x-cover x-ranking-cover-container -ranking">
                        <div className="container-fluid h-100 px-lg-3 px-0">
                            <div className="-contents-wrapper">
                                <div className="-ic-star-container">
                                    <img className="img-fluid -ic-star-rank" src="build/web/ez-bet/img/ez-slot-ic-star-rank-animation-1.png" alt="Ez Slot star icon" />
                                    <h1 className="-title">
                                        <div className="d-lg-block d-none">Top 50 Rank of week</div>
                                        <div className="d-lg-none d-block text-center">Top 50 <br /> Rank of week</div>
                                    </h1>
                                </div>
                                <div className="-sub-title">
                                    {(this.state.tblsetting.length > 0) ? "(" + moment(this.state.tblsetting[0].LastStartDate).format('DD') + " " + this.calldate(this.state.tblsetting[0].LastStartDate) + "-" + moment(this.state.tblsetting[0].LastEndDate).format('DD') + " " + this.calldate(this.state.tblsetting[0].LastEndDate) + "  )" : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="x-ranking-container">

                        <img className="img-fluid -ic-mascot-rank" src="build/web/ez-bet/img/ez-slot-jackpot-cover-mascot-1.png" alt="Ez slot mascot coin" />
                        <div className="-ranking-container-wrapper">
                            <img className="img-fluid -ic-mascot-rank-mobile" src="build/web/ez-bet/img/ez-slot-jackpot-cover-mascot-1.png" alt="Ez slot mascot coin" />
                            {this.state.rankingwin.map((listitem, i) => (

                                <div className="-row-container -first-row-bg">
                                    <div className="col-xl-3 col-sm-4 col-auto -image-profile-container">
                                        <div className="col-sm-6 -image-profile-container-wrapper">
                                            <img className="img-fluid -profile-image"
                                                src={(listitem.image == null || listitem.image == "") ? "https://betner.co/build/rank/1.jpeg" : (listitem.picrack == null || listitem.picrack == "") ? "https://betner.co/build/rank/" + listitem.image : listitem.picrack}
                                                alt="customer image" />
                                            <div className="-text-number -first-row">1</div>
                                        </div>
                                        <div className="col-sm-6 -badge-container">
                                            <img className="img-fluid mr-1 -ic-star-rank" src="build/web/ez-bet/img/ez-slot-ic-star-rank-1.png" alt="Ez Slot star icon" />
                                            <img className="img-fluid -ic-crown-rank" src="build/web/ez-bet/img/ez-slot-ic-crown-rank-1.png" alt="Ez Slot crown icon" />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-sm-4 col-auto -games-container">
                                        <div className="-game-button-container">
                                            <a
                                                style={{ cursor: "pointer" }}
                                                href={() => false}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="-play-btn" >
                                                <img className="img-fluid -ic-play" src="build/web/ez-bet/img/ez-slot-jackpot-play-icon-1.png" alt="Ez Slot play icon" />
                                            </a>
                                            <button
                                                id="loginmode"
                                                href="#loginModal"
                                                className="-btn -btn-play js-account-approve-aware"
                                                data-toggle="modal"
                                                data-target="#loginModal"
                                                style={{ display: "none" }}
                                            >
                                            </button>
                                            <a
                                                href={() => false}
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="-play-btn">
                                                <img className="img-fluid -game-img" src={listitem.gameimage} alt="EZ Slot game icon image" />
                                            </a>
                                        </div>
                                        <div className="-game-name-container -first-row">
                                            <div className="-title">รางวัลสูงสุด</div>
                                            <div className="-game-name">{listitem.description}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-sm-4 col-auto -profile-container">
                                        <div className="-profile-container-wrapper -first-row">
                                            <div className="mr-md-2 mr-1">{listitem.playerid.substring(0, 3) + " " + listitem.playerid.substring(3, 6)}</div>
                                            <div className="-hidden-phone-container">
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                            </div>
                                        </div>
                                        <div className="-win-number -first-row">ชนะ  {Number.parseFloat(listitem.win).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                    </div>

                                </div>

                            ))}

                            {this.state.ranking.map((listitem, i) => (
                                <div className="-row-container ">
                                    <div className="col-xl-3 col-sm-4 col-auto -image-profile-container">
                                        <div className="col-sm-6 -image-profile-container-wrapper">
                                            <img className="img-fluid -profile-image"
                                                src={(listitem.image == null || listitem.image == "") ? "https://betner.co/build/rank/1.jpeg" : (listitem.picrack == null || listitem.picrack == "") ? "https://betner.co/build/rank/" + listitem.image : listitem.picrack}
                                                alt="customer image" />
                                            <div className="-text-number ">{i + 2}</div>
                                        </div>
                                        <div className="col-sm-6 -badge-container">
                                            <img className="img-fluid -ic-star-rank" src="build/web/ez-bet/img/ez-slot-ic-star-rank-1.png" alt="Ez Slot star icon" />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-sm-4 col-auto -games-container">
                                        <div className="-game-button-container">
                                            <a
                                                style={{ cursor: "pointer" }}
                                                href={() => false}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="-play-btn" >
                                                <img className="img-fluid -ic-play" src="build/web/ez-bet/img/ez-slot-jackpot-play-icon-1.png" alt="Ez Slot play icon" />
                                            </a>

                                            <a
                                                href={() => false}
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="-play-btn">
                                                <img className="img-fluid -game-img" src={listitem.gameimage} alt="EZ Slot game icon image" />
                                            </a>

                                            {/* <button
                                                id="loginmode"
                                                href="#loginModal"
                                                className="-btn -btn-play js-account-approve-aware"
                                                data-toggle="modal"
                                                data-target="#loginModal"
                                                style={{ display: "none" }}
                                            >
                                            </button> */}
                                        </div>
                                        <div className="-game-name-container ">
                                            <div className="-game-name">{listitem.description}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-sm-4 col-auto -profile-container">
                                        <div className="-profile-container-wrapper ">
                                            <div className="mr-md-2 mr-1"> {listitem.playerid.substring(0, 3) + " " + listitem.playerid.substring(3, 6)}</div>
                                            <div className="-hidden-phone-container">
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                                <img className="img-fluid -ic" src="build/web/ez-bet/img/ez-slot-jackpot-phone-hidden-icon-1.png" alt="Ez slot hidden phone icon" />
                                            </div>
                                        </div>
                                        <div className="-win-number ">ชนะ  {Number.parseFloat(listitem.win).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex">
                                <div className="col-6 pl-0">
                                    {this.state.ranking2.map((listitem, i) => (

                                        <div className="-row-container">
                                            <div className="col-sm-4 col-auto -image-profile-container">
                                                <div className="-image-profile-container-wrapper">
                                                    <img className="img-fluid -profile-image"
                                                        src={(listitem.image == null || listitem.image == "") ? "https://betner.co/build/rank/1.jpeg" : (listitem.picrack == null || listitem.picrack == "") ? "https://betner.co/build/rank/" + listitem.image : listitem.picrack}
                                                        alt="customer image" />
                                                    <div className="-text-number -small">{i + 11}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-auto -profile-container -small" style={{ marginLeft: "-15px" }}>
                                                <div className="-profile-container-wrapper -small">
                                                    <div className="mr-2">{listitem.playerid.substring(0, 3) + " " + listitem.playerid.substring(3, 3)}</div>
                                                    <span>xxxx</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-auto -win-number -small" style={{ marginLeft: "-15px" }}>
                                                <div className="-number-wrapper" > {Number.parseFloat(listitem.win).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                                <div className="col-6 pr-0" style={{ marginLeft: "-15px" }}>
                                    {this.state.ranking3.map((listitem, i) => (
                                        <div className="-row-container">
                                            <div className="col-sm-4 col-auto -image-profile-container">
                                                <div className="-image-profile-container-wrapper">
                                                    <img className="img-fluid -profile-image"
                                                        src={(listitem.image == null || listitem.image == "") ? "https://betner.co/build/rank/1.jpeg" : (listitem.picrack == null || listitem.picrack == "") ? "https://betner.co/build/rank/" + listitem.image : listitem.picrack}
                                                        alt="customer image" />
                                                    <div className="-text-number -small">{i + 21}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-auto -profile-container -small" style={{ marginLeft: "-20px" }}>
                                                <div className="-profile-container-wrapper -small">
                                                    <div className="mr-2">{listitem.playerid.substring(0, 3) + " " + listitem.playerid.substring(3, 7)}</div>
                                                    <span>xxxx</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-auto -win-number -small" style={{ marginLeft: "-20px" }}>
                                                <div className="-number-wrapper"> {Number.parseFloat(listitem.win).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default App;
