import React, { Component } from 'react'; //different

import Footer from './footer';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rewardlist: [],
            activePage: 1,
            isActive: false,
            categorylist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            linelink: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            left: false,
        }
        this.login = this.login.bind(this);
    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        //////console.log(username, password);
        instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then((res) => {
                //////console.log(res);
                if (res.status === 200) {
                    //////console.log(res.data.status);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            userid: res.data.message.member_id,
                            username: res.data.message.playerid,
                            firstname: res.data.message.firstname,
                            lastname: res.data.message.lastname,
                            point: res.data.message.Point,
                            balance: res.databalance,
                            loginstate: true,
                        });
                        window.location.reload(false);
                    }
                    else if (res.data.status === 401) {
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                    }
                }
                else {
                    this.setState({
                        firstname: null,
                        lastname: null,
                        point: null,
                        loginstate: false,
                    });
                    var title = 'Error!'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
            });
    }
    async componentDidMount() {
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value,
                        System: res.data.tblcatagoryx[0].System
                    });
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }
    onChange = event => {
        let nam;
        let val;
        if (event.target.name === undefined) {
            // //////console.log("undefined", event.target);
            nam = event.name;
            val = event.value;
        } else {
            // //////console.log("event", event.target);
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };

    render() {
        return (
            <div className="x-modal-separator-container x-login-form">
                <div className="-top ">
                    <div data-animatable="fadeInModal">
                        <div className="-img-container text-center">
                        </div>
                        <form  className="js-login-form">
                            <div className="-x-input-icon mb-3 flex-column">
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-ic_phone.png"
                                    className="-icon img-fluid lazyload"
                                    alt="login"
                                    width={10}
                                    height={14}
                                />
                                <input
                                    type="text"
                                    id="username"
                                    inputMode="text"
                                    onChange={this.onChange}
                                    name="username"
                                    pattern="[0-9]*"
                                    autoFocus=""
                                    className="form-control x-form-control"
                                    placeholder="เบอร์โทรศัพท์"
                                />
                            </div>
                            <div className="-x-input-icon flex-column">
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-ic-lock-input.png"
                                    className="-icon img-fluid lazyload"
                                    alt="password"
                                    width={14}
                                    height={14}
                                />
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={this.onChange}
                                    className="form-control x-form-control"
                                    placeholder="รหัสผ่าน"
                                />
                            </div>
                            <div className="x-reset-pw-text-container">
                                <a
                                    href="#resetPassword"
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    data-target="#resetPasswordModal"
                                >
                                    <u>ลืมรหัสผ่าน</u>
                                </a>
                            </div>
                            <div className="text-center">

                                <button type="submit"
                                onClick={(e) => {
                                    this.login(e)
                                }} className="btn btn-primary -submit">
                                    <span>เข้าสู่ระบบ</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="-bottom ">
                    <div data-animatable="fadeInModal">
                        <div className="x-admin-contact ">
                            <span className="x-text-with-link-component">
                                <label className="-text-message ">พบปัญหา</label>
                                <a
                                    href={this.state.linelink}
                                    className="-link-message "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default App;
