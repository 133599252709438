

import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rewardlist: [],
            activePage: 1,
            isActive: false,
            categorylist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            linelink: "",
            amount: "",
            remark: "",
            name: "",
            val1: "",
            val2: "",
            val3: "",
            val4: "",
            val5: "",
            val6: "",
            password: "",
            left: false,
        }
    }
    async modalerror(object) {
        await Swal.fire({
            title: object.title,
            text: object.text,
            icon: object.title,
            confirmButtonText: 'ตกลง'
        })
    }
    validatergisterForm = async (e) => {
        const { username, fname, checkboxacc, accnumbertxt, link, val1, val2, val3, val4, val5, val6 } = this.state;
        let password = val1 + val2 + val3 + val4 + val5 + val6;
        if (password.length < 6) {
            var title = 'Error!'
            var text = 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ'
            var object = { title, text }
            this.modalerror(object);
        }

        else if (username === "") {

            title = 'Error!'
            text = 'คุณไม่ทำการใส่ เบอร์โทรศัพท์'
            object = { title, text }
            this.modalerror(object);

        }
        else if (accnumbertxt === "") {
            title = 'Error!'
            text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
            object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "") {

            title = 'Error!'
            text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
            object = { title, text }
            this.modalerror(object);

        }
        else if (checkboxacc === 0) {
            title = 'Error!'
            text = 'คุณยังไม่ได้เลือกธนาคาร'
            object = { title, text }
            this.modalerror(object);
        }

        else {
            return await instance.post("/api/v1/register", {
                User_id: username,
                name: fname,
                password: password,
                accbank: checkboxacc,
                accno: accnumbertxt,
                System: Systems,
                link: link,
                Useradvise: this.state.userrecomend
            })
                .then(async (res) => {
                    if (res.data.status === 200) {

                        await Swal.fire({
                            title: 'Success!',
                            text: 'ทำการสมัครเรียบร้อย',
                            icon: 'success',
                            confirmButtonText: 'ตกลง'
                        })

                        return await instance.post("/api/v1/userlogin", {
                            userid: username,
                            password: password,
                            System: Systems,
                            Function: 'validateLoginForm-navbar.js'
                        }).then((res) => {
                            if (res.status === 200) {
                                if (res.data.status === 200) {
                                    localStorage.setItem('auth_token', res.data.token);
                                    return true;
                                }
                            }
                        });
                    }
                    else if (res.data.status === 203) {
                        await Swal.fire({
                            title: 'Error!',
                            text: 'คุณไม่ได้เลือกธนาคาร',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        this.setState({
                            errorstatus: 1,
                            errortext: 'คุณไม่ได้เลือกธนาคาร'
                        });
                        return false;
                    }
                    else if (res.data.status === 202) {
                        await Swal.fire({
                            title: 'Error!',
                            text: 'Username นี้ทำการสมัครไปแล้ว',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        this.setState({
                            errorstatus: 1,
                            errortext: 'Username นี้ทำการสมัครไปแล้ว'
                        });
                        return false;
                    }
                    else {
                        await Swal.fire({
                            title: 'Error!',
                            text: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        this.setState({
                            errorstatus: 1,
                            errortext: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                        });
                        return false;
                    }
                });
        }


    };
    confirm = async (e, step, func) => {
        const { username, password } = this.state;
        let val = 1
        let checked = true;
        if (step == 1 && func == 1) {
            if (username.length === 10) val = 3
            // this.nameInput1.focus();
        }
        else if (step == 11) {
            val = 3
        }
        else if (step == 2 && func == 2) val = 4
        else if (step == 4 && func == 4) {
            let checkd = await this.validatergisterForm(e);
            console.log(checkd);
            if (checkd) {
                this.setState({
                    activeTab: 5,
                });
                val = 5
                setTimeout(function () {
                    window.location.href = '/'
                }, 3500);
            }
            else {
                checked = false;
            }
        }
        if (checked) {
            this.setState({
                activeTab: val,
            });
        }
    }
    onChangex = event => {
        console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "phoneNumber") {
                // document.getElementById("phoneNumberforget").style = "";
                // document.getElementById("checkotpforgetphone").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    onChange = (event) => {
        let nam;
        let val;
        console.log(222152445222, event);
        if (event.target.name === undefined) {
            nam = event.name;
            val = event.value;
        } else {
            nam = event.target.name;
            val = event.target.value;



        }
        this.setState({ [nam]: val });

    };
    onChangexx = (event) => {
        let nam;
        let val;
        console.log(112113223, event.target.name);
        if (event.target.name === undefined) {
            nam = event.name;
            val = event.value;
        } else {
            nam = event.target.name;
            val = event.target.value;
            if (nam == "val1") {
                if (val.length == 1) {
                    this.nameInput2.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val2") {
                if (val.length == 1) {
                    this.nameInput3.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val3") {
                if (val.length == 1) {
                    this.nameInput4.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val4") {
                if (val.length == 1) {
                    this.nameInput5.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val5") {
                if (val.length == 1) {
                    this.nameInput6.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val6") {
                if (val.length == 1) {
                    console.log(val.length);
                    this.setState({ [nam]: val });
                }
                else return;
            }
        }

    };
    render() {
        return (
            <div >
                <div className="modal-content -modal-content">
                    <button
                        type="button"
                        className="close f-1 -in-tab"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="fas fa-times" />
                    </button>
                    <div className="x-modal-account-security-tabs js-modal-account-security-tabs -v3">
                        <button
                            type="button"
                            className="-btn -register js-modal-account-security-tab-button -active"
                            data-modal-target="#registerModal"
                        >
                            สมัครสมาชิก
                            <picture>
                                <img
                                    alt="Hexa789hImage"
                                    className="img-fluid -ic-badge lazyloaded"
                                    data-src="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                    src="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                />
                            </picture>
                        </button>
                        <button
                            type="button"
                            className="-btn -login js-modal-account-security-tab-button "
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#loginModal"
                        >
                            เข้าสู่ระบบ
                        </button>
                    </div>
                    <div className="modal-body -modal-body">
                        <div className="x-register-tab-container -register js-tab-pane-checker-v3">
                            <div className="container">
                                <ul className="nav nav-tabs x-register-tab">
                                    <li className="nav-item   -phoneNumber" id="tab-phoneNumber">
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-phoneNumber"
                                            className="nav-link"
                                        >
                                            phoneNumber
                                        </a>
                                    </li>
                                    <li className="nav-item   -otp" id="tab-otp">
                                        <a data-toggle="tab" href="#tab-content-otp" className="nav-link">
                                            otp
                                        </a>
                                    </li>
                                    <li className="nav-item   -setPassword" id="tab-setPassword">
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-setPassword"
                                            className="nav-link"
                                        >
                                            setPassword
                                        </a>
                                    </li>
                                    <li className="nav-item   -bank" id="tab-bank">
                                        <a data-toggle="tab" href="#tab-content-bank" className="nav-link">
                                            bank
                                        </a>
                                    </li>
                                    <li className="nav-item   -result" id="tab-result">
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-result"
                                            className="nav-link"
                                        >
                                            result
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className={this.state.activeTab == 1 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-phoneNumber"
                                    data-redirect-url-register-success=""
                                >
                                    <div className="x-modal-body-base -v3 -phone-number x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">กรอกหมายเลขโทรศัพท์</span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="-fake-inner-body">
                                                    <div
                                                        method="post"
                                                        data-register-step="phoneNumber"
                                                        data-tab-next-step="#tab-content-otp"
                                                    >
                                                        <div className="-x-input-icon input-group text-center js-register-v3-input-group">
                                                            <input
                                                                required=""
                                                                autoComplete="off"
                                                                id="username"
                                                                value={this.state.username}
                                                                pattern="[0-9]"
                                                                type='number'
                                                                onChange={this.onChange}
                                                                name="username"
                                                                placeholder="095-123-4567"
                                                                className="form-control x-form-control js-keypad-desktop"
                                                            />
                                                        </div>
                                                        <div className="d-none js-keypad-number-wrapper">
                                                            <div className="x-keypad-number-container">
                                                                <div className="-btn-group-wrapper">
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={1}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        1
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={2}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        2
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={3}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        3
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={4}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        4
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={5}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        5
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={6}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        6
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={7}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        7
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={8}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        8
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={9}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        9
                                                                    </button>
                                                                    <div
                                                                        className="btn -btn js-keypad-btn -btn-none"
                                                                        type="button"
                                                                        data-key="none"
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    />
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                        type="button"
                                                                        data-key={0}
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        0
                                                                    </button>
                                                                    <button
                                                                        className="btn -btn js-keypad-btn -btn-backspace"
                                                                        type="button"
                                                                        data-key="backspace"
                                                                        data-target="#registerPhoneNumber"
                                                                        data-options='{"maxLength":12}'
                                                                    >
                                                                        <i className="fas fa-backspace" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="x-text-with-link ">
                                                            <span className="-message">
                                                                เมื่อท่านสมัครเท่ากับยอมรับข้อตกลง
                                                            </span>
                                                            <a
                                                                href="/term-and-condition"
                                                                className="-btn-link -x"
                                                                target="_blank"
                                                                rel="nofollow noreferrer noopener"
                                                            >
                                                                Term and condition
                                                            </a>
                                                        </div>
                                                        <div className="text-center">
                                                            <button
                                                                onClick={(e) => {
                                                                    this.confirm(e, 1, 1)
                                                                }}
                                                                type="submit"
                                                                className="btn  -submit mt-lg-3 mt-0 js-toggle-disabled-length"
                                                            >
                                                                ยืนยัน
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 2 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-otp"
                                    data-redirect-url-register-success=""
                                >
                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">กรอกรหัส OTP</span>
                                                    <span className="-sub-title">
                                                        รหัส 4 หลัก ส่งไปยัง{" "}
                                                        <span className="js-phone-number -highlight" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="x-modal-separator-container x-form-register">
                                                    <div className="-top ">
                                                        <div
                                                            data-animatable="fadeInRegister"
                                                            data-offset={0}
                                                            className="-animatable-container -otp-body animated fadeInRegister"
                                                        >
                                                            <div
                                                                method="post"
                                                                name="js-register-v3-otp-form"
                                                                data-register-step="otp"
                                                                data-tab-next-step="#tab-content-setPassword"
                                                            >
                                                                <div className="d-flex -otp-input-container js-register-v3-input-group">
                                                                    <div className="js-separator-container js-register-otp-container">
                                                                        <input
                                                                            type="text"
                                                                            id="registerOtp0"
                                                                            name="otp0"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            className="-digit-otp js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="otp"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-register-otp-container">
                                                                        <input
                                                                            type="text"
                                                                            id="registerOtp1"
                                                                            name="otp1"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            className="-digit-otp js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="otp"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-register-otp-container">
                                                                        <input
                                                                            type="text"
                                                                            id="registerOtp2"
                                                                            name="otp2"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            className="-digit-otp js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="otp"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-register-otp-container">
                                                                        <input
                                                                            type="text"
                                                                            id="registerOtp3"
                                                                            name="otp3"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            className="-digit-otp js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="otp"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    type="hidden"
                                                                    id="otp"
                                                                    name="otp"
                                                                    pattern="[0-9]*"
                                                                    className="form-control mb-3"
                                                                    defaultValue=""
                                                                />
                                                                <a
                                                                    href="/register-clear"
                                                                    className="-text-clear-otp mt-3"
                                                                >
                                                                    ยกเลิก
                                                                </a>
                                                                <div className="d-none js-keypad-number-wrapper">
                                                                    <div className="x-keypad-number-container">
                                                                        <div className="-btn-group-wrapper">
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={1}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                1
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={2}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                2
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={3}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                3
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={4}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                4
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={5}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                5
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={6}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                6
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={7}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                7
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={8}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                8
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={9}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                9
                                                                            </button>
                                                                            <div
                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                type="button"
                                                                                data-key="none"
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            />
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={0}
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                0
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                type="button"
                                                                                data-key="backspace"
                                                                                data-target="#registerOtp0"
                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-register-otp-container","targetSubmitForm":"js-register-v3-otp-form"}'
                                                                            >
                                                                                <i className="fas fa-backspace" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn -submit btn-primary my-lg-3 mt-0"
                                                                    >
                                                                        ยืนยัน
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-bottom "></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 3 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-setPassword"
                                    data-redirect-url-register-success=""
                                >
                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">ตั้งตัวเลขรหัส 6 หลัก</span>
                                                    <span className="-sub-title">
                                                        กรอกเลขรหัส 6 หลัก เพื่อใช้เข้าสู่ระบบ
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="x-modal-separator-container x-form-register">
                                                    <div className="-top ">
                                                        <div
                                                            data-animatable="fadeInRegister"
                                                            data-offset={0}
                                                            className="-animatable-container -password-body js-register-set-password animated fadeInRegister"
                                                        >
                                                            <div className="d-flex -password-input-container js-register-v3-input-group">
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        type="text"
                                                                        ref={(input) => { this.nameInput1 = input; }}
                                                                        id="registerSetPassword_1"
                                                                        name="val1"
                                                                        maxLength={1}
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        type="text"
                                                                        ref={(input) => { this.nameInput2 = input; }}
                                                                        id="registerSetPassword_2"
                                                                        name="val2"
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        maxLength={1}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        ref={(input) => { this.nameInput3 = input; }}
                                                                        type="text"
                                                                        id="registerSetPassword_3"
                                                                        name="val3"
                                                                        maxLength={1}
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        ref={(input) => { this.nameInput4 = input; }}
                                                                        type="text"
                                                                        id="registerSetPassword_3"
                                                                        name="val4"
                                                                        maxLength={1}
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        ref={(input) => { this.nameInput5 = input; }}
                                                                        type="text"
                                                                        id="registerSetPassword_3"
                                                                        name="val5"
                                                                        maxLength={1}
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-register-password-container">
                                                                    <input
                                                                        ref={(input) => { this.nameInput6 = input; }}
                                                                        type="text"
                                                                        id="registerSetPassword_3"
                                                                        name="val6"
                                                                        maxLength={1}
                                                                        onChange={(e) => { this.onChangexx(e) }}
                                                                        className="-digit-password "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-none js-keypad-number-wrapper">
                                                                <div className="x-keypad-number-container">
                                                                    <div className="-btn-group-wrapper">
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={1}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            1
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={2}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            2
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={3}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            3
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={4}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            4
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={5}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            5
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={6}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            6
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={7}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            7
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={8}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            8
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={9}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            9
                                                                        </button>
                                                                        <div
                                                                            className="btn -btn js-keypad-btn -btn-none"
                                                                            type="button"
                                                                            data-key="none"
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        />
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={0}
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            0
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-backspace"
                                                                            type="button"
                                                                            data-key="backspace"
                                                                            data-target="#registerSetPassword_1"
                                                                            data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                        >
                                                                            <i className="fas fa-backspace" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <button
                                                                    onClick={(e) => {
                                                                        this.confirm(e, 2, 2)
                                                                    }}
                                                                    type="button"
                                                                    className="btn -submit my-lg-3 mt-0 js-set-password-tab-button"
                                                                >
                                                                    ยืนยัน
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-bottom "></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 4 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-bank"
                                    data-redirect-url-register-success=""
                                >
                                    <div className="x-modal-body-base -v3 -bank-account x-form-register-v3 js-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col -title-bank-account">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">บัญชีธนาคาร</span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="x-form-register mt-0">
                                                    <div
                                                        data-animatable="fadeInRegister"
                                                        data-offset={0}
                                                        className="-animatable-container animated fadeInRegister"
                                                    >
                                                        <div className="x-bank-account-form flex-column js-bank-account-form-v3">
                                                            <div
                                                                data-animatable="fadeInModal"
                                                                className="-animatable-container animated fadeInModal"
                                                            >
                                                                <div id="card-bank-info">
                                                                    <div className="text-center">
                                                                        <div className="my-3 js-bank-select-container">
                                                                            <div className="x-bank-choices-type ">
                                                                                <div className="-outer-wrapper" id='checkboxacc' onChange={this.onChangex}>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-51692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={5}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-51692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/84c0f97b9a4a02bd519f6256da291d06.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-61692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={6}
                                                                                        data-gtm-form-interact-field-id={6}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-61692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/75cf7484ae422a0d3897e82556cb04fd.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-71692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={7}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-71692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/ffcda61156271aa5d354b4d052590a14.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-81692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={8}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-81692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/4982427c6801f5e172ab1205a9166e2f.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-91692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={9}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-91692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/62cdaab59a028355f43825a426c41b94.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-111692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={11}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-111692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/6220cfdee8ca83b5533e6d5ac87b778b.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-121692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={12}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-121692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/a3671c1989ca4935f046b184b98b28d1.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-141692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={14}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-141692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/de6513eee5e2d90848de36a1be081ef5.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-151692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={15}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-151692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/4320a4686226cba7c35cfdbbe25a0e95.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-161692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={16}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-161692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/48e5e7cc029492341f34b7ce38926edd.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-171692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={17}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-171692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="/media/cache/strip/201910/a68c3e5add8754e2c0e986c408c08c24.png"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="-input-radio"
                                                                                        id="bank-acc-991692356943"
                                                                                        name="checkboxacc"
                                                                                        defaultValue={99}
                                                                                    />
                                                                                    <label className="-label" htmlFor="bank-acc-991692356943">
                                                                                        <img
                                                                                            className="-logo"
                                                                                            src="https://yt3.googleusercontent.com/aWp38-LVqL_fztxB3VkBaYHj6otE3dmCq-QMhEJUdRvDQbn1xU5gvU9CrB15x6-aWbLA9oQ1CQ=s900-c-k-c0x00ffffff-no-rj"
                                                                                            alt=""
                                                                                        />
                                                                                        <i className="fas fa-check" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="js-bank-number-and-name-container position-relative">
                                                                            <div className="x-bank-info-validate-box -v2" style={{ color: "black" }} >
                                                                                <div className="-content-validate">
                                                                                    โปรดตรวจสอบ ชื่อ และ เลขบัญชี !!
                                                                                </div>
                                                                                <div className="-detail-validate">
                                                                                    หากไม่ถูกต้อง จะไม่สามารถแก้ไข หรือ ฝากถอนได้
                                                                                </div>
                                                                                <i className="fas fa-arrow-right" />
                                                                            </div>
                                                                            <div className="">
                                                                                <img
                                                                                    className="-icon"
                                                                                    alt="กระเป๋าเงิน ฝากถอน ออโต้"
                                                                                    width={15}
                                                                                    height={15}
                                                                                    src="/build/web/igame-index-lobby-wm/img/ic-library-book-v2.png?v=5"
                                                                                />
                                                                                <input
                                                                                    type="number"
                                                                                    onChange={this.onChangex}
                                                                                    id="accnumbertxt"
                                                                                    name="accnumbertxt"
                                                                                    required="required"
                                                                                    pattern="[0-9]*"
                                                                                    className="x-form-control form-control"
                                                                                    placeholder="กรอกเลขบัญชี"
                                                                                    autoFocus="autoFocus"
                                                                                    inputMode="text"
                                                                                />
                                                                            </div>
                                                                            <div className="">
                                                                                <img
                                                                                    className="-icon"
                                                                                    alt="รองรับการเข้าใช้งานหลายผู้เล่น 24 ชั่วโมง"
                                                                                    width={15}
                                                                                    height={15}
                                                                                    src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png?v=5"
                                                                                />
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={this.onChangex}
                                                                                    id="fname"
                                                                                    name="fname"
                                                                                    required="required"
                                                                                    className="x-form-control form-control"
                                                                                    placeholder="กรอก ชื่อ - นามสกุล (ภาษาไทยเท่านั้น)"
                                                                                />
                                                                            </div>
                                                                            <div className="-text-hint col-sm-10 m-auto d-block d-lg-none">
                                                                                กรอกข้อมูลให้ครบถ้วนและถูกต้องมิฉะนั้นท่านจะไม่สามารถถอนเงินได้
                                                                            </div>
                                                                        </div>
                                                                        <a href={() => false} style={{ cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                this.confirm(e, 11, 11)
                                                                            }} className="text-muted mt-3">
                                                                            ยกเลิก
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={(e) => {
                                                                            this.confirm(e, 4, 4)
                                                                        }}
                                                                        className="btn -submit my-lg-3 mt-0 js-set-password-tab-button"
                                                                    >
                                                                        ยืนยัน
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 5 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-result"
                                    data-redirect-url-register-success="/?action=deposit"
                                >
                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="text-center d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center">
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                            />
                                                            <img
                                                                alt="สมัครสมาชิก SUCCESS"
                                                                className="js-ic-success -success-ic img-fluid"
                                                                width={150}
                                                                height={150}
                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                            />
                                                        </picture>
                                                    </div>
                                                    <div className="-title">สมัครสมาชิกสำเร็จ!</div>
                                                    <div className="-sub-title">ระบบกำลังพาคุณไปหน้าหลัก</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;

