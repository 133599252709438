import axios from 'axios';
var api_URL= 'http://localhost:7002'
if (process.env.NODE_ENV === "development") {
  api_URL = 'http://localhost:7001'
}
else if (process.env.NODE_ENV === "production") {
  api_URL = 'https://betner.co:7001'
}
const instance = axios.create({
  baseURL: api_URL
});

const AUTH_TOKEN = "123"
const System = "Betner"
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;
