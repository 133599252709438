import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            list: [],
            casinolist: [],
            sportlist: [],
            arcadelist: [],
            fishlist: [],
            activePage: 1,
            activeid: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            pagestate: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            weekNumber: 0,
            counter: 0,
            alert: null,
            typepage: ""
        }

    }





    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        //  console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                System: Systems,
                playerUsername: this.state.userid,
                isMobile: isMobile,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined)
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {

                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
        this.getcheckin();
    }
    reload(e, date) {
        var userAgent = navigator.userAgent
        //  console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        else {
            var token = localStorage.getItem('auth_token');
            var userid = null
            if (token != null) {
                var decoded = jwt_decode(token);
                userid = decoded.message.playerid;
                var curr = new Date;
                var startDate = new Date(curr.getFullYear(), 0, 1);
                let days = Math.floor((curr - startDate) /
                    (24 * 60 * 60 * 1000));
                let weekNumber = Math.ceil(days / 7);

                var row = {
                    CheckinID: date,
                    userid: userid,
                    status: 1,
                    dateupdate: curr,
                    weekofyear: weekNumber,
                    monthofyear: moment(curr).format('MM'),
                    dayofyear: days + 1,

                }

                instance.post("/api/v1/updatecheckinlist", {
                    System: Systems,
                    userid: userid,
                    rows: row
                })
                    .then((res) => {
                        if (res.data.status === 200) {
                            Swal.fire({
                                title: 'Success!',
                                text: 'ท่าน checkin เรียบร้อย',
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else if (res.data.status === 202) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่านไม่สามารถเชคอินล่วงหน้าได้',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else if (res.data.status === 204) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่านต้องเริ่มเชคอินใหม่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'Error!',
                                text: 'ท่าน checkin ไปแล้ว',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    });



            }


        }
    }
    getcheckin() {

        var token = localStorage.getItem('auth_token');
        var userid = null
        if (token != null) {
            var decoded = jwt_decode(token);
            userid = decoded.message.playerid;
        }
        instance.post("/api/v1/getcheckin", {
            System: Systems,
            userid: userid
        })
            .then((res) => {
                console.log(111455555, res.data);
                if (res.data.status === 200) {
                    this.setState({
                        list: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });



    }
    checkonline() {

        instance.post("/api/v1/tabgamelist", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        casinolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "5",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        sportlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

        instance.post("/api/v1/tabgamelist", {
            state: "8",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        fishlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "4",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        arcadelist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });



    }



    render() {
        const { loginstate } = this.state
        if (loginstate) {
            return (<><div
                id="main__content"
                data-bgset="/build/web/ez-bet/img/index-bg.jpg?v=9"
                className="x-bg-position-center x-bg-index lazyloaded "
                style={{
                  backgroundImage: 'url("/build/web/ez-bet/img/index-bg.jpg?v=9")'
                }}
              >
                <div className="js-replace-cover-seo-container"></div>
                <div className="x-invitation-index-container">
                  <div className="x-cover-invitation-container ">
                    <div
                      className="-cover-invitation-wrapper x-bg-position-center lazyloaded"
                      data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-cover-bg-light.jpg"
                      style={{
                        backgroundImage:
                          'url("https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-cover-bg-light.jpg")'
                      }}
                    >
                      <div className="-content-wrapper">
                        <h1 className="-title">ลิงก์รับทรัพย์</h1>
                        <p className="-sub-title">รับคอมมิชชั่นสูงถึง 3%</p>
                      </div>
                    </div>
                  </div>
                  <div className="-invitation-info-wrapper">
                    <div className="container">
                      <div className="x-invitation-info">
                        <h2 className="-title">แนะนำเพื่อน</h2>
                        <div className="-invitation-link-info-wrapper">
                          <div className="-deposit-required">
                            <span>
                              ** กรุณา <b className="-highlight">ฝากเงิน</b>{" "}
                              เพื่อเปิดระบบชวนเพื่อน และรับลิงก์ของท่าน **
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-info-panel-wrapper">
                    <div className="container">
                      <div className="x-invitation-base x-invitation-overview-panel">
                        <div className="x-invitation-menu-wrapper">
                          <a href="/invitation" className="btn -btn-menu -active">
                            ภาพรวม
                          </a>
                          <a href="/invitation/income" className="btn -btn-menu ">
                            สรุปรายได้
                          </a>
                          <a href="/invitation/member" className="btn -btn-menu ">
                            สมาชิกทั้งหมด
                          </a>
                          <a href="/invitation/history" className="btn -btn-menu ">
                            ประวัติการโอนรายได้
                          </a>
                        </div>
                        <div className="-content-outer-wrapper">
                          <div className="x-invitation-overview">
                            <div className="-menu-list">
                              <div className="-menu-list-item">
                                <span className="-label">คลิกลิงก์ทั้งหมด</span>
                                <span className="-value">0</span>
                              </div>
                              <div className="-menu-list-item">
                                <span className="-label">ผู้สมัครทั้งหมด</span>
                                <span className="-value">0</span>
                              </div>
                              <div className="-menu-list-item">
                                <span className="-label">รายได้ทั้งหมด</span>
                                <span className="-value">0.00</span>
                              </div>
                            </div>
                          </div>
                          <div className="-heading-wrapper">
                            <span className="-heading-title">ภาพรวม</span>
                            <div className="x-invitation-filter -in-heading -summary">
                              <div className="x-invitation-datepicker -range-mode">
                                <div className="-main-btn-wrapper">
                                  <i className="fas fa-calendar-alt" />
                                  <input
                                    type="text"
                                    id="invitation_summary_date_filter"
                                    name="invitation_summary_date_filter"
                                    className="js-datepicker js-date-range-input form-control -form-control flatpickr-input"
                                    data-mode="range"
                                    data-max-date="today"
                                    data-human-read="true"
                                    data-url="https://lotto123.fun/invitation"
                                    data-on-date-change="_onDateInvitationChange_"
                                    placeholder="กรุณาเลือกวันที่"
                                    defaultValue="2024-06-29"
                                    readOnly="readonly"
                                  />
                                </div>
                                <a
                                  href="https://lotto123.fun/invitation"
                                  className="btn -btn-clear d-none"
                                >
                                  เคลียร์
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="x-invitation-overview">
                            <div className="-menu-list">
                              <div className="-menu-list-item">
                                <span className="-label">จำนวนคลิกลิ้งค์</span>
                                <span className="-value">0</span>
                              </div>
                              <a
                                href="/invitation/member?filterType=registered"
                                className="-menu-list-item -has-link"
                              >
                                <i className="fas fa-external-link-alt -ic-link" />
                                <span className="-label">จำนวนผู้สมัคร</span>
                                <span className="-value">0</span>
                              </a>
                              <a
                                href="/invitation/member?filterType=active"
                                className="-menu-list-item -has-link"
                              >
                                <i className="fas fa-external-link-alt -ic-link" />
                                <span className="-label">ผู้เล่นเคลื่อนไหว</span>
                                <span className="-value">0</span>
                              </a>
                              <div className="-menu-list-item">
                                <span className="-label">รายได้</span>
                                <span className="-value">0.00</span>
                              </div>
                            </div>
                          </div>
                          <div className="-table-wrapper">
                            <div className="table-responsive">
                              <table className="table table-striped table-hover table-borderless x-invitation-table ">
                                <thead>
                                  <tr>
                                    <th className=" text-center" width="50%">
                                      ประเภทเกมส์
                                    </th>
                                    <th className=" text-center" width="50%">
                                      รายได้
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-content-section-wrapper">
                    <div className="container">
                      <div className="x-invitation-content-section-container">
                        <div className="-title-wrapper">
                          <span className="-title">รับเงินได้ทุกวัน</span>{" "}
                          <span className="-title -xl ml-2">Passive Income ที่แท้จริง</span>
                        </div>
                        <div className="-image-wrapper">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.webp?v=9"
                            />
                            <source
                              type="image/jpg"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.jpg"
                            />
                            <img
                              alt="เริ่มต้นง่ายๆ ได้ทุกการเล่น"
                              className="img-fluid -image"
                              width={1110}
                              height={329}
                              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.jpg"
                            />
                          </picture>
                        </div>
                        <div className="-title-wrapper">
                          <span className="-title">ทำมาแล้ว!</span>
                          <br />{" "}
                          <span className="-title -lg">แค่เพื่อนเล่น เราก็รับทรัพย์</span>
                          <br /> <span className="-title -lg">รายได้ 6 หลัก</span>{" "}
                          <span className="-title -lg ml-1 -underline">ง่ายๆ ทุกเดือน</span>
                        </div>
                        <div className="-image-wrapper">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.webp?v=9"
                            />
                            <source
                              type="image/jpg"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.jpg"
                            />
                            <img
                              alt="เริ่มต้นง่ายๆ ได้ทุกการเล่น"
                              className="img-fluid -image"
                              width={1110}
                              height={329}
                              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.jpg"
                            />
                          </picture>
                        </div>
                        <div className="-title-wrapper">
                          <span className="-title">แนะนำเพื่อนกับเราดียังไง</span>
                        </div>
                        <div className="-content-wrapper">
                          <h3 className="-title">
                            ง่ายกว่าที่คุณคิด เพียงคัดลองลิงก์ของคุณ ส่งให้เพื่อน ยิ่งชวน
                            ยิ่งได้ มีรายได้ทุกวัน
                          </h3>{" "}
                          <p className="-description">
                            ส่วนแบ่งจากการแนะนำ สามารถนำไปเดิมพันต่อ หรือ ถอนได้เลย
                            ไม่มีเทิร์นโอเวอร์ ไม่จำกัดยอด สร้างรายได้กับเราได้ทันที ชวนเลย
                            เพื่อนสายบาคาร่า เพื่อนสายสล็อต เพื่อนสายกีฬา เพื่อนสายยิงปลา
                            เพื่อนสายหวย ไม่ว่าสายไหนก็ชวนเพื่อนได้ไม่จำกัด
                            รับส่วนแบ่งค่าคอมมิชชั่นแบบเน้นๆ จ่ายกันแบบรายวัน
                            อัพเดทยอดของเพื่อนคุณแบบเรียลไทม์ แค่เพื่อนเล่น
                            คุณก็มีรายได้ไม่จำกัด
                          </p>
                        </div>
                        <div className="x-invitation-percent-and-condition-component">
                          <div className="-title-wrapper">
                            <strong className="-title">เงื่อนไขและเปอร์เซ็นต์</strong>
                          </div>
                          <ul className="navbar-nav -percent-list">
                            <li className="nav-item -percent-list-item">
                              <strong className="-topic">คาสิโน</strong>
                              <p className="-percent">0.3%</p>
                              <span className="-description">สูงสุด 5000 /วัน</span>
                            </li>
                            <li className="nav-item -percent-list-item">
                              <strong className="-topic">สล็อต</strong>
                              <p className="-percent">0.5%</p>
                              <span className="-description">สูงสุด 5000 /วัน</span>
                            </li>
                            <li className="nav-item -percent-list-item">
                              <strong className="-topic">หวย</strong>
                              <p className="-percent">3%</p>
                              <span className="-description">สูงสุด 5000 /วัน</span>
                            </li>
                            <li className="nav-item -percent-list-item">
                              <strong className="-topic">1234 คริปโต</strong>
                              <p className="-percent">0.3%</p>
                              <span className="-description">สูงสุด 5000 /วัน</span>
                            </li>
                            <li className="nav-item -percent-list-item">
                              <strong className="-topic">กีฬา</strong>
                              <p className="-percent">1%</p>
                              <span className="-description">สูงสุด 2000 /วัน</span>
                            </li>
                          </ul>
                          <div className="-user-description">
                            <div className="-content-wrapper">
                              <div className="text-muted mb-3">
                                *เปอร์เซนต์คิดจาก <b>"Turnover"</b> ของสมาชิกคุณ{" "}
                                <u className="text-danger">ยกเว้นประเภทกีฬา</u> จะคิดจาก{" "}
                                <b>"ยอดได้"</b>
                              </div>
                              <h3 className="-title">
                                ตัวอย่างการได้ค่าเเนะนำแนะนำเพื่อนเล่นหวย
                              </h3>
                              <p className="-description">
                                สมาชิกของคุณ 1 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้ 30
                                เครดิต <br />
                                สมาชิกของคุณ 10 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้
                                300 เครดิต <br />
                                สมาชิกของคุณ 100 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้
                                3,000 เครดิต <br />
                              </p>
                              <h3 className="-title">แนะนำเพื่อนเล่นคาสิโน</h3>
                              <p className="-description">
                                สมาชิกของคุณ 1 คน เดิมพันคาสิโน 1,000 เครดิต คุณจะได้รับรายได้
                                3 เครดิต <br />
                                สมาชิกของคุณ 10 คน เดิมพันคาสิโน 1,000 เครดิต
                                คุณจะได้รับรายได้ 30 เครดิต <br />
                                สมาชิกของคุณ 100 คน เดิมพันคาสิโน 1,000 เครดิต
                                คุณจะได้รับรายได้ 300 เครดิต <br />
                              </p>
                              <h3 className="-title">แนะนำเพื่อนเล่นกีฬา</h3>
                              <p className="-description">
                                สมาชิกของคุณ 1 คน มียอดได้จากกีฬา 1,000 เครดิต
                                คุณจะได้รับรายได้ 10 เครดิต <br />
                                สมาชิกของคุณ 10 คน มียอดได้จากกีฬา 1,000 เครดิต
                                คุณจะได้รับรายได้ 100 เครดิต <br />
                                สมาชิกของคุณ 100 คน มียอดได้จากกีฬา 1,000 เครดิต
                                คุณจะได้รับรายได้ 1,000 เครดิต <br />
                              </p>
                              <h3 className="-title">เงื่อนไขการถอนเครดิตเเนะนำเพื่อน</h3>
                              <p className="-description">
                                ต้องมีรายการฝากเงิน 1 ครั้งขึ้นไป
                                ภายในเดือนที่แจ้งถอนเครดิตแนะนำเพื่อน <br />
                              </p>
                              <em className="f-7">
                                ** หากพบว่ามีการทุจริตในการปั้มค่าคอมมิชชั่น เดิมพันผิดปกติ
                                และ ล่าโปรโมชั่น มีมากกว่า 1 ยูส หรืออื่นๆ
                                ที่เข้าข่ายการฉ้อโกงทางบริษัทจะทำการระงับการจ่ายค่าคอมโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                              </em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-auto-size"
                  id="invitationNoteModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-container="#invitationNoteModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span>หมายเหตุ</span>
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <p className="js-invitation-history-note text-center mt-3 mt-md-1 mb-md-4" />
                        <button className="btn btn-primary -submit" data-dismiss="modal">
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-half-size"
                  id="invitationTransferModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-container="#invitationTransferModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span>โอนเงิน</span>
                        </h3>
                      </div>
                      <div className="modal-body -modal-body"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -alert-modal animated fadeInRight"
                  id="alertModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-animatable="fadeInRight"
                  data-delay={700}
                  data-dismiss-alert="true"
                >
                  <div className="modal-dialog -modal-size -v2  " role="document">
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="d-flex -alert-body">
                          <div className="text-center mr-3 -alert-body-wrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.webp"
                              />
                              <source
                                type="image/png"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.png"
                              />
                              <img
                                className="-img-alert js-ic-success img-fluid"
                                alt="ทำรายการเว็บพนันออนไลน์สำเร็จ"
                                width={40}
                                height={40}
                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.png"
                              />
                            </picture>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.webp"
                              />
                              <source
                                type="image/png"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.png"
                              />
                              <img
                                className="-img-alert js-ic-fail img-fluid"
                                alt="ทำรายการเว็บพนันออนไลน์ไม่สำเร็จ"
                                width={40}
                                height={40}
                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.png"
                              />
                            </picture>
                          </div>
                          <div className="my-auto js-modal-content" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 x-theme-switcher-v2"
                  id="themeSwitcherModalxx"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="-theme-switcher-container">
                          <div className="-inner-header-section">
                            <a className="-link-wrapper" href="/">
                              <picture>
                                <source
                                  type="image/webp"
                                  data-srcset="/build/web/ez-bet/img/logo.webp?v=9"
                                />
                                <source
                                  type="image/png?v=9"
                                  data-srcset="/build/web/ez-bet/img/logo.png?v=9"
                                />
                                <img
                                  alt="logo image"
                                  className="img-fluid lazyload -logo lazyload"
                                  width={180}
                                  height={42}
                                  data-src="/build/web/ez-bet/img/logo.png?v=9"
                                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                />
                              </picture>
                            </a>
                          </div>
                          <div className="-inner-top-body-section">
                            <div className="col-6 -wrapper-box">
                              <button
                                type="button"
                                className="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal lazyload x-bg-position-center"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#depositModal"
                                data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/btn-deposit-bg.png"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-deposit.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-deposit.png"
                                  />
                                  <img
                                    alt="รูปไอคอนฝากเงิน"
                                    className="img-fluid -icon-image lazyload"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-deposit.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-title">ฝากเงิน</div>
                                  <div className="-sub-title">Deposit</div>
                                </div>
                              </button>
                            </div>
                            <div className="col-6 -wrapper-box">
                              <button
                                type="button"
                                className="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal lazyload x-bg-position-center"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#withdrawModal"
                                data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/btn-withdraw-bg.png"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-withdraw.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-withdraw.png"
                                  />
                                  <img
                                    alt="รูปไอคอนถอนเงิน"
                                    className="img-fluid -icon-image lazyload"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-account-withdraw.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-title">ถอนเงิน</div>
                                  <div className="-sub-title">Withdraw</div>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className="-inner-center-body-section">
                            <div className="col-6 -wrapper-box">
                              <a
                                href="/event"
                                className="btn -btn-item -event-button -menu-center -horizontal "
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.png"
                                  />
                                  <img
                                    alt="รูปไอคอนสิทธิพิเศษ"
                                    className="img-fluid -icon-image lazyload"
                                    width={65}
                                    height={53}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-typo -is-logged">สิทธิพิเศษ</div>
                                </div>
                              </a>
                            </div>
                            <div className="col-6 -wrapper-box">
                              <a
                                href="/promotions"
                                className="btn -btn-item -promotion-button -menu-center -horizontal lazyload x-bg-position-center"
                                data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.png"
                                  />
                                  <img
                                    alt="รูปไอคอนโปรโมชั่น"
                                    className="img-fluid -icon-image lazyload"
                                    width={65}
                                    height={53}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-typo -is-logged">โปรโมชั่น</div>
                                </div>
                              </a>
                            </div>
                            <div className="col-6 -wrapper-box">
                              <a
                                href="https://doball123.live/"
                                className="btn -btn-item -ball-live-button -menu-center -horizontal lazyload x-bg-position-center"
                                target="_blank"
                                rel="noopener nofollow"
                                data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.png"
                                  />
                                  <img
                                    alt="รูปไอคอนดูบอลสด"
                                    className="img-fluid -icon-image lazyload"
                                    width={65}
                                    height={53}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-typo -is-logged">ดูบอลสด</div>
                                </div>
                              </a>
                            </div>
                            <div className="col-6 -wrapper-box">
                              <a
                                href="https://lin.ee/IHJTrwu"
                                className="btn -btn-item -line-button -menu-center -horizontal lazyload x-bg-position-center"
                                target="_blank"
                                rel="noopener nofollow"
                                data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.png"
                                  />
                                  <img
                                    alt="รูปไอคอนดูหนัง"
                                    className="img-fluid -icon-image lazyload"
                                    width={65}
                                    height={53}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <div className="-typo-wrapper">
                                  <div className="-typo -is-logged">ไลน์</div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="-inner-bottom-body-section">
                            <div className="col-12 -wrapper-box">
                              <a
                                href="#share1theme-switcher"
                                className="btn -btn-item -social-share-button -menu-banner  "
                                data-ajax-modal-ondemand-user="social-share-modal-1"
                                data-force="true"
                                data-x-dismiss="true"
                                data-parent-class-selector="x-social-share-main-modal -v2"
                                data-url="/account/_ajax_/social-share-submit/1/create"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="/build/web/ez-bet/img/menu-banner-social-share.webp?v=9"
                                  />
                                  <source
                                    type="image/png?v=9"
                                    data-srcset="/build/web/ez-bet/img/menu-banner-social-share.png?v=9"
                                  />
                                  <img
                                    alt="รูปแบนเนอร์แชร์แลกทรัพย์"
                                    className="img-fluid -banner-image lazyload"
                                    width={342}
                                    height={98}
                                    data-src="/build/web/ez-bet/img/menu-banner-social-share.png?v=9"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                              </a>
                            </div>
                            <div className="col-12 -wrapper-box">
                              <a
                                href="#0"
                                className="btn -btn-item -bookmark-button -menu-banner  js-add-to-home-screen"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="/build/web/ez-bet/img/menu-banner-bookmark.webp?v=9"
                                  />
                                  <source
                                    type="image/png?v=9"
                                    data-srcset="/build/web/ez-bet/img/menu-banner-bookmark.png?v=9"
                                  />
                                  <img
                                    alt="รูปแบนเนอร์ bookmark"
                                    className="img-fluid -banner-image lazyload"
                                    width={342}
                                    height={98}
                                    data-src="/build/web/ez-bet/img/menu-banner-bookmark.png?v=9"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 "
                  id="bookmarkModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable         -no-fixed-button
                  "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span>Bookmark</span>{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="x-bookmark-modal-container">
                          <nav>
                            <div
                              className="nav nav-tabs x-bookmark-tabs-header-wrapper"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-link "
                                id="nav-android-tab"
                                data-toggle="tab"
                                href="#nav-android"
                                role="tab"
                                aria-controls="nav-android"
                                aria-selected="true"
                              >
                                Android
                              </a>
                              <a
                                className="nav-link active"
                                id="nav-ios-tab"
                                data-toggle="tab"
                                href="#nav-ios"
                                role="tab"
                                aria-controls="nav-ios"
                                aria-selected="true"
                              >
                                iOS
                              </a>
                            </div>
                          </nav>
                          <div
                            className="tab-content x-bookmark-tabs-content-wrapper"
                            id="nav-tabContent"
                          >
                            <div
                              className="tab-pane fade "
                              id="nav-android"
                              role="tabpanel"
                              aria-labelledby="nav-android-tab"
                            >
                              <div
                                className="-slide-wrapper -bookmark-slider-for-android slick-initialized slick-slider slick-dotted"
                                data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-android"}'
                              >
                                <div className="slick-list draggable">
                                  <div
                                    className="slick-track"
                                    style={{ opacity: 1, width: 0 }}
                                  >
                                    <div
                                      className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                      data-slick-index={0}
                                      aria-hidden="false"
                                      role="tabpanel"
                                      id="slick-slide00"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 999,
                                        opacity: 1
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-1.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-1.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-android-1.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={1}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      role="tabpanel"
                                      id="slick-slide01"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-2.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-2.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-android-2.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={2}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      role="tabpanel"
                                      id="slick-slide02"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-3.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-android-3.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-android-3.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                  </div>
                                </div>
                                <ul className="slick-dots" style={{}} role="tablist">
                                  <li className="slick-active" role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control00"
                                      aria-controls="slick-slide00"
                                      aria-label="1 of 3"
                                      tabIndex={0}
                                      aria-selected="true"
                                    >
                                      1
                                    </button>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control01"
                                      aria-controls="slick-slide01"
                                      aria-label="2 of 3"
                                      tabIndex={-1}
                                    >
                                      2
                                    </button>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control02"
                                      aria-controls="slick-slide02"
                                      aria-label="3 of 3"
                                      tabIndex={-1}
                                    >
                                      3
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="-slide-wrapper -bookmark-slider-nav-android slick-initialized slick-slider"
                                data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-android"}'
                              >
                                <div className="slick-list draggable">
                                  <div
                                    className="slick-track"
                                    style={{ opacity: 1, width: 0 }}
                                  >
                                    <div
                                      className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                      data-slick-index={0}
                                      aria-hidden="false"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 999,
                                        opacity: 1
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          เข้า Google Chrome แล้ว Search <br /> “Lotto123”
                                          เข้าสู่หน้าเว็บ
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={1}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">เลือก “ติดตั้ง”</div>
                                      </div>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={2}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          ตรวจสอบหน้า <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="nav-ios"
                              role="tabpanel"
                              aria-labelledby="nav-ios-tab"
                            >
                              <div
                                className="-slide-wrapper -bookmark-slider-for-ios slick-initialized slick-slider slick-dotted"
                                data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-ios"}'
                              >
                                <div className="slick-list draggable">
                                  <div
                                    className="slick-track"
                                    style={{ opacity: 1, width: 0 }}
                                  >
                                    <div
                                      className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                      data-slick-index={0}
                                      aria-hidden="false"
                                      role="tabpanel"
                                      id="slick-slide20"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 999,
                                        opacity: 1
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-1.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-1.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-ios-1.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={1}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      role="tabpanel"
                                      id="slick-slide21"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-2.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-2.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-ios-2.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={2}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      role="tabpanel"
                                      id="slick-slide22"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-3.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-3.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-ios-3.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={3}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      role="tabpanel"
                                      id="slick-slide23"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <picture>
                                        <source
                                          type="image/webp"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-4.webp"
                                        />
                                        <source
                                          type="image/png?v=9"
                                          data-srcset="/build/web/ez-bet/img/bookmark-ios-4.png?v=9"
                                        />
                                        <img
                                          className="-img lazyload"
                                          alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                          width={253}
                                          height={513}
                                          data-src="/build/web/ez-bet/img/bookmark-ios-4.png?v=9"
                                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        />
                                      </picture>
                                    </div>
                                  </div>
                                </div>
                                <ul className="slick-dots" style={{}} role="tablist">
                                  <li className="slick-active" role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control20"
                                      aria-controls="slick-slide20"
                                      aria-label="1 of 4"
                                      tabIndex={0}
                                      aria-selected="true"
                                    >
                                      1
                                    </button>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control21"
                                      aria-controls="slick-slide21"
                                      aria-label="2 of 4"
                                      tabIndex={-1}
                                    >
                                      2
                                    </button>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control22"
                                      aria-controls="slick-slide22"
                                      aria-label="3 of 4"
                                      tabIndex={-1}
                                    >
                                      3
                                    </button>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      type="button"
                                      role="tab"
                                      id="slick-slide-control23"
                                      aria-controls="slick-slide23"
                                      aria-label="4 of 4"
                                      tabIndex={-1}
                                    >
                                      4
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="-slide-wrapper -bookmark-slider-nav-ios slick-initialized slick-slider"
                                data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-ios"}'
                              >
                                <div className="slick-list draggable">
                                  <div
                                    className="slick-track"
                                    style={{ opacity: 1, width: 0 }}
                                  >
                                    <div
                                      className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                      data-slick-index={0}
                                      aria-hidden="false"
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 999,
                                        opacity: 1
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          เข้า Safari แล้ว Search “Lotto123” <br />{" "}
                                          เข้าสู่หน้าเว็บ กดที่
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={1}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          เลือก “เพิ่มลงใปยังหน้าจอโฮม”
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={2}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          กด “เพิ่ม”ทางลัดเข้าสู่เกมส์ <br /> ลงในหน้าจอโฮม
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="-slide-inner-wrapper slick-slide"
                                      data-slick-index={3}
                                      aria-hidden="true"
                                      tabIndex={-1}
                                      style={{
                                        width: 0,
                                        position: "relative",
                                        left: 0,
                                        top: 0,
                                        zIndex: 998,
                                        opacity: 0
                                      }}
                                    >
                                      <div className="-content-wrapper -center">
                                        <picture>
                                          <source
                                            type="image/webp"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.webp"
                                          />
                                          <source
                                            type="image/png"
                                            data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.png"
                                          />
                                          <img
                                            className="-icon lazyload"
                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                            width={60}
                                            height={60}
                                            data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                          />
                                        </picture>
                                        <div className="-description">
                                          ตรวจสอบหน้า <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -modal-full-page"
                  id="websiteMenuModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span />{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="x-website-menu-modal-body">
                          <a href="/" className="nav-link -btn-logo">
                            <picture>
                              <source
                                type="image/webp"
                                data-srcset="/build/web/ez-bet/img/logo.webp?v=9"
                              />
                              <source
                                type="image/png?v=9"
                                data-srcset="/build/web/ez-bet/img/logo.png?v=9"
                              />
                              <img
                                alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                className="img-fluid lazyload -img"
                                width={400}
                                height={150}
                                data-src="/build/web/ez-bet/img/logo.png?v=9"
                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                              />
                            </picture>
                          </a>
                          <ul className="nav x-website-menu-entry-list -as-vertical">
                            <li className="nav-item -nav-item">
                              <a
                                className="nav-link -nav-link"
                                href="https://ezmovie.co"
                                target="_blank"
                                rel="noopener nofollow"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.png"
                                  />
                                  <img
                                    alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                    className="img-fluid lazyload -img"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <span className="-text">ดูหนัง</span>
                              </a>
                            </li>
                            <li className="nav-item -nav-item">
                              <a className="nav-link -nav-link" href="/promotions">
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.png"
                                  />
                                  <img
                                    alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                    className="img-fluid lazyload -img"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <span className="-text">โปรโมชั่น</span>
                              </a>
                            </li>
                            <li className="nav-item -nav-item">
                              <a className="nav-link -nav-link" href="/event">
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.png"
                                  />
                                  <img
                                    alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                    className="img-fluid lazyload -img"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <span className="-text">สิทธิพิเศษ</span>
                              </a>
                            </li>
                            <li className="nav-item -nav-item">
                              <a className="nav-link -nav-link" href="/blogs">
                                <picture>
                                  <source
                                    type="image/webp"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.webp?v=9"
                                  />
                                  <source
                                    type="image/png"
                                    data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.png"
                                  />
                                  <img
                                    alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                    className="img-fluid lazyload -img"
                                    width={50}
                                    height={50}
                                    data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.png"
                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                  />
                                </picture>
                                <span className="-text">บทความ</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="x-wrapper-right-container ">
                  <div
                    className="x-notification-entrance-container js-notification-entry"
                    data-reload-badge-url="/_ajax_/inbox-user/badge-info"
                  >
                    <a
                      href="#notificationCenter"
                      className="-link-wrapper "
                      data-toggle="modal"
                      data-target="#notificationCenterModal"
                    >
                      <div className="-img-wrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.webp?v=9"
                          />
                          <source
                            type="image/png"
                            srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.png"
                          />
                          <img
                            alt="Notification bell image png"
                            className="-img"
                            width={40}
                            height={40}
                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.png"
                          />
                        </picture>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 "
                  id="accountModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/customer-info"
                  data-container="#accountModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-big"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="accountModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/customer-info?isMobileView=1"
                  data-container="#accountModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="providerUserModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/provider-user-info?isMobileView=1"
                  data-container="#providerUserModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="couponModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/coupon-apply?isMobileView=1"
                  data-container="#couponModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="joinPromotionModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/promotion?isMobileView=1"
                  data-container="#joinPromotionModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-backdrop -with-separator -with-more-than-half-size"
                  id="depositModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/deposit"
                  data-container="#depositModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span />{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="withdrawModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/withdraw"
                  data-container="#withdrawModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span />{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-half-size x-withdraw-select-option"
                  id="withdrawSelectOptionModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal m-auto">
                          <div className="-title">ถอนเงิน</div>
                          <div className="-sub-title">
                            ขอแนะนำ PocketFarm รับเพิ่มมากถึง{" "}
                            <span className="-highlight">10%</span>
                          </div>
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="x-withdraw-select-option-body">
                          <ul className="nav -menu-wrapper">
                            <li className="nav-item">
                              <button
                                data-target="#accountModal"
                                data-toggle="modal"
                                data-dismiss="modal"
                                className="-option-button"
                                data-to-ajax-account-modal="/account/_ajax_/pocket-farm"
                              >
                                <div className="-img-wrapper">
                                  <picture>
                                    <source
                                      type="image/webp"
                                      data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-pocket-farm.webp?v=9"
                                    />
                                    <source
                                      type="image/png"
                                      data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-pocket-farm.png"
                                    />
                                    <img
                                      alt="คาสิโนออนไลน์ สล็อตออนไลน์ บาคาร่าออนไลน์ อันดับ 1"
                                      className="img-fluid lazyload -img"
                                      width={40}
                                      height={40}
                                      data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-pocket-farm.png"
                                      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    />
                                  </picture>
                                </div>
                                <div className="-text">
                                  ฝากกับ PocketFarm
                                  <br />
                                  รับเพิ่มมากถึง 10%
                                </div>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                data-target="#withdrawModal"
                                data-toggle="modal"
                                data-dismiss="modal"
                                className="-option-button"
                              >
                                <div className="-img-wrapper">
                                  <picture>
                                    <source
                                      type="image/webp"
                                      data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-withdraw.webp?v=9"
                                    />
                                    <source
                                      type="image/png"
                                      data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-withdraw.png"
                                    />
                                    <img
                                      alt="คาสิโนออนไลน์ สล็อตออนไลน์ บาคาร่าออนไลน์ อันดับ 1"
                                      className="img-fluid lazyload -img"
                                      width={40}
                                      height={40}
                                      data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-withdraw-option-withdraw.png"
                                      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                    />
                                  </picture>
                                </div>
                                <div className="-text">ถอนเงินจากบัญชี</div>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 "
                  id="depositChoosePromotionModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/promotions/in-deposit"
                  data-container="#depositChoosePromotionModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span />{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="promotionReturnByUserModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/promotion-return?isMobileView=1"
                  data-container="#promotionReturnByUserModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-small"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">คืนยอดเสีย</h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -alert-reset-passcode-modal -with-half-size"
                  id="alertResetPasscodeModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-container="#alertResetPasscodeModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <div className="modal-header -modal-header"></div>
                      <div className="modal-body -modal-body">
                        <div className="x-register-tab-container js-tab-pane-checker-v3">
                          <div className="container">
                            <ul className="nav nav-tabs x-register-tab">
                              <li
                                className="nav-item   -confirmRequestTab"
                                id="tab-confirmRequestTab"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-logged-confirmRequestTab"
                                  className="nav-link"
                                >
                                  confirmRequestTab
                                </a>
                              </li>
                              <li
                                className="nav-item   -resetPasswordVerifyOtp"
                                id="tab-resetPasswordVerifyOtp"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-logged-resetPasswordVerifyOtp"
                                  className="nav-link"
                                >
                                  resetPasswordVerifyOtp
                                </a>
                              </li>
                              <li
                                className="nav-item   -resetPasswordSetPassword"
                                id="tab-resetPasswordSetPassword"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-logged-resetPasswordSetPassword"
                                  className="nav-link"
                                >
                                  resetPasswordSetPassword
                                </a>
                              </li>
                              <li
                                className="nav-item   -resetPasswordResult"
                                id="tab-resetPasswordResult"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-logged-resetPasswordResult"
                                  className="nav-link"
                                >
                                  resetPasswordResult
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="x-alert-reset-passcode-tabs x-modal-body-base -v3">
                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="tab-content-logged-confirmRequestTab"
                                data-completed-dismiss-modal=""
                              >
                                <div className="x-tab-confirm-request">
                                  <div className="x-title-register-modal-v3 ">
                                    <span className="-title">ประกาศ</span>
                                  </div>
                                  <p className="mt-3 -description">
                                    เนื่องจากมีการเปลี่ยนรูปแบบการใช้งาน <br />{" "}
                                    ลูกค้ากรุณาตั้งรหัสผ่านใหม่เป็นเลข 6 หลัก <br />{" "}
                                    เพื่อเข้าสู่ระบบ
                                  </p>
                                  <picture>
                                    <source
                                      type="image/webp"
                                      srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-reset-passcode.webp?v=9"
                                    />
                                    <source
                                      type="image/png"
                                      srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-reset-passcode.png"
                                    />
                                    <img
                                      alt="Reset passcode init"
                                      className="-ic img-fluid mb-3"
                                      width={130}
                                      height={136}
                                      src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-reset-passcode.png"
                                    />
                                  </picture>
                                  <div className="x-reset-pw-text-container ">
                                    <form
                                      method="post"
                                      data-register-v3-form="/_ajax_/v3/reset-password/request-otp"
                                      data-register-step="resetPasswordPhoneNumber"
                                      data-tab-next-step="#tab-content-logged-resetPasswordVerifyOtp"
                                    >
                                      <input
                                        type="hidden"
                                        required=""
                                        id="phone_number[phoneNumber]"
                                        name="phone_number[phoneNumber]"
                                        pattern=".{10,11}"
                                        defaultValue={'0616659000'}
                                        placeholder="095-123-4567"
                                      />
                                      <button
                                        type="submit"
                                        className="btn -submit -btn-confirm  my-lg-3 mt-0 js-btn-confirm"
                                      >
                                        <i className="-ic fa fa-spinner fa-spin" />
                                        <span className="-message">เปลี่ยนรหัสผ่าน</span>
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane "
                                id="tab-content-logged-resetPasswordVerifyOtp"
                                data-completed-dismiss-modal=""
                              >
                                <div className="x-modal-body-base -v3 x-form-register-v3">
                                  <div className="row -register-container-wrapper">
                                    <div className="col">
                                      <div className="x-title-register-modal-v3 ">
                                        <span className="-title">กรอกรหัส OTP</span>
                                        <span className="-sub-title">
                                          รหัส 4 หลัก ส่งไปยัง{" "}
                                          <span className="js-phone-number -highlight" />
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      data-animatable="fadeInRegister"
                                      data-offset={0}
                                      className="col animated fadeInRegister"
                                    >
                                      <div className="x-modal-separator-container x-form-register">
                                        <div className="-top ">
                                          <div
                                            data-animatable="fadeInRegister"
                                            data-offset={0}
                                            className="-animatable-container -otp-body animated fadeInRegister"
                                          >
                                            <form
                                              method="post"
                                              data-register-v3-form="/_ajax_/v3/reset-password/verify-otp/_resetPasswordToken"
                                              data-register-step="resetPasswordVerifyOtp"
                                              data-tab-next-step="#tab-content-logged-resetPasswordSetPassword"
                                              name="js-reset-password-v3-otp-form"
                                            >
                                              <div className="d-flex -otp-input-container js-register-v3-input-group">
                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                  <input
                                                    type="text"
                                                    id="resetPasswordOtp0"
                                                    name="otp0"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-otp js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="otp"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                  <input
                                                    type="text"
                                                    id="resetPasswordOtp1"
                                                    name="otp1"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-otp js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="otp"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                  <input
                                                    type="text"
                                                    id="resetPasswordOtp2"
                                                    name="otp2"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-otp js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="otp"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                  <input
                                                    type="text"
                                                    id="resetPasswordOtp3"
                                                    name="otp3"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-otp js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="otp"
                                                  />
                                                </div>
                                              </div>
                                              <input
                                                type="hidden"
                                                id="resetPasswordOtp"
                                                name="otp"
                                                pattern="[0-9]*"
                                                className="form-control mb-3"
                                              />
                                              <input
                                                type="hidden"
                                                id="resetPasswordToken"
                                                name="resetPasswordToken"
                                                className="form-control mb-3"
                                              />
                                              <div className="d-none js-keypad-number-wrapper">
                                                <div className="x-keypad-number-container">
                                                  <div className="-btn-group-wrapper">
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={1}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      1
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={2}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      2
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={3}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      3
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={4}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      4
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={5}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      5
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={6}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      6
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={7}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      7
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={8}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      8
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={9}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      9
                                                    </button>
                                                    <div
                                                      className="btn -btn js-keypad-btn -btn-none"
                                                      type="button"
                                                      data-key="none"
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    />
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={0}
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      0
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-backspace"
                                                      type="button"
                                                      data-key="backspace"
                                                      data-target="#resetPasswordOtp0"
                                                      data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                    >
                                                      <i className="fas fa-backspace" />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="text-center">
                                                <button
                                                  type="submit"
                                                  className="btn -submit my-lg-3 mt-0"
                                                >
                                                  ยืนยัน
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                        <div className="-bottom "></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane "
                                id="tab-content-logged-resetPasswordSetPassword"
                                data-completed-dismiss-modal=""
                              >
                                <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                  <div className="row -register-container-wrapper">
                                    <div className="col">
                                      <div className="x-title-register-modal-v3 ">
                                        <span className="-title">ตั้งรหัสผ่านใหม่</span>
                                        <span className="-sub-title">
                                          กรอกตัวเลขรหัส 6 หลัก เพื่อใช้เข้าสู่ระบบ
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      data-animatable="fadeInRegister"
                                      data-offset={0}
                                      className="col animated fadeInRegister"
                                    >
                                      <div className="x-modal-separator-container x-form-register">
                                        <div className="-top ">
                                          <div
                                            data-animatable="fadeInRegister"
                                            data-offset={0}
                                            className="-animatable-container -password-body animated fadeInRegister"
                                          >
                                            <form
                                              method="post"
                                              data-register-v3-form="/_ajax_/v3/reset-password/set-password/_resetPasswordSetPassword"
                                              data-register-step="resetPasswordSetPassword"
                                              data-tab-next-step="#tab-content-logged-resetPasswordResult"
                                            >
                                              <div className="d-flex -password-input-container js-register-v3-input-group">
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_1"
                                                    name="resetPasswordSetPassword_1"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_2"
                                                    name="resetPasswordSetPassword_2"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_3"
                                                    name="resetPasswordSetPassword_3"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_4"
                                                    name="resetPasswordSetPassword_4"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_5"
                                                    name="resetPasswordSetPassword_5"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                                <div className="js-separator-container js-reset-password-container">
                                                  <input
                                                    type="password"
                                                    id="resetPasswordSetPassword_6"
                                                    name="resetPasswordSetPassword_6"
                                                    inputMode="text"
                                                    readOnly=""
                                                    pattern="[0-9]*"
                                                    autofocus={1}
                                                    className="-digit-password js-otp-input"
                                                    data-separator-input="true"
                                                    data-type="set_password"
                                                  />
                                                </div>
                                              </div>
                                              <input
                                                type="hidden"
                                                id="resetPasswordSetPasswordToken"
                                                name="resetPasswordSetPasswordToken"
                                              />
                                              <div className="d-none js-keypad-number-wrapper">
                                                <div className="x-keypad-number-container">
                                                  <div className="-btn-group-wrapper">
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={1}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      1
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={2}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      2
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={3}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      3
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={4}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      4
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={5}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      5
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={6}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      6
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={7}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      7
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={8}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      8
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={9}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      9
                                                    </button>
                                                    <div
                                                      className="btn -btn js-keypad-btn -btn-none"
                                                      type="button"
                                                      data-key="none"
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    />
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-keypad"
                                                      type="button"
                                                      data-key={0}
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      0
                                                    </button>
                                                    <button
                                                      className="btn -btn js-keypad-btn -btn-backspace"
                                                      type="button"
                                                      data-key="backspace"
                                                      data-target="#resetPasswordSetPassword_1"
                                                      data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                    >
                                                      <i className="fas fa-backspace" />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="text-center">
                                                <button
                                                  type="submit"
                                                  className="btn -submit  my-lg-3 mt-0"
                                                >
                                                  ยืนยัน
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                        <div className="-bottom "></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane "
                                id="tab-content-logged-resetPasswordResult"
                                data-completed-dismiss-modal="#alertResetPasscodeModal"
                              >
                                <div className="x-modal-body-base -v3 x-form-register-v3">
                                  <div className="row -register-container-wrapper">
                                    <div
                                      data-animatable="fadeInRegister"
                                      data-offset={0}
                                      className="col animated fadeInRegister"
                                    >
                                      <div className="text-center d-flex flex-column justify-content-center h-100">
                                        <div className="text-center">
                                          <picture>
                                            <source
                                              type="image/webp"
                                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.webp?v=9"
                                            />
                                            <source
                                              type="image/png"
                                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                            />
                                            <img
                                              alt="สมัครสมาชิก SUCCESS"
                                              className="js-ic-success -success-ic img-fluid"
                                              width={150}
                                              height={150}
                                              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                            />
                                          </picture>
                                        </div>
                                        <div className="-title">
                                          อัปเดตรหัสผ่านของคุณเรียบร้อย!
                                        </div>
                                        <div className="-sub-title">
                                          ระบบกำลังพาคุณไปหน้าหลัก
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 x-modal-promotion-alert -with-half-size"
                  id="promotionAlertModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header"></div>
                      <div className="modal-body -modal-body"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-half-size"
                  id="changePasswordModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-container="#changePassordModal"
                  data-dismiss-modal-target="#accountModalMobile, #accountModal"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -dialog-in-tab -change-password-index-dialog"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span />{" "}
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="x-register-tab-container -register js-tab-pane-checker-v3">
                          <div className="container">
                            <ul className="nav nav-tabs x-register-tab js-change-password-tab">
                              <li
                                className="nav-item  active -currentPassword"
                                id="tab-currentPassword"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-currentPassword"
                                  className="nav-link"
                                >
                                  currentPassword
                                </a>
                              </li>
                              <li className="nav-item   -newPassword" id="tab-newPassword">
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-newPassword"
                                  className="nav-link"
                                >
                                  newPassword
                                </a>
                              </li>
                              <li
                                className="nav-item   -resultChangePasswordSuccess"
                                id="tab-resultChangePasswordSuccess"
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-content-resultChangePasswordSuccess"
                                  className="nav-link"
                                >
                                  resultChangePasswordSuccess
                                </a>
                              </li>
                            </ul>
                          </div>
                          <form
                            method="post"
                            name="js-change-password-current"
                            data-register-v3-form="/_ajax_/v3/change-password"
                            data-register-step="changePassword"
                            data-is-passcode={1}
                          >
                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="tab-content-currentPassword"
                                data-completed-dismiss-modal=""
                              >
                                <div className="x-modal-body-base -v3 x-form-register-v3">
                                  <div className="row -register-container-wrapper">
                                    <div className="col">
                                      <div className="x-title-register-modal-v3 ">
                                        <span className="-title">รหัสผ่านเดิม 6 หลัก</span>
                                        <span className="-sub-title">
                                          กรุณากรอกเลขรหัสผ่านเดิม 6 หลัก
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      data-animatable="fadeInRegister"
                                      data-offset={0}
                                      className="col animated fadeInRegister"
                                    >
                                      <div className="x-modal-separator-container x-form-change-password">
                                        <div className="-top ">
                                          <div
                                            data-animatable="fadeInModal"
                                            data-offset={0}
                                            className="-animatable-container -password-body animated fadeInModal"
                                          >
                                            <div className="d-flex -password-input-container js-register-v3-input-group">
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_1"
                                                  name="currentPassword_1"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_2"
                                                  name="currentPassword_2"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_3"
                                                  name="currentPassword_3"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_4"
                                                  name="currentPassword_4"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_5"
                                                  name="currentPassword_5"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                              <div className="js-separator-container js-change-password-container">
                                                <input
                                                  type="text"
                                                  id="currentPassword_6"
                                                  name="currentPassword_6"
                                                  readOnly=""
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="current_set_password"
                                                />
                                              </div>
                                            </div>
                                            <input
                                              type="hidden"
                                              id="currentPassword"
                                              name="currentPassword"
                                              pattern="[0-9]*"
                                              className="form-control mb-3"
                                            />
                                            <div className="d-none js-keypad-number-wrapper">
                                              <div className="x-keypad-number-container">
                                                <div className="-btn-group-wrapper">
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={1}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    1
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={2}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    2
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={3}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    3
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={4}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    4
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={5}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    5
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={6}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    6
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={7}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    7
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={8}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    8
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={9}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    9
                                                  </button>
                                                  <div
                                                    className="btn -btn js-keypad-btn -btn-none"
                                                    type="button"
                                                    data-key="none"
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  />
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={0}
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    0
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-backspace"
                                                    type="button"
                                                    data-key="backspace"
                                                    data-target="#currentPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-change-password-container","enabledButtonTarget":".js-current-password-button","targetSubmitForm":"js-change-password-current"}'
                                                  >
                                                    <i className="fas fa-backspace" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                className="btn -submit my-lg-3 mt-0 js-current-password-button"
                                                onclick="$('a[href=\'#tab-content-newPassword\']').click();"
                                              >
                                                ยืนยัน
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="-bottom "></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane "
                                id="tab-content-newPassword"
                                data-completed-dismiss-modal=""
                              >
                                <div className="x-modal-body-base -v3 x-form-register-v3">
                                  <div className="row -register-container-wrapper">
                                    <div className="col">
                                      <div className="x-title-register-modal-v3 ">
                                        <span className="-title">ตั้งรหัส 6 หลักใหม่</span>
                                        <span className="-sub-title">
                                          กรอกเลขรหัส 6 หลัก เพื่อใช้เข้าสู่ระบบ
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      data-animatable="fadeInRegister"
                                      data-offset={0}
                                      className="col animated fadeInRegister"
                                    >
                                      <div className="x-modal-separator-container x-form-change-password">
                                        <div className="-top ">
                                          <div
                                            data-animatable="fadeInModal"
                                            data-offset={0}
                                            className="-animatable-container -password-body animated fadeInModal"
                                          >
                                            <div className="d-flex -password-input-container js-register-v3-input-group">
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_1"
                                                  name="newPassword_1"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_2"
                                                  name="newPassword_2"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_3"
                                                  name="newPassword_3"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_4"
                                                  name="newPassword_4"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_5"
                                                  name="newPassword_5"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                              <div className="js-separator-container js-new-password-container">
                                                <input
                                                  type="text"
                                                  id="newPassword_6"
                                                  name="newPassword_6"
                                                  inputMode="text"
                                                  pattern="[0-9]*"
                                                  className="-digit-password js-otp-input"
                                                  data-separator-input="true"
                                                  data-type="set_new_password"
                                                  required=""
                                                />
                                              </div>
                                            </div>
                                            <input
                                              type="hidden"
                                              id="newPassword[first]"
                                              name="newPassword[first]"
                                              pattern="[0-9]*"
                                              className="form-control mb-3"
                                            />
                                            <div className="d-none js-keypad-number-wrapper">
                                              <div className="x-keypad-number-container">
                                                <div className="-btn-group-wrapper">
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={1}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    1
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={2}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    2
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={3}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    3
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={4}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    4
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={5}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    5
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={6}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    6
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={7}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    7
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={8}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    8
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={9}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    9
                                                  </button>
                                                  <div
                                                    className="btn -btn js-keypad-btn -btn-none"
                                                    type="button"
                                                    data-key="none"
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  />
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-keypad"
                                                    type="button"
                                                    data-key={0}
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    0
                                                  </button>
                                                  <button
                                                    className="btn -btn js-keypad-btn -btn-backspace"
                                                    type="button"
                                                    data-key="backspace"
                                                    data-target="#newPassword_1"
                                                    data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-new-password-container","enabledButtonTarget":".js-new-password-button","targetSubmitForm":"js-change-password-new"}'
                                                  >
                                                    <i className="fas fa-backspace" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <button
                                                type="submit"
                                                className="btn -submit btn-primary my-lg-3 mt-0 js-new-password-button"
                                              >
                                                ยืนยัน
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="-bottom "></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane "
                                id="tab-content-resultChangePasswordSuccess"
                                data-completed-dismiss-modal="#changePasswordModal"
                              >
                                <div className="js-change-password-success-container">
                                  <div className="x-modal-body-base -v3 x-form-register-v3">
                                    <div className="row -register-container-wrapper">
                                      <div
                                        data-animatable="fadeInRegister"
                                        data-offset={0}
                                        className="col animated fadeInRegister"
                                      >
                                        <div className="text-center d-flex flex-column justify-content-center h-100">
                                          <div className="text-center">
                                            <picture>
                                              <source
                                                type="image/webp"
                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.webp?v=9"
                                              />
                                              <source
                                                type="image/png"
                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                              />
                                              <img
                                                alt="สมัครสมาชิก SUCCESS"
                                                className="js-ic-success -success-ic img-fluid"
                                                width={150}
                                                height={150}
                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                              />
                                            </picture>
                                          </div>
                                          <div className="-title">เปลี่ยนรหัสผ่านสำเร็จ!</div>
                                          <div className="-sub-title">
                                            ระบบกำลังพาคุณไปหน้าหลัก
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -with-more-than-half-size"
                  id="ads"
                  role="dialog"
                  data-loading-container=".js-modal-content"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-modal-one-time="37110f6e3737c3f9e97a2579083dd574"
                  data-check-target="#ads-no-show-again"
                  data-modal-auto-open="data-modal-auto-open"
                  data-modal-order-to-open={99}
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 -in-tab"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <h3 className="x-title-modal d-inline-block m-auto">
                          <span>ประกาศสำคัญ</span>
                        </h3>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="text-center">
                          <img
                            src="https://lotto123.fun/media/cache/strip/202406/block/628b3e95cd0a168daf86830fa93830df.jpg"
                            alt="ร่วมสนุกทายผลบอล"
                            className="img-fluid"
                            width="300px"
                          />
                        </div>
                        <div className="x-checkbox-primary">
                          <div className="form-check text-primary text-center mt-4">
                            <input
                              type="checkbox"
                              id="ads-no-show-again"
                              name="ads-no-show-again"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label -checkbox-label"
                              htmlFor="ads-no-show-again"
                            >
                              ไม่ต้องแสดงข้อความนี้อีก
                            </label>
                          </div>
                        </div>
                        <div className="my-3">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn-block text-black btn -submit btn-primary text-center m-auto"
                          >
                            <span>ปิดหน้าต่าง</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 -scrollable -no-button js-modal-scrollable"
                  id="notificationCenterModal"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/_ajax_/notifitaion-info"
                  data-container="#notificationCenterModal .modal-body"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-header -modal-header">
                        <div className="-outer-wrapper">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-title-bg.webp?v=9"
                            />
                            <source
                              type="image/png"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-title-bg.png"
                            />
                            <img
                              alt="Notification bg image png"
                              className="-title-bg"
                              width={320}
                              height={64}
                              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-title-bg.png"
                            />
                          </picture>
                          <h3 className="x-title-modal ">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.webp?v=9"
                              />
                              <source
                                type="image/png"
                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.png"
                              />
                              <img
                                alt="Notification icon bell image png"
                                className="-ic-title"
                                width={40}
                                height={40}
                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/notification-ic-bell.png"
                              />
                            </picture>
                            <span className="-text">กระดานแจ้งเตือน</span>
                          </h3>
                        </div>
                      </div>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 "
                  id="pocketFarmModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/pocket-farm?isMobileView=1"
                  data-container="#pocketFarmModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-big"
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="x-modal modal -v2 "
                  id="linkSocialAccountModalMobile"
                  role="dialog"
                  aria-hidden="true"
                  data-loading-container=".modal-body"
                  data-ajax-modal-always-reload="true"
                  tabIndex={-1}
                  data-ajax-modal="/account/_ajax_/promotion-bind-social-account?isMobileView=1"
                  data-container="#linkSocialAccountModalMobile"
                >
                  <div
                    className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                    role="document"
                  >
                    <div className="modal-content -modal-content">
                      <button
                        type="button"
                        className="close f-1 "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fas fa-times" />
                      </button>
                      <div className="modal-body -modal-body">
                        <div className="js-modal-content" />
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="x-footer -ezl -logged">
                  <div
                    className="-inner-wrapper lazyload x-bg-position-center"
                    data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/footer-inner-bg.png"
                  >
                    <div className="container -inner-title-wrapper">
                      <h3 className="-text-title">Lotto123 ระบบใหม่ เจ้าแรกในไทย</h3>
                      <p className="-text-sub-title">
                        หวยออนไลน์ Lotto123 สร้างประสบการณ์ใหม่ให้กับคุณได้ดีที่สุด
                        โดยเฉพาะการเล่นกับเว็บไซต์ Lotto123
                        ที่จะพาคุณไปพบหวยหลากหลายประเภทรวมไว้ในเว็บเดียว
                        สมัครสมาชิกแล้วพร้อมเข้าเลือกเลขได้อย่างเป็นอิสระ
                        ไม่ว่าคุณจะแทงหวยในรูปแบบใด ทำเงินได้ตลอด 24 ชั่วโมงอย่างมั่นใจ
                        เพราะที่นี่มีความมั่นคงสูง และเตรียมพร้อมในทุก ๆ
                        ด้านเพื่อให้คอหวยทุกคนได้รับความคุ้มค่ามากที่สุด
                        ทั้งยังกล้าการันตีการเป็นเว็บหวยที่ดีที่สุดแห่งยุคอีกด้วย
                      </p>
                    </div>
                    <div className="container">
                      <div className="-footer-menu">
                        <div className="-block-provider">
                          <h3 className="-text-title -provider">PAYMENTS ACCEPTED</h3>
                          <picture>
                            <source
                              type="image/webp"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.webp?v=9"
                            />
                            <source
                              type="image/png"
                              srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.png"
                            />
                            <img
                              alt="หวยออนไลน์ แทงหวยออนไลน์ คาสิโนออนไลน์ เว็บพนันออนไลน์"
                              className="img-fluid -image"
                              width={580}
                              height={40}
                              src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.png"
                            />
                          </picture>
                        </div>
                        <div className="-block-tag">
                          <h3 className="-text-title -tag">TAG</h3>
                          <div className="row x-footer-seo -ezl">
                            <div className="col-12 mb-3 -footer-seo-title"></div>
                            <div className="col-12 -tags">
                              <a
                                href="/%E0%B8%A1%E0%B8%B2%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%AB%E0%B8%99%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%AA%E0%B8%B8%E0%B8%9E%E0%B8%A3%E0%B8%A3%E0%B8%93-%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81-%E0%B8%84-67"
                                className="btn btn-sm"
                              >
                                มาแล้ว!! เลขเด็ด หนุ่มสุพรรณ งวด 1 ก.ค.67
                              </a>
                              <a
                                href="/4-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A8%E0%B8%A3%E0%B8%A9%E0%B8%90%E0%B8%B5%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B8%A2%E0%B9%81%E0%B8%94%E0%B8%87-%E0%B8%8A%E0%B8%B0%E0%B8%95%E0%B8%B2%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B8%81%E0%B8%9F%E0%B8%B7%E0%B9%89%E0%B8%99%E0%B9%83%E0%B8%99%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%87%E0%B8%84%E0%B8%A3%E0%B8%B6%E0%B9%88%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87"
                                className="btn btn-sm"
                              >
                                4 ราศี ว่าที่เศรษฐีป้ายแดง ชะตาพลิกฟื้นในช่วงครึ่งปีหลัง
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B9%80%E0%B8%88%E0%B9%87%E0%B8%9A%E0%B8%9B%E0%B9%88%E0%B8%A7%E0%B8%A2-%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2"
                                className="btn btn-sm"
                              >
                                ฝันว่าเจ็บป่วย
                              </a>
                              <a
                                href="/%E0%B9%80%E0%B8%9C%E0%B8%A2%E0%B8%A2%E0%B8%AD%E0%B8%94%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99%E0%B8%97%E0%B8%AD%E0%B8%94%E0%B8%9C%E0%B9%89%E0%B8%B2%E0%B8%9B%E0%B9%88%E0%B8%B2-%E0%B8%99%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%AD%E0%B8%81%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%94%E0%B8%B1%E0%B8%87-%E0%B9%80%E0%B8%9A%E0%B8%A5%E0%B8%A5%E0%B9%88%E0%B8%B2-%E0%B8%A3%E0%B8%B2%E0%B8%93%E0%B8%B5"
                                className="btn btn-sm"
                              >
                                เผยยอดเงินทอดผ้าป่า นางเอกชื่อดัง "เบลล่า ราณี"
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%88%E0%B8%B1%E0%B8%99%E0%B8%97%E0%B8%A3%E0%B9%8C%E0%B8%97%E0%B8%A3%E0%B8%87%E0%B8%81%E0%B8%A5%E0%B8%94%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นจันทร์ทรงกลด
                              </a>
                              <a
                                href="/%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5%E0%B8%A1%E0%B8%B5%E0%B9%80%E0%B8%81%E0%B8%93%E0%B8%91%E0%B9%8C-%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B8%84%E0%B8%B4%E0%B8%A7%E0%B8%AA%E0%B8%A1%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%84%E0%B8%99%E0%B8%A3%E0%B8%B1%E0%B8%81%E0%B8%95%E0%B8%B2%E0%B8%A1-checklist"
                                className="btn btn-sm"
                              >
                                ราศีมีเกณฑ์ ถึงคิวสมหวัง ได้คนรักตาม Checklist
                              </a>
                              <a
                                href="/Introducing-the-secret-to-winning-the"
                                className="btn btn-sm"
                              >
                                หวยยี่กี
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%88%E0%B8%B8%E0%B8%94%E0%B9%84%E0%B8%9F%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันว่าจุดไฟ
                              </a>
                              <a
                                href="/%E0%B8%A1%E0%B8%94%E0%B8%94%E0%B8%B3-%E0%B8%A2%E0%B8%81%E0%B8%97%E0%B8%B1%E0%B8%9E-%E0%B8%A1%E0%B8%B9%E0%B9%84%E0%B8%81%E0%B8%A5%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B9%80%E0%B8%A1%E0%B8%B5%E0%B8%A2%E0%B8%A3%E0%B9%8C%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B9%8C-%E0%B8%9E%E0%B8%B2%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94"
                                className="btn btn-sm"
                              >
                                "มดดำ" ยกทัพ มูไกลถึงเมียร์มาร์ พาส่องเลขเด็ด
                              </a>
                              <a
                                href="/2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%88%E0%B8%B0%E0%B8%A1%E0%B8%B5%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B9%83%E0%B8%AB%E0%B8%8D%E0%B9%88-%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%9B%E0%B8%B5%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%A2-%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%A3%E0%B8%A7%E0%B8%A2"
                                className="btn btn-sm"
                              >
                                2 ราศี จะมีโชคใหญ่ ต้นปีร้าย กลางปีรวย
                              </a>
                              <a
                                href="/%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94%E0%B8%AB%E0%B8%B2%E0%B8%87%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%97%E0%B8%B1%E0%B8%94-%E0%B8%AB%E0%B8%A5%E0%B8%A7%E0%B8%87%E0%B8%9E%E0%B9%88%E0%B8%AD%E0%B8%AA%E0%B8%A1%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%AA%E0%B8%A1%E0%B8%9B%E0%B8%A3%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%99%E0%B8%B2%E0%B8%9E%E0%B8%B2%E0%B8%A5%E0%B8%B9%E0%B8%81-%E0%B9%80"
                                className="btn btn-sm"
                              >
                                ส่องเลขเด็ดหางประทัด "หลวงพ่อสมหวัง"
                                หลังหนุ่มสมปรารถนาพาลูก-เมียแก้บน
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%80%E0%B8%88%E0%B8%94%E0%B8%B5%E0%B8%A2%E0%B9%8C%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นเจดีย์
                              </a>
                              <a
                                href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%A3%E0%B8%B1%E0%B8%81-2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%88%E0%B8%B0%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9E%E0%B8%9A%E0%B8%9B%E0%B9%8B%E0%B8%B2%E0%B8%AA%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%9B%E0%B8%A2%E0%B9%8C"
                                className="btn btn-sm"
                              >
                                ดวงความรัก 2 ราศีที่จะได้พบป๋าสายเปย์
                              </a>
                              <a
                                href="/%E0%B8%84%E0%B8%99%E0%B9%81%E0%B8%AB%E0%B9%88%E0%B8%82%E0%B8%AD%E0%B9%82%E0%B8%8A%E0%B8%84-%E0%B9%84%E0%B8%AD%E0%B9%88%E0%B9%84%E0%B8%82%E0%B9%88-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%8430%E0%B8%A5%E0%B9%89%E0%B8%B2%E0%B8%99-%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%88%E0%B8%B2%E0%B8%84%E0%B8%A7%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B21%E0%B8%A5%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%9A%E0%B8%B2%E0%B8%97"
                                className="btn btn-sm"
                              >
                                คนแห่ขอโชค "ไอ่ไข่" หลังให้โชค30ล้าน บริจาควัดกว่า1ล้านบาท
                              </a>
                              <a
                                href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99-%E0%B8%9E%E0%B8%9A-3-%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A9%E0%B8%B1%E0%B8%95%E0%B8%A3-%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%A5%E0%B8%B7%E0%B8%A1%E0%B8%95%E0%B8%B2%E0%B8%AD%E0%B9%89%E0%B8%B2%E0%B8%9B%E0%B8%B2%E0%B8%81-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%88%E0%B8%B2%E0%B8%81%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A5%E0%B8%87%E0%B8%97%E0%B8%B8%E0%B8%99"
                                className="btn btn-sm"
                              >
                                ดวงการเงิน พบ 3 นักษัตร ดวงลืมตาอ้าปาก ได้โชคจากการลงทุน
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%88%E0%B8%B4%E0%B9%89%E0%B8%87%E0%B8%88%E0%B8%AD%E0%B8%81%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นจิ้งจอก
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%80%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%8A%E0%B8%B2%E0%B8%A2-%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2"
                                className="btn btn-sm"
                              >
                                ฝันเห็นเจ้าชาย
                              </a>
                              <a
                                href="/%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%AB%E0%B8%A7%E0%B8%A2%E0%B9%81%E0%B8%A1%E0%B9%88%E0%B8%97%E0%B8%B3%E0%B9%80%E0%B8%99%E0%B8%B5%E0%B8%A2%E0%B8%99-%E0%B8%87%E0%B8%A7%E0%B8%941-%E0%B8%81-%E0%B8%84-67-%E0%B8%99%E0%B8%B5%E0%B9%89-%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B9%82%E0%B8%94%E0%B8%99"
                                className="btn btn-sm"
                              >
                                รวมเลขเด็ด "หวยแม่ทำเนียน" งวด1 ก.ค. 67 นี้ ต้องโดน!!
                              </a>
                              <a
                                href="/%E0%B8%97%E0%B8%B3%E0%B8%9A%E0%B8%B8%E0%B8%8D%E0%B9%80%E0%B8%AA%E0%B8%A3%E0%B8%B4%E0%B8%A1%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99%2012%20%E0%B8%A5%E0%B8%B1%E0%B8%84%E0%B8%99%E0%B8%B2%20%E0%B8%84%E0%B8%A3%E0%B8%B6%E0%B9%88%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%202567"
                                className="btn btn-sm"
                              >
                                ทำบุญเสริมดวงการเงิน 12 ลัคนา ครึ่งปีหลัง 2567
                              </a>
                              <a
                                href="/%E0%B8%9E%E0%B8%B2%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87-%E0%B8%9A%E0%B8%AD%E0%B8%A5-%E0%B9%80%E0%B8%8A%E0%B8%B4%E0%B8%8D%E0%B8%A2%E0%B8%B4%E0%B9%89%E0%B8%A1-%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B8%87%E0%B9%84%E0%B8%AB-%E0%B9%81%E0%B8%A1%E0%B9%88%E0%B8%9C%E0%B8%B6%E0%B9%89%E0%B8%87-%E0%B8%9E%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%9E%E0%B8%A7%E0%B8%87"
                                className="btn btn-sm"
                              >
                                พาส่อง บอล เชิญยิ้ม ล้วงไห "แม่ผึ้ง พุ่มพวง"
                              </a>
                              <a
                                href="/3-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A2%E0%B8%B8%E0%B9%88%E0%B8%87%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B9%86-%E0%B9%81%E0%B8%95%E0%B9%88%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B8%A3%E0%B8%A7%E0%B8%A2"
                                className="btn btn-sm"
                              >
                                3 ราศี งานยุ่งสุดๆ แต่แล้วรวย
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%82%E0%B8%88%E0%B8%A3%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นโจร
                              </a>
                              <a
                                href="/%E0%B8%A1%E0%B8%B2%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%97%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B8%B3%E0%B8%8A%E0%B8%B0%E0%B9%82%E0%B8%99%E0%B8%94-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%88%E0%B8%B3%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81-%E0%B8%84-67"
                                className="btn btn-sm"
                              >
                                มาแล้ว!! เลขเด็ด ปฏิทินคำชะโนด ประจำงวด 1 ก.ค.67
                              </a>
                              <a
                                href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-7-%E0%B8%A7%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%81%E0%B8%B4%E0%B8%94-%E0%B8%8A%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%AB%E0%B8%A5%E0%B9%88%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-%E0%B8%A5%E0%B8%B8%E0%B9%89%E0%B8%99%E0%B8%A3%E0%B8%A7%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C"
                                className="btn btn-sm"
                              >
                                ดวงโชคลาภ 7 วันเกิด ชี้เป้าแหล่งโชคลาภ ลุ้นรวยรับทรัพย์
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%87%E0%B8%B9%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นงู
                              </a>
                              <a
                                href="/%E0%B8%84%E0%B8%B7%E0%B8%99%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B8%97%E0%B9%89%E0%B8%B2%E0%B8%A2-%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A3%E0%B8%B3%E0%B8%A5%E0%B8%B6%E0%B8%81%E0%B8%9E%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%9E%E0%B8%A7%E0%B8%87-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B9%81%E0%B8%9F%E0%B8%99%E0%B8%84%E0%B8%A5%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%AE%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B9%88%E0%B8%99"
                                className="btn btn-sm"
                              >
                                คืนสุดท้าย งานรำลึกพุ่มพวง หลังแฟนคลับเฮได้โชคหลักหมื่น
                              </a>
                              <a
                                href="/2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B9%80%E0%B8%A7%E0%B8%A5%E0%B8%B2%E0%B8%9A%E0%B8%AD%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%AB%E0%B8%99%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A2%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%AA%E0%B8%B4%E0%B9%88%E0%B8%87%E0%B8%95%E0%B8%AD%E0%B8%9A%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%84%E0%B8%B8%E0%B9%89%E0%B8%A1%E0%B8%84%E0%B9%88%E0%B8%B2"
                                className="btn btn-sm"
                              >
                                2 ราศี ถึงเวลาบอกลาการเหนื่อยฟรี ได้สิ่งตอบแทนคุ้มค่า
                              </a>
                              <a
                                href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%8A%E0%B9%89%E0%B8%B2%E0%B8%87%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                className="btn btn-sm"
                              >
                                ฝันเห็นงาช้าง
                              </a>
                              <a
                                href="/%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B8%AA%E0%B8%96%E0%B8%B4%E0%B8%95%E0%B8%B4%E0%B8%AB%E0%B8%A7%E0%B8%A2%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81%E0%B8%A3%E0%B8%81%E0%B8%8E%E0%B8%B2%E0%B8%84%E0%B8%A1-67-%E0%B8%A2%E0%B9%89%E0%B8%AD%E0%B8%99%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87-10-%E0%B8%9B%E0%B8%B5"
                                className="btn btn-sm"
                              >
                                รวมสถิติหวยงวด 1 กรกฎาคม 67 ย้อนหลัง 10 ปี
                              </a>
                              <a
                                href="/%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-7-%E0%B8%A7%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%81%E0%B8%B4%E0%B8%94-%E0%B8%8A%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%AB%E0%B8%A5%E0%B9%88%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-%E0%B8%A5%E0%B8%B8%E0%B9%89%E0%B8%99%E0%B8%A3%E0%B8%A7%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C"
                                className="btn btn-sm"
                              >
                                โชคลาภ 7 วันเกิด ชี้เป้าแหล่งโชคลาภ ลุ้นรวยรับทรัพย์
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center -copy-right-container">
                    <p className="mb-0 -copy-right-text">
                      Copyright © 2024 Lotto123. All Rights Reserved.
                    </p>
                  </div>
                </footer>
                {/* Google tag (gtag.js) */}
              </div>
              </>)
        }
        else {
            return (
                <>
                    <div
                        id="main__content"
                        data-bgset="/build/web/ez-bet/img/index-bg.jpg?v=9"
                        className="x-bg-position-center x-bg-index lazyloaded"
                        style={{
                            backgroundImage: 'url("/build/web/ez-bet/img/index-bg.jpg?v=9")'
                        }}
                    >
                        <div className="js-replace-cover-seo-container"></div>
                        <div className="x-invitation-index-container">
                            <div className="x-cover-invitation-container ">
                                <div
                                    className="-cover-invitation-wrapper x-bg-position-center lazyloaded"
                                    data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-cover-bg-light.jpg"
                                    style={{
                                        backgroundImage:
                                            'url("https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-cover-bg-light.jpg")'
                                    }}
                                >
                                    <div className="-content-wrapper">
                                        <h1 className="-title">ลิงก์รับทรัพย์</h1>
                                        <p className="-sub-title">รับคอมมิชชั่นสูงถึง 3%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="-content-section-wrapper">
                                <div className="container">
                                    <div className="x-invitation-content-section-container">
                                        <div className="-title-wrapper">
                                            <span className="-title">รับเงินได้ทุกวัน</span>{" "}
                                            <span className="-title -xl ml-2">Passive Income ที่แท้จริง</span>
                                        </div>
                                        <div className="-image-wrapper">
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.webp?v=9"
                                                />
                                                <source
                                                    type="image/jpg"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.jpg"
                                                />
                                                <img
                                                    alt="เริ่มต้นง่ายๆ ได้ทุกการเล่น"
                                                    className="img-fluid -image"
                                                    width={1110}
                                                    height={329}
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-1-light.jpg"
                                                />
                                            </picture>
                                        </div>
                                        <div className="-title-wrapper">
                                            <span className="-title">ทำมาแล้ว!</span>
                                            <br />{" "}
                                            <span className="-title -lg">แค่เพื่อนเล่น เราก็รับทรัพย์</span>
                                            <br /> <span className="-title -lg">รายได้ 6 หลัก</span>{" "}
                                            <span className="-title -lg ml-1 -underline">ง่ายๆ ทุกเดือน</span>
                                        </div>
                                        <div className="-image-wrapper">
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.webp?v=9"
                                                />
                                                <source
                                                    type="image/jpg"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.jpg"
                                                />
                                                <img
                                                    alt="เริ่มต้นง่ายๆ ได้ทุกการเล่น"
                                                    className="img-fluid -image"
                                                    width={1110}
                                                    height={329}
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/invitation-content-section-2-light.jpg"
                                                />
                                            </picture>
                                        </div>
                                        <div className="-title-wrapper">
                                            <span className="-title">แนะนำเพื่อนกับเราดียังไง</span>
                                        </div>
                                        <div className="-content-wrapper">
                                            <h3 className="-title">
                                                ง่ายกว่าที่คุณคิด เพียงคัดลองลิงก์ของคุณ ส่งให้เพื่อน ยิ่งชวน
                                                ยิ่งได้ มีรายได้ทุกวัน
                                            </h3>{" "}
                                            <p className="-description">
                                                ส่วนแบ่งจากการแนะนำ สามารถนำไปเดิมพันต่อ หรือ ถอนได้เลย
                                                ไม่มีเทิร์นโอเวอร์ ไม่จำกัดยอด สร้างรายได้กับเราได้ทันที ชวนเลย
                                                เพื่อนสายบาคาร่า เพื่อนสายสล็อต เพื่อนสายกีฬา เพื่อนสายยิงปลา
                                                เพื่อนสายหวย ไม่ว่าสายไหนก็ชวนเพื่อนได้ไม่จำกัด
                                                รับส่วนแบ่งค่าคอมมิชชั่นแบบเน้นๆ จ่ายกันแบบรายวัน
                                                อัพเดทยอดของเพื่อนคุณแบบเรียลไทม์ แค่เพื่อนเล่น
                                                คุณก็มีรายได้ไม่จำกัด
                                            </p>
                                        </div>
                                        <div className="x-invitation-percent-and-condition-component">
                                            <div className="-title-wrapper">
                                                <strong className="-title">เงื่อนไขและเปอร์เซ็นต์</strong>
                                            </div>
                                            <ul className="navbar-nav -percent-list">
                                                <li className="nav-item -percent-list-item">
                                                    <strong className="-topic">คาสิโน</strong>
                                                    <p className="-percent">0.3%</p>
                                                    <span className="-description">สูงสุด 5000 /วัน</span>
                                                </li>
                                                <li className="nav-item -percent-list-item">
                                                    <strong className="-topic">สล็อต</strong>
                                                    <p className="-percent">0.5%</p>
                                                    <span className="-description">สูงสุด 5000 /วัน</span>
                                                </li>
                                                <li className="nav-item -percent-list-item">
                                                    <strong className="-topic">หวย</strong>
                                                    <p className="-percent">3%</p>
                                                    <span className="-description">สูงสุด 5000 /วัน</span>
                                                </li>
                                                <li className="nav-item -percent-list-item">
                                                    <strong className="-topic">1234 คริปโต</strong>
                                                    <p className="-percent">0.3%</p>
                                                    <span className="-description">สูงสุด 5000 /วัน</span>
                                                </li>
                                                <li className="nav-item -percent-list-item">
                                                    <strong className="-topic">กีฬา</strong>
                                                    <p className="-percent">1%</p>
                                                    <span className="-description">สูงสุด 2000 /วัน</span>
                                                </li>
                                            </ul>
                                            <div className="-user-description">
                                                <div className="-content-wrapper">
                                                    <div className="text-muted mb-3">
                                                        *เปอร์เซนต์คิดจาก <b>"Turnover"</b> ของสมาชิกคุณ{" "}
                                                        <u className="text-danger">ยกเว้นประเภทกีฬา</u> จะคิดจาก{" "}
                                                        <b>"ยอดได้"</b>
                                                    </div>
                                                    <h3 className="-title">
                                                        ตัวอย่างการได้ค่าเเนะนำแนะนำเพื่อนเล่นหวย
                                                    </h3>
                                                    <p className="-description">
                                                        สมาชิกของคุณ 1 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้ 30
                                                        เครดิต <br />
                                                        สมาชิกของคุณ 10 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้
                                                        300 เครดิต <br />
                                                        สมาชิกของคุณ 100 คน เดิมพันหวย 1,000 เครดิต คุณจะได้รับรายได้
                                                        3,000 เครดิต <br />
                                                    </p>
                                                    <h3 className="-title">แนะนำเพื่อนเล่นคาสิโน</h3>
                                                    <p className="-description">
                                                        สมาชิกของคุณ 1 คน เดิมพันคาสิโน 1,000 เครดิต คุณจะได้รับรายได้
                                                        3 เครดิต <br />
                                                        สมาชิกของคุณ 10 คน เดิมพันคาสิโน 1,000 เครดิต
                                                        คุณจะได้รับรายได้ 30 เครดิต <br />
                                                        สมาชิกของคุณ 100 คน เดิมพันคาสิโน 1,000 เครดิต
                                                        คุณจะได้รับรายได้ 300 เครดิต <br />
                                                    </p>
                                                    <h3 className="-title">แนะนำเพื่อนเล่นกีฬา</h3>
                                                    <p className="-description">
                                                        สมาชิกของคุณ 1 คน มียอดได้จากกีฬา 1,000 เครดิต
                                                        คุณจะได้รับรายได้ 10 เครดิต <br />
                                                        สมาชิกของคุณ 10 คน มียอดได้จากกีฬา 1,000 เครดิต
                                                        คุณจะได้รับรายได้ 100 เครดิต <br />
                                                        สมาชิกของคุณ 100 คน มียอดได้จากกีฬา 1,000 เครดิต
                                                        คุณจะได้รับรายได้ 1,000 เครดิต <br />
                                                    </p>
                                                    <h3 className="-title">เงื่อนไขการถอนเครดิตเเนะนำเพื่อน</h3>
                                                    <p className="-description">
                                                        ต้องมีรายการฝากเงิน 1 ครั้งขึ้นไป
                                                        ภายในเดือนที่แจ้งถอนเครดิตแนะนำเพื่อน <br />
                                                    </p>
                                                    <em className="f-7">
                                                        ** หากพบว่ามีการทุจริตในการปั้มค่าคอมมิชชั่น เดิมพันผิดปกติ
                                                        และ ล่าโปรโมชั่น มีมากกว่า 1 ยูส หรืออื่นๆ
                                                        ที่เข้าข่ายการฉ้อโกงทางบริษัทจะทำการระงับการจ่ายค่าคอมโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="x-modal modal -v2 -alert-modal animated fadeInRight"
                            id="alertModal"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".js-modal-content"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                            data-animatable="fadeInRight"
                            data-delay={700}
                            data-dismiss-alert="true"
                        >
                            <div className="modal-dialog -modal-size -v2  " role="document">
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 "
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="modal-body -modal-body">
                                        <div className="d-flex -alert-body">
                                            <div className="text-center mr-3 -alert-body-wrapper">
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.webp"
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.png"
                                                    />
                                                    <img
                                                        className="-img-alert js-ic-success img-fluid"
                                                        alt="ทำรายการเว็บพนันออนไลน์สำเร็จ"
                                                        width={40}
                                                        height={40}
                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-success.png"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.webp"
                                                    />
                                                    <source
                                                        type="image/png"
                                                        srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.png"
                                                    />
                                                    <img
                                                        className="-img-alert js-ic-fail img-fluid"
                                                        alt="ทำรายการเว็บพนันออนไลน์ไม่สำเร็จ"
                                                        width={40}
                                                        height={40}
                                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-alert-failed.png"
                                                    />
                                                </picture>
                                            </div>
                                            <div className="my-auto js-modal-content" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="x-modal modal -v2 x-theme-switcher-v2"
                            id="themeSwitcherModalxx"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".js-modal-content"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                        >
                            <div
                                className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered"
                                role="document"
                            >
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 "
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="modal-body -modal-body">
                                        <div className="-theme-switcher-container">
                                            <div className="-inner-header-section">
                                                <a className="-link-wrapper" href="/">
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            data-srcset="/build/web/ez-bet/img/logo.webp?v=9"
                                                        />
                                                        <source
                                                            type="image/png?v=9"
                                                            data-srcset="/build/web/ez-bet/img/logo.png?v=9"
                                                        />
                                                        <img
                                                            alt="logo image"
                                                            className="img-fluid lazyload -logo lazyload"
                                                            width={180}
                                                            height={42}
                                                            data-src="/build/web/ez-bet/img/logo.png?v=9"
                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                        />
                                                    </picture>
                                                </a>
                                            </div>
                                            <div className="-inner-top-body-section">
                                                <div className="col-6 -wrapper-box">
                                                    <button
                                                        type="button"
                                                        className="btn -btn-item -top-btn -register-button  lazyload x-bg-position-center"
                                                        data-toggle="modal"
                                                        data-dismiss="modal"
                                                        data-target="#registerModal"
                                                        data-bgset="/build/web/ez-bet/img/btn-register-login-bg.png?v=9"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-register.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-register.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนสมัครสมาชิก"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-register.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">สมัครเลย</div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-6 -wrapper-box">
                                                    <button
                                                        type="button"
                                                        className="btn -btn-item -top-btn -login-btn  lazyload x-bg-position-center"
                                                        data-toggle="modal"
                                                        data-dismiss="modal"
                                                        data-target="#loginModal"
                                                        data-bgset="/build/web/ez-bet/img/btn-register-login-bg.png?v=9"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-login.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-login.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนเข้าสู่ระบบ"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-login.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">เข้าสู่ระบบ</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="-inner-center-body-section">
                                                <div className="col-6 -wrapper-box">
                                                    <a
                                                        href="/event"
                                                        className="btn -btn-item -event-button -menu-center -horizontal "
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนสิทธิพิเศษ"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={65}
                                                                height={53}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-event.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">สิทธิพิเศษ</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-6 -wrapper-box">
                                                    <a
                                                        href="/promotions"
                                                        className="btn -btn-item -promotion-button -menu-center -horizontal lazyload x-bg-position-center"
                                                        data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนโปรโมชั่น"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={65}
                                                                height={53}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-promotion.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">โปรโมชั่น</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-6 -wrapper-box">
                                                    <a
                                                        href="https://doball123.live/"
                                                        className="btn -btn-item -ball-live-button -menu-center -horizontal lazyload x-bg-position-center"
                                                        target="_blank"
                                                        rel="noopener nofollow"
                                                        data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนดูบอลสด"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={65}
                                                                height={53}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-ball-live.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">ดูบอลสด</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-6 -wrapper-box">
                                                    <a
                                                        href="https://lin.ee/IHJTrwu"
                                                        className="btn -btn-item -line-button -menu-center -horizontal lazyload x-bg-position-center"
                                                        target="_blank"
                                                        rel="noopener nofollow"
                                                        data-bgset="/build/web/ez-bet/img/btn-menu-middle-bg.png?v=9"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.png"
                                                            />
                                                            <img
                                                                alt="รูปไอคอนดูหนัง"
                                                                className="img-fluid -icon-image lazyload"
                                                                width={65}
                                                                height={53}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-modal-menu-line.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <div className="-typo-wrapper">
                                                            <div className="-typo ">ไลน์</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="-inner-bottom-body-section">
                                                <div className="col-12 -wrapper-box">
                                                    <a
                                                        href="#0"
                                                        className="btn -btn-item -social-share-button -menu-banner  "
                                                        data-toggle="modal"
                                                        data-target="#loginModal"
                                                        data-dismiss="modal"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="/build/web/ez-bet/img/menu-banner-social-share.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png?v=9"
                                                                data-srcset="/build/web/ez-bet/img/menu-banner-social-share.png?v=9"
                                                            />
                                                            <img
                                                                alt="รูปแบนเนอร์แชร์แลกทรัพย์"
                                                                className="img-fluid -banner-image lazyload"
                                                                width={342}
                                                                height={98}
                                                                data-src="/build/web/ez-bet/img/menu-banner-social-share.png?v=9"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                    </a>
                                                </div>
                                                <div className="col-12 -wrapper-box">
                                                    <a
                                                        href="#0"
                                                        className="btn -btn-item -bookmark-button -menu-banner  js-add-to-home-screen"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="/build/web/ez-bet/img/menu-banner-bookmark.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png?v=9"
                                                                data-srcset="/build/web/ez-bet/img/menu-banner-bookmark.png?v=9"
                                                            />
                                                            <img
                                                                alt="รูปแบนเนอร์ bookmark"
                                                                className="img-fluid -banner-image lazyload"
                                                                width={342}
                                                                height={98}
                                                                data-src="/build/web/ez-bet/img/menu-banner-bookmark.png?v=9"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="x-modal modal -v2 "
                            id="bookmarkModal"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".js-modal-content"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                        >
                            <div
                                className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable         -no-fixed-button
    "
                                role="document"
                            >
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 "
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="modal-header -modal-header">
                                        <h3 className="x-title-modal d-inline-block m-auto">
                                            <span>Bookmark</span>{" "}
                                        </h3>
                                    </div>
                                    <div className="modal-body -modal-body">
                                        <div className="x-bookmark-modal-container">
                                            <nav>
                                                <div
                                                    className="nav nav-tabs x-bookmark-tabs-header-wrapper"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <a
                                                        className="nav-link "
                                                        id="nav-android-tab"
                                                        data-toggle="tab"
                                                        href="#nav-android"
                                                        role="tab"
                                                        aria-controls="nav-android"
                                                        aria-selected="true"
                                                    >
                                                        Android
                                                    </a>
                                                    <a
                                                        className="nav-link active"
                                                        id="nav-ios-tab"
                                                        data-toggle="tab"
                                                        href="#nav-ios"
                                                        role="tab"
                                                        aria-controls="nav-ios"
                                                        aria-selected="true"
                                                    >
                                                        iOS
                                                    </a>
                                                </div>
                                            </nav>
                                            <div
                                                className="tab-content x-bookmark-tabs-content-wrapper"
                                                id="nav-tabContent"
                                            >
                                                <div
                                                    className="tab-pane fade "
                                                    id="nav-android"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-android-tab"
                                                >
                                                    <div
                                                        className="-slide-wrapper -bookmark-slider-for-android slick-initialized slick-slider slick-dotted"
                                                        data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-android"}'
                                                    >
                                                        <div className="slick-list draggable">
                                                            <div
                                                                className="slick-track"
                                                                style={{ opacity: 1, width: 0 }}
                                                            >
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                                                    data-slick-index={0}
                                                                    aria-hidden="false"
                                                                    role="tabpanel"
                                                                    id="slick-slide00"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 999,
                                                                        opacity: 1
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-1.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-1.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-android-1.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    role="tabpanel"
                                                                    id="slick-slide01"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-2.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-2.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-android-2.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    role="tabpanel"
                                                                    id="slick-slide02"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-3.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-android-3.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-android-3.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="slick-dots" style={{}} role="tablist">
                                                            <li className="slick-active" role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control00"
                                                                    aria-controls="slick-slide00"
                                                                    aria-label="1 of 3"
                                                                    tabIndex={0}
                                                                    aria-selected="true"
                                                                >
                                                                    1
                                                                </button>
                                                            </li>
                                                            <li role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control01"
                                                                    aria-controls="slick-slide01"
                                                                    aria-label="2 of 3"
                                                                    tabIndex={-1}
                                                                >
                                                                    2
                                                                </button>
                                                            </li>
                                                            <li role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control02"
                                                                    aria-controls="slick-slide02"
                                                                    aria-label="3 of 3"
                                                                    tabIndex={-1}
                                                                >
                                                                    3
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        className="-slide-wrapper -bookmark-slider-nav-android slick-initialized slick-slider"
                                                        data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-android"}'
                                                    >
                                                        <div className="slick-list draggable">
                                                            <div
                                                                className="slick-track"
                                                                style={{ opacity: 1, width: 0 }}
                                                            >
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                                                    data-slick-index={0}
                                                                    aria-hidden="false"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 999,
                                                                        opacity: 1
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            เข้า Google Chrome แล้ว Search <br /> “Lotto123”
                                                                            เข้าสู่หน้าเว็บ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">เลือก “ติดตั้ง”</div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            ตรวจสอบหน้า <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-ios"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-ios-tab"
                                                >
                                                    <div
                                                        className="-slide-wrapper -bookmark-slider-for-ios slick-initialized slick-slider slick-dotted"
                                                        data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-ios"}'
                                                    >
                                                        <div className="slick-list draggable">
                                                            <div
                                                                className="slick-track"
                                                                style={{ opacity: 1, width: 0 }}
                                                            >
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                                                    data-slick-index={0}
                                                                    aria-hidden="false"
                                                                    role="tabpanel"
                                                                    id="slick-slide20"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 999,
                                                                        opacity: 1
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-1.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-1.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-ios-1.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    role="tabpanel"
                                                                    id="slick-slide21"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-2.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-2.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-ios-2.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    role="tabpanel"
                                                                    id="slick-slide22"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-3.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-3.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-ios-3.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={3}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    role="tabpanel"
                                                                    id="slick-slide23"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <picture>
                                                                        <source
                                                                            type="image/webp"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-4.webp"
                                                                        />
                                                                        <source
                                                                            type="image/png?v=9"
                                                                            data-srcset="/build/web/ez-bet/img/bookmark-ios-4.png?v=9"
                                                                        />
                                                                        <img
                                                                            className="-img lazyload"
                                                                            alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                            width={253}
                                                                            height={513}
                                                                            data-src="/build/web/ez-bet/img/bookmark-ios-4.png?v=9"
                                                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="slick-dots" style={{}} role="tablist">
                                                            <li className="slick-active" role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control20"
                                                                    aria-controls="slick-slide20"
                                                                    aria-label="1 of 4"
                                                                    tabIndex={0}
                                                                    aria-selected="true"
                                                                >
                                                                    1
                                                                </button>
                                                            </li>
                                                            <li role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control21"
                                                                    aria-controls="slick-slide21"
                                                                    aria-label="2 of 4"
                                                                    tabIndex={-1}
                                                                >
                                                                    2
                                                                </button>
                                                            </li>
                                                            <li role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control22"
                                                                    aria-controls="slick-slide22"
                                                                    aria-label="3 of 4"
                                                                    tabIndex={-1}
                                                                >
                                                                    3
                                                                </button>
                                                            </li>
                                                            <li role="presentation">
                                                                <button
                                                                    type="button"
                                                                    role="tab"
                                                                    id="slick-slide-control23"
                                                                    aria-controls="slick-slide23"
                                                                    aria-label="4 of 4"
                                                                    tabIndex={-1}
                                                                >
                                                                    4
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        className="-slide-wrapper -bookmark-slider-nav-ios slick-initialized slick-slider"
                                                        data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-ios"}'
                                                    >
                                                        <div className="slick-list draggable">
                                                            <div
                                                                className="slick-track"
                                                                style={{ opacity: 1, width: 0 }}
                                                            >
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide slick-current slick-active"
                                                                    data-slick-index={0}
                                                                    aria-hidden="false"
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 999,
                                                                        opacity: 1
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-1.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            เข้า Safari แล้ว Search “Lotto123” <br />{" "}
                                                                            เข้าสู่หน้าเว็บ กดที่
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={1}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-2.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            เลือก “เพิ่มลงใปยังหน้าจอโฮม”
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={2}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-3.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            กด “เพิ่ม”ทางลัดเข้าสู่เกมส์ <br /> ลงในหน้าจอโฮม
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="-slide-inner-wrapper slick-slide"
                                                                    data-slick-index={3}
                                                                    aria-hidden="true"
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        width: 0,
                                                                        position: "relative",
                                                                        left: 0,
                                                                        top: 0,
                                                                        zIndex: 998,
                                                                        opacity: 0
                                                                    }}
                                                                >
                                                                    <div className="-content-wrapper -center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.webp"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.png"
                                                                            />
                                                                            <img
                                                                                className="-icon lazyload"
                                                                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                                                                                width={60}
                                                                                height={60}
                                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/bookmark-slide-number-4.png"
                                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                                            />
                                                                        </picture>
                                                                        <div className="-description">
                                                                            ตรวจสอบหน้า <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="x-modal modal -v2 -modal-full-page"
                            id="websiteMenuModal"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".js-modal-content"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                        >
                            <div
                                className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
                                role="document"
                            >
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 "
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="modal-header -modal-header">
                                        <h3 className="x-title-modal d-inline-block m-auto">
                                            <span />{" "}
                                        </h3>
                                    </div>
                                    <div className="modal-body -modal-body">
                                        <div className="x-website-menu-modal-body">
                                            <a href="/" className="nav-link -btn-logo">
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet/img/logo.webp?v=9"
                                                    />
                                                    <source
                                                        type="image/png?v=9"
                                                        data-srcset="/build/web/ez-bet/img/logo.png?v=9"
                                                    />
                                                    <img
                                                        alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                                        className="img-fluid lazyload -img"
                                                        width={400}
                                                        height={150}
                                                        data-src="/build/web/ez-bet/img/logo.png?v=9"
                                                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    />
                                                </picture>
                                            </a>
                                            <ul className="nav x-website-menu-entry-list -as-vertical">
                                                <li className="nav-item -nav-item">
                                                    <a
                                                        className="nav-link -nav-link"
                                                        href="https://ezmovie.co"
                                                        target="_blank"
                                                        rel="noopener nofollow"
                                                    >
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.png"
                                                            />
                                                            <img
                                                                alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                                                className="img-fluid lazyload -img"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-movie.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <span className="-text">ดูหนัง</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item -nav-item">
                                                    <a className="nav-link -nav-link" href="/promotions">
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.png"
                                                            />
                                                            <img
                                                                alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                                                className="img-fluid lazyload -img"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-promotion.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <span className="-text">โปรโมชั่น</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item -nav-item">
                                                    <a className="nav-link -nav-link" href="/event">
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.png"
                                                            />
                                                            <img
                                                                alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                                                className="img-fluid lazyload -img"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-event.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <span className="-text">สิทธิพิเศษ</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item -nav-item">
                                                    <a className="nav-link -nav-link" href="/blogs">
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                data-srcset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.png"
                                                            />
                                                            <img
                                                                alt="บาคาร่าออนไลน์ สล็อตออนไลน์ อันดับหนึ่งในประเทศไทย"
                                                                className="img-fluid lazyload -img"
                                                                width={50}
                                                                height={50}
                                                                data-src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/ic-website-menu-blog.png"
                                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                            />
                                                        </picture>
                                                        <span className="-text">บทความ</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="x-wrapper-right-container "></div>
                        <div
                            className="x-modal modal -v2 -with-half-size"
                            id="loginModalx"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".js-modal-content"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                        >
                            <div
                                className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -dialog-in-tab -register-index-dialog"
                                role="document"
                            >
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 -in-tab"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="x-modal-account-security-tabs js-modal-account-security-tabs -v3">
                                        <button
                                            type="button"
                                            className="-btn -register js-modal-account-security-tab-button "
                                            data-modal-target="#registerModal"
                                        >
                                            สมัครสมาชิก
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    data-srcset="/build/web/ez-bet/img/register-badge-new-user.webp?v=9"
                                                />
                                                <source
                                                    type="image/png?v=9"
                                                    data-srcset="/build/web/ez-bet/img/register-badge-new-user.png?v=9"
                                                />
                                                <img
                                                    alt="Hexa789hImage"
                                                    className="img-fluid lazyload -ic-badge"
                                                    data-src="/build/web/ez-bet/img/register-badge-new-user.png?v=9"
                                                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                />
                                            </picture>
                                        </button>
                                        <button
                                            type="button"
                                            className="-btn -login js-modal-account-security-tab-button -active"
                                            data-modal-target="#loginModal"
                                        >
                                            เข้าสู่ระบบ
                                        </button>
                                    </div>
                                    <div className="modal-body -modal-body">
                                        <div className="x-register-tab-container -login js-tab-pane-checker-v3">
                                            <div className="container">
                                                <ul className="nav nav-tabs x-register-tab">
                                                    <li
                                                        className="nav-item   -loginPhoneNumber"
                                                        id="tab-loginPhoneNumber"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-loginPhoneNumber"
                                                            className="nav-link"
                                                        >
                                                            loginPhoneNumber
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item   -loginPassword"
                                                        id="tab-loginPassword"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-loginPassword"
                                                            className="nav-link"
                                                        >
                                                            loginPassword
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item   -loginPasswordV3"
                                                        id="tab-loginPasswordV3"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-loginPasswordV3"
                                                            className="nav-link"
                                                        >
                                                            loginPasswordV3
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item   -resetPasswordVerifyOtp"
                                                        id="tab-resetPasswordVerifyOtp"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-resetPasswordVerifyOtp"
                                                            className="nav-link"
                                                        >
                                                            resetPasswordVerifyOtp
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item   -resetPasswordSetPassword"
                                                        id="tab-resetPasswordSetPassword"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-resetPasswordSetPassword"
                                                            className="nav-link"
                                                        >
                                                            resetPasswordSetPassword
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item   -resetPasswordResult"
                                                        id="tab-resetPasswordResult"
                                                    >
                                                        <a
                                                            data-toggle="tab"
                                                            href="#tab-content-resetPasswordResult"
                                                            className="nav-link"
                                                        >
                                                            resetPasswordResult
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane active"
                                                    id="tab-content-loginPhoneNumber"
                                                    data-completed-dismiss-modal=""
                                                >
                                                    <div className="x-modal-body-base -v3 -phone-number x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div className="col">
                                                                <div className="x-title-register-modal-v3 ">
                                                                    <span className="-title">กรอกหมายเลขโทรศัพท์</span>
                                                                    <span className="-sub-title">
                                                                        เบอร์โทรศัพท์ที่ใช้ในการสมัครเพื่อล็อคอิน
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="-fake-inner-body">
                                                                    <form
                                                                        method="post"
                                                                        data-register-v3-form="/_ajax_/v3/check-for-login"
                                                                        data-register-step="loginPhoneNumber"
                                                                    >
                                                                        <div className="-x-input-icon input-group text-center js-register-v3-input-group">
                                                                            <input
                                                                                type="text"
                                                                                required=""
                                                                                autoComplete="off"
                                                                                id="phoneNumber"
                                                                                name="phoneNumber"
                                                                                placeholder="095-123-4567"
                                                                                className="form-control x-form-control js-keypad-desktop"
                                                                                autofocus="true"
                                                                                data-type="phone_number"
                                                                                data-options='{"maxLength":12}'
                                                                            />
                                                                        </div>
                                                                        <div className="d-none js-keypad-number-wrapper">
                                                                            <div className="x-keypad-number-container">
                                                                                <div className="-btn-group-wrapper">
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={1}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        1
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={2}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        2
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={3}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        3
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={4}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        4
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={5}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        5
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={6}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        6
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={7}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        7
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={8}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        8
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={9}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        9
                                                                                    </button>
                                                                                    <div
                                                                                        className="btn -btn js-keypad-btn -btn-none"
                                                                                        type="button"
                                                                                        data-key="none"
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    />
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-keypad"
                                                                                        type="button"
                                                                                        data-key={0}
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        0
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn -btn js-keypad-btn -btn-backspace"
                                                                                        type="button"
                                                                                        data-key="backspace"
                                                                                        data-target="#phoneNumber"
                                                                                        data-options='{"maxLength":12}'
                                                                                    >
                                                                                        <i className="fas fa-backspace" />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn  -submit mt-lg-3 mt-0"
                                                                            >
                                                                                ยืนยัน
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="tab-content-loginPassword"
                                                    data-completed-dismiss-modal=""
                                                >
                                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div className="col">
                                                                <div className="x-title-register-modal-v3 ">
                                                                    <span className="-title">รหัสผ่าน</span>
                                                                    <span className="-sub-title">
                                                                        กรอกรหัสเข้าระบบ{" "}
                                                                        <span className="js-phone-number -highlight" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="x-modal-separator-container x-form-register">
                                                                    <div className="-top ">
                                                                        <div
                                                                            data-animatable="fadeInRegister"
                                                                            data-offset={0}
                                                                            className="-animatable-container -password-body animated fadeInRegister"
                                                                        >
                                                                            <form
                                                                                action="/login-json-check"
                                                                                className="js-login-form"
                                                                            >
                                                                                <div className="-x-input-icon flex-column">
                                                                                    <input
                                                                                        type="password"
                                                                                        id="password"
                                                                                        name="password"
                                                                                        className="form-control x-form-control"
                                                                                        placeholder="XXXXXXXX"
                                                                                    />
                                                                                </div>
                                                                                <div className="-x-input-icon">
                                                                                    <input
                                                                                        type="hidden"
                                                                                        id="usernameV3Normal"
                                                                                        inputMode="text"
                                                                                        name="username"
                                                                                        pattern="[0-9]*"
                                                                                        autofocus=""
                                                                                        className="form-control x-form-control"
                                                                                        placeholder="เบอร์โทรศัพท์"
                                                                                    />
                                                                                </div>
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="phoneNumberV3Normal"
                                                                                    name="phoneNumber"
                                                                                    autofocus=""
                                                                                    className="form-control x-form-control"
                                                                                    placeholder="เบอร์โทรศัพท์"
                                                                                />
                                                                                <div className="text-center">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn -submit btn-primary my-lg-3 mt-0"
                                                                                    >
                                                                                        ยืนยัน
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                            <div className="x-reset-pw-text-container ">
                                                                                <form
                                                                                    method="post"
                                                                                    data-register-v3-form="/_ajax_/v3/reset-password/request-otp"
                                                                                    data-register-step="resetPasswordPhoneNumber"
                                                                                    data-tab-next-step="#tab-content-resetPasswordVerifyOtp"
                                                                                >
                                                                                    <input
                                                                                        type="hidden"
                                                                                        required=""
                                                                                        id="phone_numberV3Normal[phoneNumber]"
                                                                                        name="phone_number[phoneNumber]"
                                                                                        pattern=".{10,11}"
                                                                                        defaultValue=""
                                                                                        placeholder="095-123-4567"
                                                                                    />
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="-btn-reset-password"
                                                                                    >
                                                                                        <u>ลืมรหัสผ่าน</u>
                                                                                    </button>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-bottom "></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="tab-content-loginPasswordV3"
                                                    data-completed-dismiss-modal=""
                                                >
                                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div className="col">
                                                                <div className="x-title-register-modal-v3 ">
                                                                    <span className="-title">รหัสผ่าน</span>
                                                                    <span className="-sub-title">
                                                                        กรอกตัวเลขรหัส 6 หลัก เข้าสู่ระบบ{" "}
                                                                        <span className="js-phone-number -highlight" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="x-modal-separator-container x-form-register">
                                                                    <div className="-top ">
                                                                        <div
                                                                            data-animatable="fadeInRegister"
                                                                            data-offset={0}
                                                                            className="-animatable-container -password-body animated fadeInRegister"
                                                                        >
                                                                            <form
                                                                                action="/login-json-check"
                                                                                className="js-login-form"
                                                                            >
                                                                                <div className="d-flex -password-input-container js-register-v3-input-group">
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_1"
                                                                                            name="loginPassword_1"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_2"
                                                                                            name="loginPassword_2"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_3"
                                                                                            name="loginPassword_3"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_4"
                                                                                            name="loginPassword_4"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_5"
                                                                                            name="loginPassword_5"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="loginPassword_6"
                                                                                            name="loginPassword_6"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-none js-keypad-number-wrapper">
                                                                                    <div className="x-keypad-number-container">
                                                                                        <div className="-btn-group-wrapper">
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={1}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                1
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={2}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                2
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={3}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                3
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={4}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                4
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={5}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                5
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={6}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                6
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={7}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                7
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={8}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                8
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={9}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                9
                                                                                            </button>
                                                                                            <div
                                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                                type="button"
                                                                                                data-key="none"
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            />
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={0}
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                0
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                                type="button"
                                                                                                data-key="backspace"
                                                                                                data-target="#loginPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-password-container"}'
                                                                                            >
                                                                                                <i className="fas fa-backspace" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="-x-input-icon">
                                                                                    <input
                                                                                        type="hidden"
                                                                                        id="usernameV3"
                                                                                        inputMode="text"
                                                                                        name="username"
                                                                                        pattern="[0-9]*"
                                                                                        autofocus=""
                                                                                        className="form-control x-form-control"
                                                                                        placeholder="เบอร์โทรศัพท์"
                                                                                    />
                                                                                </div>
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="phoneNumberV3"
                                                                                    name="phoneNumber"
                                                                                    autofocus=""
                                                                                    className="form-control x-form-control"
                                                                                    placeholder="เบอร์โทรศัพท์"
                                                                                />
                                                                                <div className="text-center">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn -submit btn-primary my-lg-3 mt-0"
                                                                                    >
                                                                                        ยืนยัน
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                            <div className="x-reset-pw-text-container ">
                                                                                <form
                                                                                    method="post"
                                                                                    data-register-v3-form="/_ajax_/v3/reset-password/request-otp"
                                                                                    data-register-step="resetPasswordPhoneNumber"
                                                                                    data-tab-next-step="#tab-content-resetPasswordVerifyOtp"
                                                                                >
                                                                                    <input
                                                                                        type="hidden"
                                                                                        required=""
                                                                                        id="phone_numberV3[phoneNumber]"
                                                                                        name="phone_number[phoneNumber]"
                                                                                        pattern=".{10,11}"
                                                                                        defaultValue=""
                                                                                        placeholder="095-123-4567"
                                                                                    />
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="-btn-reset-password"
                                                                                    >
                                                                                        <u>ลืมรหัสผ่าน</u>
                                                                                    </button>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-bottom "></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="tab-content-resetPasswordVerifyOtp"
                                                    data-completed-dismiss-modal=""
                                                >
                                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div className="col">
                                                                <div className="x-title-register-modal-v3 ">
                                                                    <span className="-title">กรอกรหัส OTP</span>
                                                                    <span className="-sub-title">
                                                                        รหัส 4 หลัก ส่งไปยัง{" "}
                                                                        <span className="js-phone-number -highlight" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="x-modal-separator-container x-form-register">
                                                                    <div className="-top ">
                                                                        <div
                                                                            data-animatable="fadeInRegister"
                                                                            data-offset={0}
                                                                            className="-animatable-container -otp-body animated fadeInRegister"
                                                                        >
                                                                            <form
                                                                                method="post"
                                                                                data-register-v3-form="/_ajax_/v3/reset-password/verify-otp/_resetPasswordToken"
                                                                                data-register-step="resetPasswordVerifyOtp"
                                                                                data-tab-next-step="#tab-content-resetPasswordSetPassword"
                                                                                name="js-reset-password-v3-otp-form"
                                                                            >
                                                                                <div className="d-flex -otp-input-container js-register-v3-input-group">
                                                                                    <div className="js-separator-container js-login-reset-password-otp-container">
                                                                                        <input
                                                                                            type="text"
                                                                                            id="resetPasswordOtp0"
                                                                                            name="otp0"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-otp js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="otp"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-login-reset-password-otp-container">
                                                                                        <input
                                                                                            type="text"
                                                                                            id="resetPasswordOtp1"
                                                                                            name="otp1"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-otp js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="otp"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-login-reset-password-otp-container">
                                                                                        <input
                                                                                            type="text"
                                                                                            id="resetPasswordOtp2"
                                                                                            name="otp2"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-otp js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="otp"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-login-reset-password-otp-container">
                                                                                        <input
                                                                                            type="text"
                                                                                            id="resetPasswordOtp3"
                                                                                            name="otp3"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-otp js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="otp"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="resetPasswordOtp"
                                                                                    name="otp"
                                                                                    pattern="[0-9]*"
                                                                                    className="form-control mb-3"
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="resetPasswordToken"
                                                                                    name="resetPasswordToken"
                                                                                    className="form-control mb-3"
                                                                                />
                                                                                <div className="d-none js-keypad-number-wrapper">
                                                                                    <div className="x-keypad-number-container">
                                                                                        <div className="-btn-group-wrapper">
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={1}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                1
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={2}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                2
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={3}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                3
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={4}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                4
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={5}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                5
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={6}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                6
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={7}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                7
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={8}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                8
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={9}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                9
                                                                                            </button>
                                                                                            <div
                                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                                type="button"
                                                                                                data-key="none"
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            />
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={0}
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                0
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                                type="button"
                                                                                                data-key="backspace"
                                                                                                data-target="#resetPasswordOtp0"
                                                                                                data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                                            >
                                                                                                <i className="fas fa-backspace" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn -submit btn-primary my-lg-3 mt-0"
                                                                                    >
                                                                                        ยืนยัน
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-bottom "></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="tab-content-resetPasswordSetPassword"
                                                    data-completed-dismiss-modal=""
                                                >
                                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div className="col">
                                                                <div className="x-title-register-modal-v3 ">
                                                                    <span className="-title">ตั้งรหัสผ่านใหม่</span>
                                                                    <span className="-sub-title">
                                                                        กรอกตัวเลขรหัส 6 หลัก เพื่อใช้เข้าสู่ระบบ
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="x-modal-separator-container x-form-register">
                                                                    <div className="-top ">
                                                                        <div
                                                                            data-animatable="fadeInRegister"
                                                                            data-offset={0}
                                                                            className="-animatable-container -password-body animated fadeInRegister"
                                                                        >
                                                                            <form
                                                                                method="post"
                                                                                data-register-v3-form="/_ajax_/v3/reset-password/set-password/_resetPasswordSetPassword"
                                                                                data-register-step="resetPasswordSetPassword"
                                                                                data-tab-next-step="#tab-content-resetPasswordResult"
                                                                            >
                                                                                <div className="d-flex -password-input-container js-register-v3-input-group">
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_1"
                                                                                            name="resetPasswordSetPassword_1"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_2"
                                                                                            name="resetPasswordSetPassword_2"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_3"
                                                                                            name="resetPasswordSetPassword_3"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_4"
                                                                                            name="resetPasswordSetPassword_4"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_5"
                                                                                            name="resetPasswordSetPassword_5"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="js-separator-container js-reset-password-container">
                                                                                        <input
                                                                                            type="password"
                                                                                            id="resetPasswordSetPassword_6"
                                                                                            name="resetPasswordSetPassword_6"
                                                                                            inputMode="text"
                                                                                            readOnly=""
                                                                                            pattern="[0-9]*"
                                                                                            autofocus={1}
                                                                                            className="-digit-password js-otp-input"
                                                                                            data-separator-input="true"
                                                                                            data-type="set_password"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <input
                                                                                    type="hidden"
                                                                                    id="resetPasswordSetPasswordToken"
                                                                                    name="resetPasswordSetPasswordToken"
                                                                                />
                                                                                <div className="d-none js-keypad-number-wrapper">
                                                                                    <div className="x-keypad-number-container">
                                                                                        <div className="-btn-group-wrapper">
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={1}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                1
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={2}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                2
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={3}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                3
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={4}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                4
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={5}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                5
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={6}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                6
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={7}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                7
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={8}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                8
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={9}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                9
                                                                                            </button>
                                                                                            <div
                                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                                type="button"
                                                                                                data-key="none"
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            />
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                                type="button"
                                                                                                data-key={0}
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                0
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                                type="button"
                                                                                                data-key="backspace"
                                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                                            >
                                                                                                <i className="fas fa-backspace" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn -submit btn-primary my-lg-3 mt-0"
                                                                                    >
                                                                                        ยืนยัน
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-bottom "></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="tab-content-resetPasswordResult"
                                                    data-completed-dismiss-modal="#loginModal"
                                                >
                                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                                        <div className="row -register-container-wrapper">
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="col animated fadeInRegister"
                                                            >
                                                                <div className="text-center d-flex flex-column justify-content-center h-100">
                                                                    <div className="text-center">
                                                                        <picture>
                                                                            <source
                                                                                type="image/webp"
                                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.webp?v=9"
                                                                            />
                                                                            <source
                                                                                type="image/png"
                                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                                            />
                                                                            <img
                                                                                alt="สมัครสมาชิก SUCCESS"
                                                                                className="js-ic-success -success-ic img-fluid"
                                                                                width={150}
                                                                                height={150}
                                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                    <div className="-title">
                                                                        อัปเดตรหัสผ่านของคุณเรียบร้อย!
                                                                    </div>
                                                                    <div className="-sub-title">
                                                                        ไปหน้าเข้าสู่ระบบอัตโนมัติใน
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="x-modal modal -v2 -with-half-size"
                            id="registerModalxx"
                            role="dialog"
                            aria-hidden="true"
                            data-loading-container=".modal-body"
                            data-ajax-modal-always-reload="true"
                            tabIndex={-1}
                            data-ajax-modal="/_ajax_/v3/register/entry"
                            data-container="#registerModaxx"
                        >
                            <div
                                className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -dialog-in-tab -register-index-dialog"
                                role="document"
                            >
                                <div className="modal-content -modal-content">
                                    <button
                                        type="button"
                                        className="close f-1 "
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                    <div className="modal-header -modal-header">
                                        <h3 className="x-title-modal d-inline-block m-auto">
                                            <span />{" "}
                                        </h3>
                                    </div>
                                    <div className="modal-body -modal-body">
                                        <div className="js-modal-content" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="x-footer -ezl -anon">
                            <div
                                className="-inner-wrapper x-bg-position-center lazyloaded"
                                data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/footer-inner-bg.png"
                                style={{
                                    backgroundImage:
                                        'url("https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/footer-inner-bg.png")'
                                }}
                            >
                                <div className="container -inner-title-wrapper">
                                    <h3 className="-text-title">Hexa789hระบบใหม่ เจ้าแรกในไทย</h3>
                                    <p className="-text-sub-title">
                                        หวยออนไลน์ Hexa789hสร้างประสบการณ์ใหม่ให้กับคุณได้ดีที่สุด
                                        โดยเฉพาะการเล่นกับเว็บไซต์ Lotto123
                                        ที่จะพาคุณไปพบหวยหลากหลายประเภทรวมไว้ในเว็บเดียว
                                        สมัครสมาชิกแล้วพร้อมเข้าเลือกเลขได้อย่างเป็นอิสระ
                                        ไม่ว่าคุณจะแทงหวยในรูปแบบใด ทำเงินได้ตลอด 24 ชั่วโมงอย่างมั่นใจ
                                        เพราะที่นี่มีความมั่นคงสูง และเตรียมพร้อมในทุก ๆ
                                        ด้านเพื่อให้คอหวยทุกคนได้รับความคุ้มค่ามากที่สุด
                                        ทั้งยังกล้าการันตีการเป็นเว็บหวยที่ดีที่สุดแห่งยุคอีกด้วย
                                    </p>
                                </div>
                                <div className="container">
                                    <div className="-footer-menu">
                                        <div className="-block-provider">
                                            <h3 className="-text-title -provider">PAYMENTS ACCEPTED</h3>
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.webp?v=9"
                                                />
                                                <source
                                                    type="image/png"
                                                    srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.png"
                                                />
                                                <img
                                                    alt="หวยออนไลน์ แทงหวยออนไลน์ คาสิโนออนไลน์ เว็บพนันออนไลน์"
                                                    className="img-fluid -image"
                                                    width={580}
                                                    height={40}
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/icon-payment.png"
                                                />
                                            </picture>
                                        </div>
                                        <div className="-block-tag">
                                            <h3 className="-text-title -tag">TAG</h3>
                                            <div className="row x-footer-seo -ezl">
                                                <div className="col-12 mb-3 -footer-seo-title"></div>
                                                <div className="col-12 -tags">
                                                    <a
                                                        href="/%E0%B8%A1%E0%B8%B2%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%AB%E0%B8%99%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%AA%E0%B8%B8%E0%B8%9E%E0%B8%A3%E0%B8%A3%E0%B8%93-%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81-%E0%B8%84-67"
                                                        className="btn btn-sm"
                                                    >
                                                        มาแล้ว!! เลขเด็ด หนุ่มสุพรรณ งวด 1 ก.ค.67
                                                    </a>
                                                    <a
                                                        href="/4-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A8%E0%B8%A3%E0%B8%A9%E0%B8%90%E0%B8%B5%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B8%A2%E0%B9%81%E0%B8%94%E0%B8%87-%E0%B8%8A%E0%B8%B0%E0%B8%95%E0%B8%B2%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B8%81%E0%B8%9F%E0%B8%B7%E0%B9%89%E0%B8%99%E0%B9%83%E0%B8%99%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%87%E0%B8%84%E0%B8%A3%E0%B8%B6%E0%B9%88%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87"
                                                        className="btn btn-sm"
                                                    >
                                                        4 ราศี ว่าที่เศรษฐีป้ายแดง ชะตาพลิกฟื้นในช่วงครึ่งปีหลัง
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B9%80%E0%B8%88%E0%B9%87%E0%B8%9A%E0%B8%9B%E0%B9%88%E0%B8%A7%E0%B8%A2-%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันว่าเจ็บป่วย
                                                    </a>
                                                    <a
                                                        href="/%E0%B9%80%E0%B8%9C%E0%B8%A2%E0%B8%A2%E0%B8%AD%E0%B8%94%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99%E0%B8%97%E0%B8%AD%E0%B8%94%E0%B8%9C%E0%B9%89%E0%B8%B2%E0%B8%9B%E0%B9%88%E0%B8%B2-%E0%B8%99%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%AD%E0%B8%81%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%94%E0%B8%B1%E0%B8%87-%E0%B9%80%E0%B8%9A%E0%B8%A5%E0%B8%A5%E0%B9%88%E0%B8%B2-%E0%B8%A3%E0%B8%B2%E0%B8%93%E0%B8%B5"
                                                        className="btn btn-sm"
                                                    >
                                                        เผยยอดเงินทอดผ้าป่า นางเอกชื่อดัง "เบลล่า ราณี"
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%88%E0%B8%B1%E0%B8%99%E0%B8%97%E0%B8%A3%E0%B9%8C%E0%B8%97%E0%B8%A3%E0%B8%87%E0%B8%81%E0%B8%A5%E0%B8%94%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นจันทร์ทรงกลด
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5%E0%B8%A1%E0%B8%B5%E0%B9%80%E0%B8%81%E0%B8%93%E0%B8%91%E0%B9%8C-%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B8%84%E0%B8%B4%E0%B8%A7%E0%B8%AA%E0%B8%A1%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%84%E0%B8%99%E0%B8%A3%E0%B8%B1%E0%B8%81%E0%B8%95%E0%B8%B2%E0%B8%A1-checklist"
                                                        className="btn btn-sm"
                                                    >
                                                        ราศีมีเกณฑ์ ถึงคิวสมหวัง ได้คนรักตาม Checklist
                                                    </a>
                                                    <a
                                                        href="/Introducing-the-secret-to-winning-the"
                                                        className="btn btn-sm"
                                                    >
                                                        หวยยี่กี
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%88%E0%B8%B8%E0%B8%94%E0%B9%84%E0%B8%9F%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันว่าจุดไฟ
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%A1%E0%B8%94%E0%B8%94%E0%B8%B3-%E0%B8%A2%E0%B8%81%E0%B8%97%E0%B8%B1%E0%B8%9E-%E0%B8%A1%E0%B8%B9%E0%B9%84%E0%B8%81%E0%B8%A5%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B9%80%E0%B8%A1%E0%B8%B5%E0%B8%A2%E0%B8%A3%E0%B9%8C%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B9%8C-%E0%B8%9E%E0%B8%B2%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94"
                                                        className="btn btn-sm"
                                                    >
                                                        "มดดำ" ยกทัพ มูไกลถึงเมียร์มาร์ พาส่องเลขเด็ด
                                                    </a>
                                                    <a
                                                        href="/2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%88%E0%B8%B0%E0%B8%A1%E0%B8%B5%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B9%83%E0%B8%AB%E0%B8%8D%E0%B9%88-%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%9B%E0%B8%B5%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%A2-%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%A3%E0%B8%A7%E0%B8%A2"
                                                        className="btn btn-sm"
                                                    >
                                                        2 ราศี จะมีโชคใหญ่ ต้นปีร้าย กลางปีรวย
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94%E0%B8%AB%E0%B8%B2%E0%B8%87%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%97%E0%B8%B1%E0%B8%94-%E0%B8%AB%E0%B8%A5%E0%B8%A7%E0%B8%87%E0%B8%9E%E0%B9%88%E0%B8%AD%E0%B8%AA%E0%B8%A1%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%AA%E0%B8%A1%E0%B8%9B%E0%B8%A3%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%99%E0%B8%B2%E0%B8%9E%E0%B8%B2%E0%B8%A5%E0%B8%B9%E0%B8%81-%E0%B9%80"
                                                        className="btn btn-sm"
                                                    >
                                                        ส่องเลขเด็ดหางประทัด "หลวงพ่อสมหวัง"
                                                        หลังหนุ่มสมปรารถนาพาลูก-เมียแก้บน
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%80%E0%B8%88%E0%B8%94%E0%B8%B5%E0%B8%A2%E0%B9%8C%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นเจดีย์
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%A3%E0%B8%B1%E0%B8%81-2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%88%E0%B8%B0%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9E%E0%B8%9A%E0%B8%9B%E0%B9%8B%E0%B8%B2%E0%B8%AA%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%9B%E0%B8%A2%E0%B9%8C"
                                                        className="btn btn-sm"
                                                    >
                                                        ดวงความรัก 2 ราศีที่จะได้พบป๋าสายเปย์
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%84%E0%B8%99%E0%B9%81%E0%B8%AB%E0%B9%88%E0%B8%82%E0%B8%AD%E0%B9%82%E0%B8%8A%E0%B8%84-%E0%B9%84%E0%B8%AD%E0%B9%88%E0%B9%84%E0%B8%82%E0%B9%88-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%8430%E0%B8%A5%E0%B9%89%E0%B8%B2%E0%B8%99-%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%88%E0%B8%B2%E0%B8%84%E0%B8%A7%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B21%E0%B8%A5%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%9A%E0%B8%B2%E0%B8%97"
                                                        className="btn btn-sm"
                                                    >
                                                        คนแห่ขอโชค "ไอ่ไข่" หลังให้โชค30ล้าน บริจาควัดกว่า1ล้านบาท
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99-%E0%B8%9E%E0%B8%9A-3-%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A9%E0%B8%B1%E0%B8%95%E0%B8%A3-%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%A5%E0%B8%B7%E0%B8%A1%E0%B8%95%E0%B8%B2%E0%B8%AD%E0%B9%89%E0%B8%B2%E0%B8%9B%E0%B8%B2%E0%B8%81-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%88%E0%B8%B2%E0%B8%81%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A5%E0%B8%87%E0%B8%97%E0%B8%B8%E0%B8%99"
                                                        className="btn btn-sm"
                                                    >
                                                        ดวงการเงิน พบ 3 นักษัตร ดวงลืมตาอ้าปาก ได้โชคจากการลงทุน
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%88%E0%B8%B4%E0%B9%89%E0%B8%87%E0%B8%88%E0%B8%AD%E0%B8%81%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นจิ้งจอก
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%80%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%8A%E0%B8%B2%E0%B8%A2-%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นเจ้าชาย
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%AB%E0%B8%A7%E0%B8%A2%E0%B9%81%E0%B8%A1%E0%B9%88%E0%B8%97%E0%B8%B3%E0%B9%80%E0%B8%99%E0%B8%B5%E0%B8%A2%E0%B8%99-%E0%B8%87%E0%B8%A7%E0%B8%941-%E0%B8%81-%E0%B8%84-67-%E0%B8%99%E0%B8%B5%E0%B9%89-%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B9%82%E0%B8%94%E0%B8%99"
                                                        className="btn btn-sm"
                                                    >
                                                        รวมเลขเด็ด "หวยแม่ทำเนียน" งวด1 ก.ค. 67 นี้ ต้องโดน!!
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%97%E0%B8%B3%E0%B8%9A%E0%B8%B8%E0%B8%8D%E0%B9%80%E0%B8%AA%E0%B8%A3%E0%B8%B4%E0%B8%A1%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99%2012%20%E0%B8%A5%E0%B8%B1%E0%B8%84%E0%B8%99%E0%B8%B2%20%E0%B8%84%E0%B8%A3%E0%B8%B6%E0%B9%88%E0%B8%87%E0%B8%9B%E0%B8%B5%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%202567"
                                                        className="btn btn-sm"
                                                    >
                                                        ทำบุญเสริมดวงการเงิน 12 ลัคนา ครึ่งปีหลัง 2567
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9E%E0%B8%B2%E0%B8%AA%E0%B9%88%E0%B8%AD%E0%B8%87-%E0%B8%9A%E0%B8%AD%E0%B8%A5-%E0%B9%80%E0%B8%8A%E0%B8%B4%E0%B8%8D%E0%B8%A2%E0%B8%B4%E0%B9%89%E0%B8%A1-%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B8%87%E0%B9%84%E0%B8%AB-%E0%B9%81%E0%B8%A1%E0%B9%88%E0%B8%9C%E0%B8%B6%E0%B9%89%E0%B8%87-%E0%B8%9E%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%9E%E0%B8%A7%E0%B8%87"
                                                        className="btn btn-sm"
                                                    >
                                                        พาส่อง บอล เชิญยิ้ม ล้วงไห "แม่ผึ้ง พุ่มพวง"
                                                    </a>
                                                    <a
                                                        href="/3-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A2%E0%B8%B8%E0%B9%88%E0%B8%87%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B9%86-%E0%B9%81%E0%B8%95%E0%B9%88%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B8%A3%E0%B8%A7%E0%B8%A2"
                                                        className="btn btn-sm"
                                                    >
                                                        3 ราศี งานยุ่งสุดๆ แต่แล้วรวย
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B9%82%E0%B8%88%E0%B8%A3%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นโจร
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%A1%E0%B8%B2%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%97%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B8%B3%E0%B8%8A%E0%B8%B0%E0%B9%82%E0%B8%99%E0%B8%94-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%88%E0%B8%B3%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81-%E0%B8%84-67"
                                                        className="btn btn-sm"
                                                    >
                                                        มาแล้ว!! เลขเด็ด ปฏิทินคำชะโนด ประจำงวด 1 ก.ค.67
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-7-%E0%B8%A7%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%81%E0%B8%B4%E0%B8%94-%E0%B8%8A%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%AB%E0%B8%A5%E0%B9%88%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-%E0%B8%A5%E0%B8%B8%E0%B9%89%E0%B8%99%E0%B8%A3%E0%B8%A7%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C"
                                                        className="btn btn-sm"
                                                    >
                                                        ดวงโชคลาภ 7 วันเกิด ชี้เป้าแหล่งโชคลาภ ลุ้นรวยรับทรัพย์
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%87%E0%B8%B9%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นงู
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%84%E0%B8%B7%E0%B8%99%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B8%97%E0%B9%89%E0%B8%B2%E0%B8%A2-%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A3%E0%B8%B3%E0%B8%A5%E0%B8%B6%E0%B8%81%E0%B8%9E%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%9E%E0%B8%A7%E0%B8%87-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B9%81%E0%B8%9F%E0%B8%99%E0%B8%84%E0%B8%A5%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%AE%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B9%88%E0%B8%99"
                                                        className="btn btn-sm"
                                                    >
                                                        คืนสุดท้าย งานรำลึกพุ่มพวง หลังแฟนคลับเฮได้โชคหลักหมื่น
                                                    </a>
                                                    <a
                                                        href="/2-%E0%B8%A3%E0%B8%B2%E0%B8%A8%E0%B8%B5-%E0%B8%96%E0%B8%B6%E0%B8%87%E0%B9%80%E0%B8%A7%E0%B8%A5%E0%B8%B2%E0%B8%9A%E0%B8%AD%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%AB%E0%B8%99%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A2%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%AA%E0%B8%B4%E0%B9%88%E0%B8%87%E0%B8%95%E0%B8%AD%E0%B8%9A%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%84%E0%B8%B8%E0%B9%89%E0%B8%A1%E0%B8%84%E0%B9%88%E0%B8%B2"
                                                        className="btn btn-sm"
                                                    >
                                                        2 ราศี ถึงเวลาบอกลาการเหนื่อยฟรี ได้สิ่งตอบแทนคุ้มค่า
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B9%87%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%8A%E0%B9%89%E0%B8%B2%E0%B8%87%20%E0%B8%97%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%A2%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%AB%E0%B8%95%E0%B8%B8%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9D%E0%B8%B1%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%A1%E0%B8%B5%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3"
                                                        className="btn btn-sm"
                                                    >
                                                        ฝันเห็นงาช้าง
                                                    </a>
                                                    <a
                                                        href="/%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B8%AA%E0%B8%96%E0%B8%B4%E0%B8%95%E0%B8%B4%E0%B8%AB%E0%B8%A7%E0%B8%A2%E0%B8%87%E0%B8%A7%E0%B8%94-1-%E0%B8%81%E0%B8%A3%E0%B8%81%E0%B8%8E%E0%B8%B2%E0%B8%84%E0%B8%A1-67-%E0%B8%A2%E0%B9%89%E0%B8%AD%E0%B8%99%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87-10-%E0%B8%9B%E0%B8%B5"
                                                        className="btn btn-sm"
                                                    >
                                                        รวมสถิติหวยงวด 1 กรกฎาคม 67 ย้อนหลัง 10 ปี
                                                    </a>
                                                    <a
                                                        href="/%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-7-%E0%B8%A7%E0%B8%B1%E0%B8%99%E0%B9%80%E0%B8%81%E0%B8%B4%E0%B8%94-%E0%B8%8A%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%AB%E0%B8%A5%E0%B9%88%E0%B8%87%E0%B9%82%E0%B8%8A%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A0-%E0%B8%A5%E0%B8%B8%E0%B9%89%E0%B8%99%E0%B8%A3%E0%B8%A7%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C"
                                                        className="btn btn-sm"
                                                    >
                                                        โชคลาภ 7 วันเกิด ชี้เป้าแหล่งโชคลาภ ลุ้นรวยรับทรัพย์
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center -copy-right-container">
                                <p className="mb-0 -copy-right-text">
                                    Copyright © 2024 Lotto123. All Rights Reserved.
                                </p>
                            </div>
                        </footer>
                        {/* Google tag (gtag.js) */}
                    </div>
                </ >
            )
        }

    }
}
export default App;
