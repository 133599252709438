import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
        }
    }


    checklogin() {
        const search = window.location.href;
        var token = search.split("login=")[1]
        localStorage.setItem('auth_token', token);
        window.location.href = '/'
    }
    componentDidMount() {
        this.checklogin();
    }
    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <div>
                </div >
            );
        } else {

        }
    }
}
export default App;
