
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import jwt_decode from "jwt-decode";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            activePage: 1,
            isActive: false,
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            linelink: "",
            page: "",
            left: false,
            changepassword: false,
        }
    }
    async componentDidMount() {

        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            page: space[4].replace("#", ""),
            pathname: window.location.pathname.replace("/", "").trim()
        });
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }

    render() {
        const pathname = this.state.pathname
        const { loginstate, page } = this.state
        return (
            <div>
                {(page === "" || page === "slot" || page === "arcade" || page === "fishing" || page === "lotto") ?
                     <div className="js-replace-cover-seo-container">
                     <div
                         className="x-cover -small x-cover-casino x-bg-position-center lazyloaded"
                         data-bgset="/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9"
                         style={{
                             backgroundImage:
                                 'url("/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9")'
                         }}
                     >
                         <div className="x-cover-template-full ">
                             <div className="container -container-wrapper">
                                 <div className="-row-wrapper ">
                                     <div
                                         className="-col-wrapper -first animated fadeInModal"
                                         data-animatable="fadeInModal"
                                     >
                                         <div className="x-cover-typography -v2">
                                             <h1 className="-title">
                                             เว็บพนัน คาสิโนออนไลน์ การันตีความ ปลอดภัย 100%
                                             </h1>
                                             <p className="-sub-title">
                                             เดิมพันคาสิโนออนไลน์ เว็บใหญ่ที่สุด เล่นง่ายถอนได้ทุกบิล
                                             </p>
                                         </div>
                                         <button
                                             className="btn x-cover-btn"
                                             data-toggle="modal"
                                             data-target="#registerModal"
                                         >
                                             สมัครสมาชิก
                                         </button>
                                     </div>
                                 </div>
                             </div>
                         </div>

                     </div>
                 </div>
                    : ""
                }
                {
                    (page === "casino") ?
                        <>
                            <div className="js-replace-cover-seo-container">
                                <div
                                    className="x-cover -small x-cover-casino x-bg-position-center lazyloaded"
                                    data-bgset="/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9"
                                    style={{
                                        backgroundImage:
                                            'url("/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9")'
                                    }}
                                >
                                    <div className="x-cover-template-full ">
                                        <div className="container -container-wrapper">
                                            <div className="-row-wrapper ">
                                                <div
                                                    className="-col-wrapper -first animated fadeInModal"
                                                    data-animatable="fadeInModal"
                                                >
                                                    <div className="x-cover-typography -v2">
                                                        <h1 className="-title">
                                                        เว็บพนัน คาสิโนออนไลน์ การันตีความ ปลอดภัย 100%
                                                        </h1>
                                                        <p className="-sub-title">
                                                        เดิมพันคาสิโนออนไลน์ เว็บใหญ่ที่สุด เล่นง่ายถอนได้ทุกบิล
                                                        </p>
                                                    </div>
                                                    <button
                                                        className="btn x-cover-btn"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                    >
                                                        สมัครสมาชิก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                        : ""}
                {
                    (page === "sport") ?
                    <div className="js-replace-cover-seo-container">
                    <div
                        className="x-cover -small x-cover-casino x-bg-position-center lazyloaded"
                        data-bgset="/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9"
                        style={{
                            backgroundImage:
                                'url("/build/web/ez-bet/img/cover-bg-casino-mobile.png?v=9")'
                        }}
                    >
                        <div className="x-cover-template-full ">
                            <div className="container -container-wrapper">
                                <div className="-row-wrapper ">
                                    <div
                                        className="-col-wrapper -first animated fadeInModal"
                                        data-animatable="fadeInModal"
                                    >
                                        <div className="x-cover-typography -v2">
                                            <h1 className="-title">
                                            เว็บพนัน คาสิโนออนไลน์ การันตีความ ปลอดภัย 100%
                                            </h1>
                                            <p className="-sub-title">
                                            เดิมพันคาสิโนออนไลน์ เว็บใหญ่ที่สุด เล่นง่ายถอนได้ทุกบิล
                                            </p>
                                        </div>
                                        <button
                                            className="btn x-cover-btn"
                                            data-toggle="modal"
                                            data-target="#registerModal"
                                        >
                                            สมัครสมาชิก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                        : ""}



            </div>
        );
    }
}

export default App;
