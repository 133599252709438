
import React, { Component } from 'react'; //different

import Footer from './footer';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { isMobile } from 'react-device-detect';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rewardlist: [],
            activePage: 1,
            isActive: false,
            categorylist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: '',
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            linelink: "",
            amount: "",
            remark: "",
            name: "",
            val1: "",
            val2: "",
            val3: "",
            val4: "",
            val5: "",
            val6: "",
            password: "",
            left: false,
        }
        this.login = this.login.bind(this);
    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    confirm = (e, step, func) => {
        const { username, password } = this.state;
        let val = 1
        if (step == 1 && func == 1 && username.length == 10) {
            val = 2
           // this.nameInput1.focus();
        }
        else if (step == 2 && func == 2) val = 1
        else if (step == 6 && func == 6) val = 6
        this.setState({
            activeTab: val,
        });
    }

    validateLoginForm = async (e) => {
        const { username, password } = this.state;
      //  let password = password;
        instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then((res) => {
                //////console.log(res);
                if (res.status === 200) {
                    //////console.log(res.data.status);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            userid: res.data.message.member_id,
                            username: res.data.message.playerid,
                            firstname: res.data.message.firstname,
                            lastname: res.data.message.lastname,
                            point: res.data.message.Point,
                            balance: res.databalance,
                            loginstate: true,
                        });
                        window.location.reload(false);
                    }
                    else if (res.data.status === 401) {
                        // this.setState({
                        //     activeTab: val,
                        // });
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                    }
                }
                else {
                    this.setState({
                        firstname: null,
                        lastname: null,
                        point: null,
                        loginstate: false,
                    });
                    var title = 'Error!'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
            });
    }
    async componentDidMount() {
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value,
                        System: res.data.tblcatagoryx[0].System
                    });
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }
    onChangex = (event, index) => {
        //console.log(9999, index);
        let username = this.state.username;
        let val;
        if (index == 10) {
            val = username.slice(0, -1);
        }
        else {
            val = String(username) + String(index);
        }
        this.setState({ username: val });
    };
    onChangexx = (event) => {
        let nam;
        let val;
        console.log(112113223, event.target.name);
        if (event.target.name === undefined) {
            nam = event.name;
            val = event.value;
        } else {
            nam = event.target.name;
            val = event.target.value;
            if (nam == "val1") {
                if (val.length == 1) {
                    this.nameInput2.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val2") {
                if (val.length == 1) {
                    this.nameInput3.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val3") {
                if (val.length == 1) {
                    this.nameInput4.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val4") {
                if (val.length == 1) {
                    this.nameInput5.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val5") {
                if (val.length == 1) {
                    this.nameInput6.focus();
                    this.setState({ [nam]: val });
                }
                else return;
            }
            else if (nam == "val6") {
                if (val.length == 1) {
                    console.log(val.length);
                    this.setState({ [nam]: val });
                }
                else return;
            }
        }

    };
    onChange = (event) => {
        console.log(event);
        let nam;
        let val;
        if (event.target.name === undefined) {
            nam = event.name;
            val = event.value;
        } else {
            nam = event.target.name;
            val = event.target.value;
        }

        this.setState({ [nam]: val });

    };
    render() {
        return (
            <div >
                <div className="modal-content -modal-content">
                    <button
                        type="button"
                        className="close f-1 -in-tab"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="fas fa-times" />
                    </button>
                    <div className="x-modal-account-security-tabs js-modal-account-security-tabs -v3">
                        <button
                            type="button"
                            className="-btn -register js-modal-account-security-tab-button "
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#registerModal"
                        >
                            สมัครสมาชิก
                            <picture>
                                <source
                                    type="image/webp"
                                    data-srcset="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                    srcSet="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                />
                                <source
                                    type="image/png?v=9"
                                    data-srcset="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                    srcSet="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                />
                                <img
                                    alt="Hexa789hImage"
                                    className="img-fluid -ic-badge lazyloaded"
                                    data-src="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                    src="/build/web/igame-index-lobby-wm/img/register-badge-new-user.png?v=9"
                                />
                            </picture>
                        </button>
                        <button
                            type="button"
                            className="-btn -login js-modal-account-security-tab-button -active"
                            data-modal-target="#loginModal"
                        >
                            เข้าสู่ระบบ
                        </button>
                    </div>
                    <div className="modal-body -modal-body">
                        <div className="x-register-tab-container -login js-tab-pane-checker-v3">
                            <div className="container">
                                <ul className="nav nav-tabs x-register-tab">
                                    <li
                                        className="nav-item   -loginPhoneNumber"
                                        id="tab-loginPhoneNumber"
                                    >
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-loginPhoneNumber"
                                            className="nav-link"
                                        >
                                            loginPhoneNumber
                                        </a>
                                    </li>
                                    <li className="nav-item   -loginPassword" id="tab-loginPassword">
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-loginPassword"
                                            className="nav-link"
                                        >
                                            loginPassword
                                        </a>
                                    </li>
                                    <li className="nav-item   -loginPasswordV3" id="tab-loginPasswordV3">
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-loginPasswordV3"
                                            className="nav-link"
                                        >
                                            loginPasswordV3
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item   -resetPasswordVerifyOtp"
                                        id="tab-resetPasswordVerifyOtp"
                                    >
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-resetPasswordVerifyOtp"
                                            className="nav-link"
                                        >
                                            resetPasswordVerifyOtp
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item   -resetPasswordSetPassword"
                                        id="tab-resetPasswordSetPassword"
                                    >
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-resetPasswordSetPassword"
                                            className="nav-link"
                                        >
                                            resetPasswordSetPassword
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item   -resetPasswordResult"
                                        id="tab-resetPasswordResult"
                                    >
                                        <a
                                            data-toggle="tab"
                                            href="#tab-content-resetPasswordResult"
                                            className="nav-link"
                                        >
                                            resetPasswordResult
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className={this.state.activeTab == 1 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-loginPhoneNumber"
                                    data-completed-dismiss-modal=""
                                >
                                    <div className="x-modal-body-base -v3 -phone-number x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">กรอกหมายเลขโทรศัพท์</span>
                                                    <span className="-sub-title">
                                                        เบอร์โทรศัพท์ที่ใช้ในการสมัครเพื่อล็อคอิน
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="-fake-inner-body">
                                                    <div className="-x-input-icon input-group text-center js-register-v3-input-group">
                                                        <input
                                                            required=""
                                                            autoComplete="off"
                                                            id="username"
                                                            pattern="[0-9]"
                                                            type='number'
                                                            value={this.state.username}
                                                            onChange={this.onChange}
                                                            name="username"
                                                            placeholder="095-123-4567"
                                                            className="form-control x-form-control js-keypad-desktop"
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <button
                                                            type="submit"
                                                            onClick={(e) => {
                                                                this.confirm(e, 1, 1)
                                                            }}
                                                            className="btn  -submit mt-lg-3 mt-0">
                                                            ยืนยัน
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 2 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-loginPassword"
                                    data-completed-dismiss-modal=""
                                >
                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">รหัสผ่าน</span>
                                                    <span className="-sub-title">
                                                        กรอกรหัสเข้าระบบ{" "}
                                                        <span className="js-phone-number -highlight" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div
                                                    data-animatable="fadeInRegister"
                                                    data-offset={0}
                                                    className="col animated fadeInRegister"
                                                >
                                                    <div className="x-modal-separator-container x-form-register">
                                                        <div className="-top ">
                                                            <div
                                                                data-animatable="fadeInRegister"
                                                                data-offset={0}
                                                                className="-animatable-container -password-body js-register-set-password animated fadeInRegister"
                                                            >
                                                                <div className="d-flex -password-input-container js-register-v3-input-group">
                                                                    <input
                                                                        required=""
                                                                        autoComplete="off"
                                                                        id="password"
                                                                        type='password'
                                                                        value={this.state.password}
                                                                        onChange={this.onChange}
                                                                        name="password"
                                                                        className="form-control x-form-control js-keypad-desktop"
                                                                    />
                                                                </div>
                                                                {/* <div className="d-none js-keypad-number-wrapper">
                                                                    <div className="x-keypad-number-container">
                                                                        <div className="-btn-group-wrapper">
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={1}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                1
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={2}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                2
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={3}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                3
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={4}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                4
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={5}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                5
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={6}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                6
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={7}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                7
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={8}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                8
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={9}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                9
                                                                            </button>
                                                                            <div
                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                type="button"
                                                                                data-key="none"
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            />
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={0}
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                0
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                type="button"
                                                                                data-key="backspace"
                                                                                data-target="#registerSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-register-password-container","enabledButtonTarget":".js-set-password-tab-button"}'
                                                                            >
                                                                                <i className="fas fa-backspace" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={(e) => {
                                                                            this.login(e)
                                                                        }}
                                                                        className="btn -submit my-lg-3 mt-0"
                                                                    >
                                                                        ยืนยัน
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="-bottom "></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.activeTab == 6 ? "tab-pane active" : "tab-pane"}
                                    id="tab-content-loginPasswordV3"
                                    data-completed-dismiss-modal=""
                                >
                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">รหัสผ่าน</span>
                                                    <span className="-sub-title">
                                                        ติดต่อเจ้าหน้าพี่เพื่อเปลี่ยนรหัสผ่าน
                                                        <span className="js-phone-number -highlight" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane "
                                    id="tab-content-resetPasswordVerifyOtp"
                                    data-completed-dismiss-modal=""
                                >
                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">กรอกรหัส OTP</span>
                                                    <span className="-sub-title">
                                                        รหัส 4 หลัก ส่งไปยัง{" "}
                                                        <span className="js-phone-number -highlight" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="x-modal-separator-container x-form-register">
                                                    <div className="-top ">
                                                        <div
                                                            data-animatable="fadeInRegister"
                                                            data-offset={0}
                                                            className="-animatable-container -otp-body animated fadeInRegister"
                                                        >
                                                            <div className="d-flex -otp-input-container js-register-v3-input-group">
                                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                                    <input
                                                                        type="text"
                                                                        id="resetPasswordOtp0"
                                                                        name="otp0"
                                                                        inputMode="text"
                                                                        readOnly=""
                                                                        pattern="[0-9]*"
                                                                        autofocus={1}
                                                                        className="-digit-otp js-otp-input"
                                                                        data-separator-input="true"
                                                                        data-type="otp"
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                                    <input
                                                                        type="text"
                                                                        id="resetPasswordOtp1"
                                                                        name="otp1"
                                                                        inputMode="text"
                                                                        readOnly=""
                                                                        pattern="[0-9]*"
                                                                        autofocus={1}
                                                                        className="-digit-otp js-otp-input"
                                                                        data-separator-input="true"
                                                                        data-type="otp"
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                                    <input
                                                                        type="text"
                                                                        id="resetPasswordOtp2"
                                                                        name="otp2"
                                                                        inputMode="text"
                                                                        readOnly=""
                                                                        pattern="[0-9]*"
                                                                        autofocus={1}
                                                                        className="-digit-otp js-otp-input"
                                                                        data-separator-input="true"
                                                                        data-type="otp"
                                                                    />
                                                                </div>
                                                                <div className="js-separator-container js-login-reset-password-otp-container">
                                                                    <input
                                                                        type="text"
                                                                        id="resetPasswordOtp3"
                                                                        name="otp3"
                                                                        inputMode="text"
                                                                        readOnly=""
                                                                        pattern="[0-9]*"
                                                                        autofocus={1}
                                                                        className="-digit-otp js-otp-input"
                                                                        data-separator-input="true"
                                                                        data-type="otp"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="hidden"
                                                                id="resetPasswordOtp"
                                                                name="otp"
                                                                pattern="[0-9]*"
                                                                className="form-control mb-3"
                                                            />
                                                            <input
                                                                type="hidden"
                                                                id="resetPasswordToken"
                                                                name="resetPasswordToken"
                                                                className="form-control mb-3"
                                                            />
                                                            <div className="d-none js-keypad-number-wrapper">
                                                                <div className="x-keypad-number-container">
                                                                    <div className="-btn-group-wrapper">
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={1}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            1
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={2}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            2
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={3}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            3
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={4}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            4
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={5}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            5
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={6}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            6
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={7}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            7
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={8}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            8
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={9}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            9
                                                                        </button>
                                                                        <div
                                                                            className="btn -btn js-keypad-btn -btn-none"
                                                                            type="button"
                                                                            data-key="none"
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        />
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-keypad"
                                                                            type="button"
                                                                            data-key={0}
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            0
                                                                        </button>
                                                                        <button
                                                                            className="btn -btn js-keypad-btn -btn-backspace"
                                                                            type="button"
                                                                            data-key="backspace"
                                                                            data-target="#resetPasswordOtp0"
                                                                            data-options='{"maxLength":4,"inputContainer":".js-separator-container.js-login-reset-password-otp-container","targetSubmitForm":"js-reset-password-v3-otp-form"}'
                                                                        >
                                                                            <i className="fas fa-backspace" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <button
                                                                    type="submit"
                                                                    onClick={(e) => {
                                                                        this.login(e)
                                                                    }}
                                                                    className="btn -submit  my-lg-3 mt-0"
                                                                >
                                                                    ยืนยัน
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="-bottom "></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane "
                                    id="tab-content-resetPasswordSetPassword"
                                    data-completed-dismiss-modal=""
                                >
                                    <div className="x-modal-body-base -v3 -password x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div className="col">
                                                <div className="x-title-register-modal-v3 ">
                                                    <span className="-title">ตั้งรหัสผ่านใหม่</span>
                                                    <span className="-sub-title">
                                                        กรอกตัวเลขรหัส 6 หลัก เพื่อใช้เข้าสู่ระบบ
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="x-modal-separator-container x-form-register">
                                                    <div className="-top ">
                                                        <div
                                                            data-animatable="fadeInRegister"
                                                            data-offset={0}
                                                            className="-animatable-container -password-body animated fadeInRegister"
                                                        >
                                                            <form
                                                                method="post"
                                                                data-register-v3-form="/_ajax_/v3/reset-password/set-password/_resetPasswordSetPassword"
                                                                data-register-step="resetPasswordSetPassword"
                                                                data-tab-next-step="#tab-content-resetPasswordResult"
                                                            >
                                                                <div className="d-flex -password-input-container js-register-v3-input-group">
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_1"
                                                                            name="resetPasswordSetPassword_1"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_2"
                                                                            name="resetPasswordSetPassword_2"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_3"
                                                                            name="resetPasswordSetPassword_3"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_4"
                                                                            name="resetPasswordSetPassword_4"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_5"
                                                                            name="resetPasswordSetPassword_5"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                    <div className="js-separator-container js-reset-password-container">
                                                                        <input
                                                                            type="password"
                                                                            id="resetPasswordSetPassword_6"
                                                                            name="resetPasswordSetPassword_6"
                                                                            inputMode="text"
                                                                            readOnly=""
                                                                            pattern="[0-9]*"
                                                                            autofocus={1}
                                                                            className="-digit-password js-otp-input"
                                                                            data-separator-input="true"
                                                                            data-type="set_password"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    type="hidden"
                                                                    id="resetPasswordSetPasswordToken"
                                                                    name="resetPasswordSetPasswordToken"
                                                                />
                                                                <div className="d-none js-keypad-number-wrapper">
                                                                    <div className="x-keypad-number-container">
                                                                        <div className="-btn-group-wrapper">
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={1}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                1
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={2}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                2
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={3}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                3
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={4}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                4
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={5}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                5
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={6}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                6
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={7}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                7
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={8}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                8
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={9}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                9
                                                                            </button>
                                                                            <div
                                                                                className="btn -btn js-keypad-btn -btn-none"
                                                                                type="button"
                                                                                data-key="none"
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            />
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-keypad"
                                                                                type="button"
                                                                                data-key={0}
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                0
                                                                            </button>
                                                                            <button
                                                                                className="btn -btn js-keypad-btn -btn-backspace"
                                                                                type="button"
                                                                                data-key="backspace"
                                                                                data-target="#resetPasswordSetPassword_1"
                                                                                data-options='{"maxLength":6,"inputContainer":".js-separator-container.js-reset-password-container"}'
                                                                            >
                                                                                <i className="fas fa-backspace" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn -submit  my-lg-3 mt-0"
                                                                    >
                                                                        ยืนยัน
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="-bottom "></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane "
                                    id="tab-content-resetPasswordResult"
                                    data-completed-dismiss-modal="#loginModal"
                                >
                                    <div className="x-modal-body-base -v3 x-form-register-v3">
                                        <div className="row -register-container-wrapper">
                                            <div
                                                data-animatable="fadeInRegister"
                                                data-offset={0}
                                                className="col animated fadeInRegister"
                                            >
                                                <div className="text-center d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center">
                                                        <picture>
                                                            <source
                                                                type="image/webp"
                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.webp?v=9"
                                                            />
                                                            <source
                                                                type="image/png"
                                                                srcSet="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                            />
                                                            <img
                                                                alt="สมัครสมาชิก SUCCESS"
                                                                className="js-ic-success -success-ic img-fluid"
                                                                width={150}
                                                                height={150}
                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/animated-register-success.png"
                                                            />
                                                        </picture>
                                                    </div>
                                                    <div className="-title">อัปเดตรหัสผ่านของคุณเรียบร้อย!</div>
                                                    <div className="-sub-title">ไปหน้าเข้าสู่ระบบอัตโนมัติใน</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default App;
